type Props = {
  title: string;
  children?: React.ReactElement;
  menu?: React.ReactElement;
  footer?: React.ReactElement;
};

export function Page({ title, menu, children, footer }: Props) {
  return (
    <>
      <div className="row" style={{ padding: '30px 0px' }}>
        <div className="row text-centerx">
          <h1 style={{ margin: '5px 0px' }}>{title}</h1>
        </div>
        {!menu ? null : <div className="row mtop10">{menu}</div>}
        <div className="row mtop10">{children}</div>
        {footer}
      </div>
    </>
  );
}
