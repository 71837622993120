import '../css/a_app.scss';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import React from 'react';

import Messages from '../util/notification/messages.component';
import { SideBar } from './nav-sidebar';
import { TabsList } from './nav-topbar';
import { OneContent } from './one-content';
import { initState } from './u-state';
import { useStyles } from './u-style';

const alwaysNewPage = true;

export function Frame() {
  const [state, setS] = React.useState(initState());
  const classes = useStyles();
  const setState = (json: any) => {
    const newstate = { ...state, ...json };
    setS(newstate);
  };

  const addPage = (content: any, newpage = false) => {
    let { pages, act, nr } = state as any;
    const { ver } = state;
    nr++;
    act = 'p' + nr;
    const page = { code: act, content };
    if (alwaysNewPage || newpage) pages.push(page);
    else pages = [page];
    setState({ pages, act, nr, ver });
  };
  const selectPage = (act: any) => {
    setState({ act });
  };
  const closePage = (code: string) => {
    let { pages, act } = state as any;
    pages = pages.filter((p: any) => p.code !== code);
    if (pages.length === 0) act = null;
    else if (act === code) act = pages[0].code;
    setState({ pages, act });
  };
  const renamePage = (code: string, title: string) => {
    let { pages } = state as { pages: any };
    pages = pages.map((page: any) => ({
      ...page,
      title: page.code === code ? title : page.title,
    }));
    setState({ pages });
  };

  return (
    <>
      <Messages />
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          onContextMenu={noRightClick}
        >
          <SideBar {...{ addPage }} />
        </Drawer>

        <AppBar position="fixed" className={classes.appBar}>
          <TabsList {...{ selectPage, closePage, ...state }} />
        </AppBar>

        <main className={classes.content} onContextMenu={noRightClick}>
          {state.pages.map((page: any) => (
            <ShowOneContent
              {...{ ...page, act: state.act, renamePage, addPage }}
              key={page.code}
            />
          ))}
        </main>
      </div>
    </>
  );
}

const noRightClick = (event: any) => event.preventDefault();

function ShowOneContent({
  content,
  code,
  act,
  renamePage,
  addPage,
}: {
  content: any;
  code: any;
  act: any;
  renamePage: any;
  addPage: any;
}) {
  const style = code === act ? {} : { display: 'none' };
  return (
    <div style={style}>
      <OneContent
        initialContent={content}
        addPage={addPage}
        setTitle={(title: string) => {
          renamePage(code, title);
        }}
      />
    </div>
  );
}
