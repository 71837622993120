/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { valGen } from '../../util/client_http/h_validate';
import { repNumber, round } from '../../util/common/number';
import {
  SetDate,
  SetSelect,
  SetSelectSwitch,
  SetSelectVT,
  SetSwitch,
  SetText,
} from '../../util/io_form-old/io_helpers';

const value_dig = 0;

type CompProps = {
  data: any;
  meta: any;
  css?: any;
  onAction: any;
  id: any;
  exrate: any;
};

export function ItemRow({
  data,
  meta,
  css = '',
  onAction,
  id,
  exrate,
}: CompProps) {
  const fields = Object.keys(meta).filter((f) => {
    return !meta[f].exclude;
  });
  let cur_updated = false;
  let update_currency_orig = false;

  const set_meta_data = (state_: any) => {
    for (const f of fields) {
      const _data =
        data[f] !== undefined
          ? data[f]
          : (meta[f] ? meta[f].value : undefined) || '';
      const _meta = {
        type: 'text',
        validations: [],
        errorhint: '',
        ...meta[f],
        disabled: meta[f].disabled,
      };
      state_[f] = {
        meta: _meta,
        ...valF(f, _data, _meta),
      };
    }
  };

  const set_f_exchange_rate = (state_: any) => {
    if (exrate) {
      state_.exchange_rate.data = exrate * 1;
      state_.exchange_rate.meta.data = exrate * 1;
      state_.exchange_rate.ok = true;
      state_.exchange_rate.error = null;
      state_.exchange_rate.meta.type = 'rep_num';
    } else {
      state_.exchange_rate.meta.type = 'text';
    }
  };

  const onChange = (f: any, data: any) => {
    const newstate = { ...state };
    onChangeField(newstate, f, data);
    if (f === 'currency_orig') set_f_exchange_rate(newstate);
    set_f_value(newstate);
    onAction({ type: 'itemchange', $id: id, meta: newstate });
    setState(newstate);
  };

  const onChangeField = (state_: any, f: any, data: any) => {
    const valJ = valF(f, data, state[f].meta);
    state_[f] = { ...state[f], ...valJ };
  };

  const onDelItem = () => {
    onAction({ type: 'itemdel', $id: id });
  };
  const checkCurr = () => {
    if (cur_updated) return;
    if (exrate) {
      if (
        state.exchange_rate.data !== exrate * 1 ||
        state.exchange_rate.meta.type === 'text'
      )
        update_currency_orig = true;
    } else {
      if (state.exchange_rate.meta.type === 'rep_num')
        update_currency_orig = true;
    }
    cur_updated = true;
  };

  // ------------------------------------
  const [state, setState] = useState(null) as [any | null, (st: any) => void];

  if (!state) {
    const _state = {};
    set_meta_data(_state);
    set_f_exchange_rate(_state);
    set_f_value(_state);
    setState(_state);
  } else {
    checkCurr();
    if (update_currency_orig) {
      setState(null);
      setTimeout(() => {
        onChange('currency_orig', state.currency_orig.data);
      }, 10);
    }
  }

  return !state ? null : (
    <>
      <tr>
        <td
          className="text-center relative"
          style={{ width: '30px', minWidth: '30px' }}
        >
          <div className="absolute tl0 wh100 text-center">
            <span
              className="fas fa-times-circle cl_red left m5_0 op5 hoverop1 pointer"
              style={{ width: '30px' }}
              onClick={onDelItem}
            ></span>
          </div>
        </td>
        {fields.map((f) => (
          <CreateCell
            key={f}
            data={state[f].data}
            meta={state[f].meta}
            error={state[f].error}
            css={
              css +
              ' cell' +
              (state[f].ok ? '' : ' err') +
              (state[f].meta.validations.includes('number')
                ? ' text-right'
                : '')
            }
            name={f}
            onChange={(val: any) => onChange(f, val)}
          />
        ))}
      </tr>
    </>
  );
}

const valF = (f: any, _data: any, _meta: any) => {
  let _error = '';
  const errFx = (_err: any) => {
    _error = _err;
  };
  const ok = valGen(_data, _meta.validations, _meta.errorhint, errFx);
  const re_ = { data: _data, error: _error, ok };
  return re_;
};

const set_f_value = (state_: any) => {
  state_.value.ok = true;
  state_.value.error = null;
  try {
    state_.value_orig.data *= 1;
  } catch {
    null;
  }
  try {
    state_.value.data = round(
      state_.value_orig.data * 1 * (state_.exchange_rate.data * 1),
      value_dig
    );
  } catch {
    state_.value.data = Number.NaN;
  }
  if (Number.isNaN(+state_.value.data)) {
    state_.value.data = 0;
    state_.value.ok = false;
    state_.value.error = 'error';
  }
};

function CreateCell({ data, error, meta, name, css, onChange }: any) {
  return (
    <td>
      <CreateCellData
        data={data}
        error={error}
        meta={meta}
        name={meta.text || name}
        css={css}
        onChange={onChange}
      />
    </td>
  );
}

function CreateCellData({ data, error, meta, name, css, onChange }: any) {
  switch (meta.type) {
    case 'rep_num':
      return (
        <span
          className={'left w100 op8' + (error ? ' cell_err' : '')}
          style={{
            textAlign: 'right',
            lineHeight: '25px',
            height: '25px',
            paddingRight: '5px',
            backgroundColor: '#ededed',
          }}
        >
          {repNumber(data)}
        </span>
      );

    case 'text':
      return (
        <SetText
          value={data}
          error={error}
          class={css}
          name={name}
          onChange={onChange}
          maxlength={meta.maxlength}
          disabled={meta.disabled}
        />
      );

    case 'date':
      return (
        <SetDate
          value={data}
          error={error}
          class={css}
          name={name}
          onChange={onChange}
          disabled={meta.disabled}
        />
      );

    case 'switch':
      return (
        <SetSwitch
          value={data}
          error={error}
          class={css}
          name={name}
          onChange={onChange}
          maxlength={meta.maxlength}
          disabled={meta.disabled}
        />
      );

    case 'select':
      return (
        <SetSelect
          value={data}
          class={css}
          name={name}
          onChange={onChange}
          options={meta.options}
          disabled={meta.disabled}
        />
      );

    case 'select2':
      return (
        <SetSelectVT
          value={data}
          class={css}
          name={name}
          onChange={onChange}
          options={meta.options}
          disabled={meta.disabled}
        />
      );

    case 'select3':
      return (
        <SetSelectSwitch
          value={data}
          css={css}
          name={name}
          onChange={onChange}
          options={meta.options}
          disabled={meta.disabled}
        />
      );

    default:
      return null;
  }
}
