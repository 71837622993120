type CTprops = { onAction: any; bookinData: any };

export function ChooseTemplate({ onAction, bookinData }: CTprops) {
  let templates = [];
  try {
    templates = bookinData.role_list.bktemplate
      .map((iid: any) => bookinData.item[iid])
      .filter((tt: any) => !!tt);
  } catch {
    null;
  }

  return (
    <>
      <div className="row m50_0 p5">
        <div className="row text-center">
          <h1>Könyvelési sablon választása</h1>
        </div>
        <div className="row mtop30">
          <div className="mw700">
            <table className="w100 table_dark">
              <tbody>
                <tr>
                  <th>ID</th>
                  <th>Név</th>
                  <th>Elemek száma</th>
                  <th>Mentés ideje</th>
                </tr>
                <BoxNew onAction={onAction} />
                {templates.map((template: any) => (
                  <BoxTemp
                    key={template.id}
                    template={template}
                    onAction={onAction}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

function BoxNew({ onAction }: any) {
  const clicked = () => {
    onAction({ template: null });
  };
  return (
    <tr className="cl_accent2 hcl_accent pointer" onClick={clicked}>
      <td className="text-center">*</td>
      <td>Üres könyvelési csomag</td>
      <td></td>
      <td></td>
    </tr>
  );
}

function BoxTemp({ onAction, template }: any) {
  const clicked = () => {
    onAction({ template });
  };

  return (
    <tr className="hcl_accent pointer" onClick={clicked}>
      <td className="text-center">{template.id}</td>
      <td>{template.title}</td>
      <td className="text-center">{template.bktemplate.data.items.length}</td>
      <td className="text-center">
        {new Date(template.created).toLocaleString()}
      </td>
    </tr>
  );
}
