import { api_url } from '../../../config/api.config';
import { GET } from './request';

export const reqDownloadPath = (path = '') => {
  if (!path) return '';
  const base = api_url();
  const rand = Date.now();
  const dest = base + path + '?rand=' + rand;
  return path.startsWith('http') ? path : dest;
};

export const downloadAsset = async (
  fileName: string
): Promise<{ filename: string; filepath: string }> => {
  const base = api_url();
  const url = base + '/asset/' + fileName;
  return GET({ url });
};
