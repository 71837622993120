interface CompProps {
  files: any[];
}

export function FileList({ files }: CompProps) {
  return (
    <>
      {
        <div className="row">
          <table className="m0a">
            <tbody>
              {files.map((file) => (
                <tr key={file.name} className="">
                  <td className="text-center">
                    <a
                      className="left op7 hoverop1 pointer p5"
                      href={file.dest}
                      rel="noopener noreferrer"
                    >
                      {/* <span className="">
                        <span className="fas fa-file-excel mright8x cl_green"></span>
                        <span className="mw200 m5">{file.name}</span>
                        <span className="m5">-</span>
                        <span className="m5">{file.time.toLocaleString()}</span>
                      </span> */}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </>
  );
}
