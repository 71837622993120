export const round = (val, dig = 2) => {
  return Math.round(val * Math.pow(10, dig)) / Math.pow(10, dig);
};

const ui_thousandSeparator = ' ';
const ui_digSeparator = '.';

export const repNumber = (nr, maxdig = 2, fixdig = 0) => {
  if (Number.isNaN(+nr)) return nr.toString();

  let mult = Math.pow(10, maxdig);
  let nr2 = Math.round(nr * mult) / mult;

  let [fig1, fig2] = nr2.toString().split('.');
  let back = tothousand(fig1);
  if (fig2) {
    back +=
      ui_digSeparator +
      (!fixdig
        ? fig2
        : (fig2 + '00000000000000000').slice(0, Math.max(0, fixdig)));
  }

  return back;
};

const tothousand = (fig) => {
  let where = fig.length % 3;
  let inserted = 0;
  while (where + inserted < fig.length) {
    if (where > 0) {
      fig =
        fig.slice(0, Math.max(0, where + inserted)) +
        ui_thousandSeparator +
        fig.slice(where + inserted);
      inserted += 1;
    }
    where += 3;
  }
  fig = fig.replace('- ', '-');
  fig = fig.replace('-' + ui_thousandSeparator, '-');
  return fig;
};
