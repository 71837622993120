import React from 'react';

import Tokens from '../client/tokens';
import { userData } from './cognito-user';

const FX = !!process.env.REACT_APP_API_FX;
const ENV = process.env.REACT_APP_ENV;

export function UserName() {
  const [user, setU] = React.useState<string>('');

  const refresh = async () => {
    if (FX) {
      const userCog = await userData();
      setU(userCog?.attributes?.name);
    } else {
      const userToken = Tokens.get('user');
      setU(userToken);
    }
  };

  React.useEffect(() => {
    refresh();
  }, []);

  return (
    <span>
      {ENV}: {user}
    </span>
  );
}
