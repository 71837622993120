import { ItemRole, Template } from '../content.types';

export const TemplateDefs: Record<ItemRole | 'base', Template> = {
  base: {},
  ledger: {},
  exrate: {},
  partner: {
    base: {},
    billing_address: {},
  },
  bsasset: {},
  bsaccount: {},
  bktemplate: {},
};
