import { calcBalance } from '../meta/balance';

type SFXprops = {
  input: any;
  state: any;
  setFX: any;
};

export const selectFX = ({ input, state, setFX }: SFXprops) => {
  const { ids, elements } = input;
  const balance = calcBalance(elements);
  setFX({ ...state, selected: { ids, elements, balance } });
};
