import { TextField } from '@material-ui/core';
import React, { useState } from 'react';

import { generate_code } from '../../util/common/gen';

export function SetText({
  value,
  name,
  disabled,
  error,
  onChange,
  autoFocus,
}: any) {
  // ,autofocus, maxlength
  const [svalue, setValue] = useState(value);
  const [id] = useState('c' + generate_code(6));
  const handleChange = (event: any) => {
    const v = event.target.value;
    onChange(v);
    setValue(event.target.value);
  };

  return (
    <TextField
      id={id}
      label={name}
      value={svalue}
      onChange={handleChange}
      //variant="outlined"
      disabled={disabled}
      error={!!error}
      helperText={error}
      size="small"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  );
}
