import { makeStyles } from '@material-ui/core/styles';

const isProd = process.env.REACT_APP_ENV === 'prod';

const drawerWidth = 180;
export const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#888a94', //"#145ca4"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: isProd ? '#363a50' : '#555',
    color: 'white',
  }, //  <Drawer classes={..}
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    height: '48px',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));
