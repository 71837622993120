import { CreateForm, metaToVals } from '../../util/io_form-mat';
import { Item } from '../content.types';
import { exrate_meta } from '../meta_item/template.meta';

interface CompProps {
  item: Item;
  disabled_all: boolean;
  onChange: (changeInput: { exrate: any }) => void;
}

export function ExrateRows({ item, disabled_all, onChange }: CompProps) {
  const onSubmit = (x: any) => {
    onChange({ exrate: metaToVals(x) });
  };

  return (
    <>
      <div className="row m20_0">
        <div className="mw700 ">
          <div className="row text-center m10_0">
            <h2>Árfolyam adatok</h2>
          </div>
          <div className="row">
            <CreateForm
              data={item.exrate}
              meta={exrate_meta}
              exclude={[]}
              def={{}}
              disabled_all={disabled_all || false}
              button={false}
              cancel_button={false}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}
