import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { StoreProps } from '../../store.types';
import Modal from '../../util/client_jsx/modal-new';
import { Id, ItemRole } from '../content.types';
import templates from '../meta_item/templates';
import { selector_item, selector_role_list } from '../redux/content.selectors';
import { SplitTableBody, SplitTableHeader } from './list_3_split';

interface List2FrameProps {
  role: ItemRole;
  onAction: () => void;
}

export function List2Frame({ role, onAction }: List2FrameProps) {
  const role_list = useSelector((store: StoreProps) =>
    selector_role_list(store, role)
  );
  const template = templates.find((t) => t.role === role);

  return !role_list ? null : (
    <>
      <div className="row text-center">
        <h1>{template?.template || role} - lista</h1>
      </div>

      <div className="row p20">
        <Table list={role_list} role={role} onAction={onAction} />
      </div>

      <div className="row"></div>
      <div className="row"></div>
    </>
  );
}

interface TableProps {
  list: Id[];
  role: ItemRole;
  onAction: (action: { type: string; data: { role: ItemRole } }) => void;
}

function Table({ list, role, onAction }: TableProps) {
  const onNew = () => {
    onAction({ type: 'newitem', data: { role } });
  };

  return (
    <div className="">
      <div className="row m10_0">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={onNew}
          startIcon={<AddIcon />}
        >
          Új
        </Button>
      </div>
      <table className="w100 table_dark">
        <tbody>
          <SplitTableHeader role={role} />
          {list.map((itemid) => (
            <TableElement
              key={itemid}
              id={itemid}
              role={role}
              onAction={onAction}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

interface TableElementProps {
  id: Id;
  role: ItemRole;
  onAction: (action: { type: string; data: { role: ItemRole } }) => void;
}

function TableElement({ id, role, onAction }: TableElementProps) {
  const item = useSelector((store: StoreProps) => selector_item(store, id));
  const title = item?.title;
  const [delModalOn, delM] = useState(false);
  const getData = () => {
    return { id, role, title };
  };

  const onOpen = () => {
    onAction({ type: 'viewitem', data: getData() });
  };
  const onEdit = () => {
    onAction({ type: 'edititem', data: getData() });
  };
  const onDel = () => {
    delM(true);
  };
  const doDel = () => {
    onAction({ type: 'delitem', data: getData() });
    delM(false);
  };
  const noDel = () => {
    delM(false);
  };

  return !item ? null : (
    <>
      <SplitTableBody
        role={role}
        item={item}
        onOpen={onOpen}
        onEdit={onEdit}
        onDel={onDel}
      />
      <Modal
        open={delModalOn}
        onAction={doDel}
        onClose={noDel}
        title={item.title}
        button={'Törlés'}
      >
        <div className="row p20_0 text-center">
          <div>Valóban törölni szeretnéd?</div>
          <div className="row m20_0 text-center bold">{title}</div>
        </div>
      </Modal>
    </>
  );
}
