import { useState } from 'react';

import List1 from './m_list_1';
import QueryPage from './m_query';
import ReportPage from './m_report';

export default function ListOfReportsAndQueries({
  action,
}: {
  action: (inp: { type: string; data?: any }) => void;
}) {
  const [page, setP] = useState({ type: 'list', data: {} });

  const onAction = (act: any) => {
    switch (act.type) {
      case 'list':
      case 'report':
      case 'query':
        action({ type: 'bi_action', data: act });
        setP(act);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <InnerPage {...{ onAction, page }} />
    </>
  );
}

function InnerPage({
  onAction,
  page,
}: {
  onAction: (act: any) => void;
  page: any;
}) {
  switch (page.type) {
    case 'list':
      return (
        <>
          <div className="row text-center">
            <h1>Könyvelési kimutatások</h1>
          </div>
          <List1 onAction={onAction} type="report" />
          <List1 onAction={onAction} type="query" />
        </>
      );
    case 'report':
      return (
        <>
          <ReportPage onAction={onAction} meta={page.data} />
        </>
      );
    case 'query':
      return (
        <>
          <QueryPage onAction={onAction} meta={page.data} />
        </>
      );
    default:
      return null;
  }
}
