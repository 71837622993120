import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { round } from '../../util/common/number';
import { show_message } from '../../util/notification/pure.messages.actions';
import { newbooking_meta } from '../meta/meta_new';
import { BtottomButtons } from './booking_4_buttons';
import { DCdiff } from './booking_4_diff';
import { Errors } from './booking_4_errors';
import { HeadSection } from './booking_4_head';
import { ItemsSection } from './booking_4_items';

const currency_data_field = 'completition_date';

type BDprops = {
  template: any;
  onAction: (inp: { type: string; item?: any; bktemplate?: any }) => void;
  bookinData: any;
};

export function BookingData({ template, onAction, bookinData }: BDprops) {
  const dispatch = useDispatch();

  const newdata = () => {
    if (template) return template.bktemplate;
    else {
      const nd: any = { head_data: {}, head: {}, items: [] };
      for (const _h in newbooking_meta.head) {
        const h = _h as keyof typeof newbooking_meta.head;
        nd.head[h] = {
          meta: newbooking_meta.head[h],
          data: newbooking_meta.head[h].value || '',
          error: '',
          ok: true,
        };
      }
      for (const h in nd.head) nd.head_data[h] = nd.head[h].data;
      return {
        data: nd,
        ok: { head: true, items: false, eq: true },
        sum: { D: 0, C: 0 },
        auto_exrate: 1,
        count: 0,
      };
    }
  };

  const [item, setI] = useState(newdata());

  const Action = (action: any) => {
    const newitem: any = { ...item };
    switch (action.type) {
      case 'itemadd':
        newitem.count++;
        const newelement: any = {
          $id: '$' + newitem.count,
          error: null,
          data: {},
          ok: true,
          meta: newbooking_meta.item,
        };
        for (const e in newelement.meta) {
          newelement.data[e] =
            newelement.meta[e].value ||
            (['select2', 'select3'].includes(newelement.meta[e].type)
              ? newelement.meta[e].options[0]?.v
              : null) ||
            '';
        }
        newitem.data.items.push(newelement);
        checkItems(newitem);
        calcSum(newitem);
        setI(newitem);
        break;
      case 'itemdel':
        newitem.data.items = newitem.data.items.filter(
          (ii: any) => ii.$id !== action.$id
        );
        checkItems(newitem);
        calcSum(newitem);
        setI(newitem);
        break;
      case 'itemchange':
        const index = newitem.data.items.findIndex(
          (ii: any) => ii.$id === action.$id
        );
        const oldelement = { ...newitem.data.items[index] };
        const newelement2 = { ...oldelement, meta: action.meta };
        let newelok = true;
        for (const i_ in newelement2.meta) {
          newelement2.data[i_] = newelement2.meta[i_].data;
          newelok = newelok && newelement2.meta[i_].ok;
        }
        newelement2.ok = newelok;
        newitem.data.items[index] = { ...newelement2 };
        checkItems(newitem);
        calcSum(newitem);
        setI(newitem);
        break;

      case 'headchange':
        const { head } = action;
        let headok = true;
        newitem.data.head = { ...newitem.data.head, ...head };
        for (const h in newitem.data.head) {
          newitem.data.head_data[h] = newitem.data.head[h].data;
          headok = headok && newitem.data.head[h].ok;
        }
        newitem.ok.head = headok;
        setI(newitem);
        break;
      case 'set_auto_exrate':
        const { setto } = action;
        newitem.auto_exrate = setto;
        setI(newitem);
        break;
      case 'save_booking':
        if (item.ok.head && item.ok.items && item.ok.eq)
          onAction({ type: 'save_booking', item });
        else show_message('warning_ui_form_complete', dispatch);
        break;
      case 'save_template':
        if (item.ok.head && item.ok.items && item.ok.eq)
          onAction({ type: 'save_template', bktemplate: item });
        else show_message('warning_ui_form_complete', dispatch);
        break;
      case 'back_to_templates':
        onAction({ type: 'back_to_templates' });
        break;
      default:
        console.log(action);
        break;
    }
  };
  const backAction = () => {
    Action({ type: 'back_to_templates' });
  };

  return (
    <div className="row" style={{ margin: '120px 0px 50px', padding: '5px' }}>
      <div className="mw1000">
        <div className="row text-center">
          <h1>Új könyvelési tétel</h1>
          {!template ? null : (
            <div className="row op5 text-center">
              Sablon: #{template.id} - {template.title}{' '}
            </div>
          )}
        </div>

        <HeadSection
          head={item.data.head_data}
          meta={newbooking_meta.head}
          onAction={Action} // bookinData={bookinData}
        />

        <ItemsSection
          items={item.data.items}
          meta={newbooking_meta.item}
          onAction={Action}
          bookingData={bookinData}
          currency_date={item.data.head_data[currency_data_field]}
          auto_exrate={item.auto_exrate}
        />

        <DCdiff item={item} />
        <Errors item={item} />
        <BtottomButtons Action={Action} backAction={backAction} />
      </div>
    </div>
  );
}

const calcSum = (item_: any) => {
  const sum = { D: 0, C: 0 };
  for (const i_ of item_.data.items) {
    sum[i_.data.side as 'D' | 'C'] += i_.data.value;
  }
  sum.D = round(sum.D);
  sum.C = round(sum.C);
  item_.sum = { ...sum };
  item_.ok.eq = sum.D === sum.C;
};
const checkItems = (item_: any) => {
  let itemsok = true;
  if (item_.data.items.length === 0) itemsok = false;
  else {
    for (const i_ of item_.data.items) {
      itemsok = itemsok && i_.ok;
    }
  }
  item_.ok.items = itemsok;
};
