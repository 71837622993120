import type { ReduxAction, ReduxState } from '../content.types';

export function handle_item_exrate(action: ReduxAction, newstate: ReduxState) {
  const src = action.payload.item_exrate;

  if (src) {
    newstate.data.item_exrate = newstate.data.item_exrate || {};
    const dest = newstate.data.item_exrate;

    for (const row of src) {
      const { exrate_code, date } = row;
      //let year = date.substr(0, 4)
      //let month = date.substr(0, 7).substr(-2) * 1
      //let day = date.substr(-2) * 1
      dest[exrate_code] = dest[exrate_code] || {};
      dest[exrate_code][date] = { ...row };
      //dest[exrate_code][year] = dest[exrate_code][year] || {}
      //dest[exrate_code][year][date] = { ...row }
    }
  }
}

export function handle_item_ledger(action: ReduxAction, newstate: ReduxState) {
  if (action.payload.item_ledger) {
    newstate.data.item_ledger = newstate.data.item_ledger || {};

    for (const row of action.payload.item_ledger) {
      const { ledgeritem, active } = row;
      //newstate.data.item_ledger[ledger] = newstate.data.item_ledger[ledger] || {}
      if (active) newstate.data.item_ledger[ledgeritem] = { ...row };
      else delete newstate.data.item_ledger[ledgeritem];
    }
  }
}

export function handle_is_all_loaded(
  action: ReduxAction,
  newstate: ReduxState
) {
  let ok = true;
  if (!newstate.data.role_listed.partner) ok = false;
  if (!newstate.data.role_listed.exrate) ok = false;
  if (!newstate.data.role_listed.ledger) ok = false;
  if (!newstate.data.role_listed.bsasset) ok = false;
  if (!newstate.data.role_listed.bsaccount) ok = false;
  if (!newstate.data.role_listed.bktemplate) ok = false;
  if (!newstate.data.item_exrate) ok = false;
  if (!newstate.data.item_ledger) ok = false;
  newstate.data.items_for_booking_loaded = ok;
}
