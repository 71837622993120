import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { show_message } from '../../util/notification/pure.messages.actions';
import { Id, Item } from '../content.types';
import { item_back, item_save } from '../redux/content.actions';
import { EditItem2 } from './edit_2_frame';

interface CompProps {
  item: Item;
  edit: boolean;
  group_id?: Id;
  onAction: (input: any) => void;
}

export function EditItem({ onAction, item, edit, group_id }: CompProps) {
  const dispatch: Dispatch<any> = useDispatch();

  const onSave = async (newitem: Item) => {
    const group_id2 = item.group_id || group_id || 0;
    const newtemp = { ...newitem, id: item.id, group_id: group_id2 };
    const same = JSON.stringify(item) === JSON.stringify(newtemp);

    if (same) show_message('warning_data_unchanged', dispatch);
    else
      try {
        const res: any = await item_save(newtemp, dispatch);
        dispatch(item_back(res));
        let id = 0;
        try {
          id = res.$new_item || item.id;
        } catch {
          null;
        }
        onAction(
          // id
          //   ? { type: 'viewitem', data: { id, role: item.role } }
          //   :
          { type: 'listitems', data: { role: item.role } }
        );
      } catch (error) {
        console.log(error);
      }
  };

  return (
    <EditItem2 item={item} onSave={onSave} onAction={onAction} edit={edit} />
  );
}
