import Button from '@material-ui/core/Button';
import React, { useState } from 'react';

import { FormRowData } from './p4-field';
import { CreateState, valF } from './u-state';

export function CreateForm(props: any) {
  const {
    data,
    meta,
    def,
    autofocus,
    exclude,
    exclude_disabled,
    disabled,
    disabled_all,
    button,
    button_text,
    cancel_button,
    cancel_button_text,
    onSubmit,
    onCancel,
  } = props;

  const { fields, stateInit } = CreateState({
    data,
    meta,
    def,
    autofocus,
    exclude,
    exclude_disabled,
    disabled,
    disabled_all,
  });
  const [state, setState] = useState(stateInit);

  const submit = (what: any) => {
    if (onSubmit) onSubmit(what);
  };

  const onSubmit2 = (e: any) => {
    // event
    e.preventDefault();
    submit(state);
  };

  const onChange = (f: string, data: any) => {
    const valJ = valF(data, state[f].meta);
    const newstate = { ...state, [f]: { ...state[f], ...valJ } };
    if (!button) submit(newstate);
    setState(newstate);
  };

  return (
    <>
      <form onSubmit={onSubmit2}>
        <div className="row">
          {fields.map((f) => (
            <FormRowData
              key={f}
              data={state[f].data}
              meta={state[f].meta}
              error={state[f].error}
              name={f}
              onChange={(val: any) => onChange(f, val)}
            />
          ))}
        </div>
        {button || cancel_button ? (
          <div className="row text-center mtop30">
            {!button ? null : (
              <Button
                type="submit"
                color="primary"
                size="small"
                variant="contained"
              >
                {button_text}
              </Button>
            )}
            {!cancel_button ? null : (
              <Button
                type="submit"
                color="primary"
                size="small"
                variant="outlined"
                onClick={onCancel}
              >
                {cancel_button_text}
              </Button>
            )}
          </div>
        ) : null}
      </form>
    </>
  );
}

export function metaToVals(json: any) {
  const newvals: any = {};
  for (const i in json) newvals[i] = json[i].data;
  return newvals;
}
