import React, { useState } from 'react';

export function SetSwitch(props: any) {
  const { value, name, onChange, disabled } = props;
  const [value2, setValue] = useState(value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <label>{name}</label>
      <span
        className="switch"
        role="switch"
        aria-checked={!!value2}
        tabIndex={0}
        onKeyDown={() => null}
        onClick={() => {
          if (!disabled) {
            setValue(1 - value2);
            onChange(1 - value2);
          }
        }}
      >
        {value2 === 1 ? (
          <span className="fas fa-toggle-on cl_green"></span>
        ) : (
          <span className="fas fa-toggle-off cl_red op3x"></span>
        )}
      </span>
    </div>
  );
}
