import type { ItemRole, ReduxAction, ReduxState } from '../content.types';

export function handle_delete_item(action: ReduxAction, newstate: ReduxState) {
  if (action.payload.$del_item) {
    const { id } = action.payload.$del_item;

    if (newstate.data.item[id]) {
      const item = newstate.data.item[id];
      if (newstate.data.role_list[item.role]) {
        newstate.data.role_list[item.role] = newstate.data.role_list[
          item.role
        ]?.filter((rid) => rid !== id);
      }
    }

    delete newstate.data.item[id];
    newstate.data.itemids = Object.keys(newstate.data.item).map((id) => +id);

    const grid = newstate.data.item_group[id];
    delete newstate.data.item_group[id];
    delete newstate.data.group_item[grid]?.[id];
  }
}

export function handle_items(action: ReduxAction, newstate: ReduxState) {
  let items = action.payload.items;
  if (!items) return;
  if (!Array.isArray(items)) items = [items];

  if (action.payload.f_role) {
    const f_role: ItemRole = action.payload.f_role;
    newstate.data.role_list[f_role] = [];
    if (!newstate.data.role_listed[f_role])
      newstate.data.role_listed[f_role] = 1;
  }

  if (action.payload.f_all) {
    newstate.data.item = {};
    newstate.data.itemids = [];
  }
  if (items) {
    for (const item of items) {
      newstate.data.item[item.id] = { ...item };
      const role: ItemRole = item.role;
      newstate.data.role_list[role] = newstate.data.role_list[role] || [];
      if (!newstate.data.role_list[role]?.includes(item.id))
        newstate.data.role_list[role]!.push(item.id);
    }
    newstate.data.itemids = Object.keys(newstate.data.item).map((id) => +id);
  }
}
