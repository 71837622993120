import { BStatus } from '../booking.types';

type BookingConf = {
  status: Record<BStatus, string>;
  status_code: {
    open: BStatus;
    eq: BStatus;
    back: BStatus;
    storno: BStatus;
  };
};

export const booking_cnf: BookingConf = {
  status: {
    0: 'Nyitott',
    1: 'Kiegyenlített',
    2: 'Kiegyen.biz.',
    3: 'Feloldott',
    4: 'Feloldás',
    5: 'Sztornózott',
    6: 'Sztorno',
    9: 'Lezárt', // head
  },
  status_code: { open: 0, eq: 1, back: 2, storno: 9 },
};
