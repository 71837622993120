/* eslint-disable unicorn/consistent-function-scoping */
import { Button, Icon } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';

import { bookconfig } from '../../config/book.config';
import {
  downloadAsset,
  IframeErrors,
  reqDownloadPath,
  reqUpload,
} from '../util';
import { book_meta_active__xls } from './redux/booking.actions';

type stateP = {
  disabled: boolean;
  ok: boolean;
  ready: boolean;
  errors: any[];
  filename: string;
  iframeSrc: string;
  uploading: boolean;
  downloading: boolean;
};

const initState: stateP = {
  disabled: false,
  ok: true,
  ready: false,
  errors: [],
  filename: '',
  iframeSrc: '',
  uploading: false,
  downloading: false,
};

export const BookUpload = ({ onAction }: { onAction: (inp: any) => void }) => {
  const [state, setState] = React.useState<stateP>(initState);
  const dispatch = useDispatch();

  const onBooked = () => {
    onAction({ type: 'justbooked' });
  };

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      reqUpload('/booking/upload', file)
        .then((res) => {
          const { ok, errors } = res.data;
          setState({ ...initState, ok, errors, ready: true, uploading: false });
          if (errors.length === 0) {
            onBooked();
          }
        })
        .catch((error) => {
          console.log(error);
          setState({ ...initState, ok: false, ready: true, uploading: false });
        });
      setState({ ...initState, disabled: true, uploading: true });
    } else {
      setState({ ...initState });
    }
  };

  const onDownloadTemplate = () => {
    downloadAsset(bookconfig.UPLOAD_TEMPLATE)
      .then((res) => {
        const { filepath } = res;
        const file = reqDownloadPath(filepath);
        setState({ ...state, iframeSrc: file });
      })
      .catch(null);
  };

  const onDownloadLists = () => {
    book_meta_active__xls(dispatch)
      .then((res) => {
        const { filepath } = res;
        if (filepath) {
          const file = reqDownloadPath(filepath);
          setState({ ...state, iframeSrc: file, downloading: false });
        }
      })
      .catch(null);
    setState({ ...state, downloading: true });
  };

  const bstyle: React.CSSProperties = {
    minWidth: '130px',
    margin: '0px 5px',
  };

  return (
    <>
      <div className="row text-center mtop50">
        <h1>Feltöltés</h1>
      </div>
      <div className="row text-center mtop50">
        <Button
          color="primary"
          variant="contained"
          component="label"
          disabled={state.disabled || state.uploading}
          style={bstyle}
          startIcon={
            state.uploading ? (
              <Icon className="fas fa-spinner fa-spin" />
            ) : (
              <CloudUploadOutlined />
            )
          }
        >
          Feltöltés
          <input type="file" onChange={onFileChange} hidden />
        </Button>
        <Button
          color="primary"
          variant="outlined"
          component="label"
          style={bstyle}
          onClick={onDownloadTemplate}
          startIcon={<Icon className="far fa-file-excel" />}
        >
          Sablon
        </Button>
        <Button
          color="primary"
          variant="outlined"
          component="label"
          style={bstyle}
          onClick={onDownloadLists}
          startIcon={
            state.downloading ? (
              <Icon className="fas fa-spinner fa-spin" />
            ) : (
              <Icon className="far fa-file-excel" />
            )
          }
          disabled={state.downloading}
        >
          Listák
        </Button>
      </div>
      <div className="row text-center mtop50">
        <IframeErrors {...{ ready: state.ready, errors: state.errors }} />
      </div>
      <iframe
        id="downloadarea"
        title="downloadarea"
        src={state.iframeSrc}
        style={{ display: 'none' }}
      ></iframe>
    </>
  );
};
