import { Button } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React from 'react';

import Modal from '../../util/client_jsx/modal-new';
import { FilterForm } from './u-filter-form2';

type FBprops = {
  onFilter: (inp: any) => void;
  filter: any;
  fastfilters: any[];
};

export function FilterButton({ onFilter, filter, fastfilters }: FBprops) {
  const [open, setM] = React.useState(false);
  const [_filter, setF] = React.useState(filter);

  const openModal = () => {
    setM(true);
  };
  const noModal = () => {
    setM(false);
  };
  const yesModal = () => {
    onFilter(_filter);
    setM(false);
  };
  const onFinal = (newfilter: any) => {
    onFilter(newfilter);
    setM(false);
  };

  return (
    <>
      <Button
        key={'button-filter-table'}
        variant="outlined"
        color="primary"
        onClick={openModal}
        size="small"
        startIcon={<FilterList />}
      >
        Szűrő
      </Button>

      {!open ? null : (
        <Modal
          open={true}
          title={'Új szűrő hozzáadása'}
          button="Ok"
          onAction={yesModal}
          onClose={noModal}
        >
          <FilterForm
            {...{
              filter,
              onFilter: setF,
              onFinal,
              fastfilters,
            }}
          />
        </Modal>
      )}
    </>
  );
}
