import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import { booking_reducer } from './booking/redux/booking.reducer';
import { content_reducer } from './content/redux/content.reducer';
import { Store, StoreProps } from './store.types';
import { messages_reducer } from './util/notification/store.messages.reducer';

const middlewares = [thunk];

const reducers: StoreProps = {
  notification: messages_reducer,
  content: content_reducer,
  booking: booking_reducer,
};

export const store: Store = createStore(
  combineReducers(reducers),
  applyMiddleware(...middlewares)
  //,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
