import { Dispatch } from 'redux';

import { POST } from '../../util';

export const bi_query = (query: string, dispatch: Dispatch) => {
  return commonFx2('bi/query', { query }, dispatch);
};
export const bi_xlsx = (query: string, dispatch: Dispatch) => {
  return commonFx2('bi/xls', { query }, dispatch);
};

const commonFx2 = async (url: string, data: any, dispatch: Dispatch) => {
  dispatch({ type: 'BI_PENDING' });
  const x = await POST({ url, data });
  if (x) {
    dispatch({ type: 'BI_RES', payload: x });
    return x;
  } else {
    dispatch({ type: 'BI_ERR', e: 'error' });
  }
};
