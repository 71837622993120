import { useState } from 'react';

import { FilterForm } from '../../booking/u_util/u-filter-form';
import Data1 from './p_data_1';

interface CompProps {
  meta: any;
  onAction: (input: { type: string; data?: any }) => void;
}

export default function ReportsPage({ meta, onAction }: CompProps) {
  let query0 = { ...meta };
  if (query0.updatefx) query0 = query0.updatefx(query0);

  const [data, setQ] = useState({ refreshing: 0, query: query0 });

  const onRefresh = () => {
    setQ({ refreshing: 0, query: data.query });
  };
  const onFilter = (filter: any) => {
    const newfilters = { ...data.query.filters, ...filter };
    let newquery = { ...data.query, filters: newfilters };
    if (newquery.updatefx) newquery = newquery.updatefx(newquery);
    setQ({ refreshing: 1, query: newquery });
  };

  return (
    <>
      <div className="row m50_0">
        <div className="row text-center">
          <h1>{meta.title}</h1>
        </div>
        <div className="row mtop20">
          <FilterForm filter={meta.filters} onFilter={onFilter} />
        </div>

        <div className="row mtop20">
          {data.refreshing ? (
            <Refresh onRefresh={onRefresh} />
          ) : (
            <Data1 query={data.query} onAction={onAction} />
          )}
        </div>
      </div>
    </>
  );
}

function Refresh({ onRefresh }: { onRefresh: () => void }) {
  setTimeout(() => {
    onRefresh();
  }, 1);
  return null;
}
