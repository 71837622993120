const _f_vb_all_head2 =
  'id,head_id,name,booking_date,D,C,ledgeritem2,status,partner,bsitem,receipt_date,receipt_ref,text,text2,comment,comment2,value_orig,currency_orig,exchange_rate,completition_date,receipt_date,recording_date';
const _q_vb_all_head2 = {
  dataobject: 'li_vb_all_head2',
  fieldlist: _f_vb_all_head2,
  filters: '',
  orders: 'id desc',
};
/*let _m_agg_ChE_1 = [
    { code: 'change_2018', value: 'S', measure: 'change', parameter: { booking_year: '2018' } },
    { code: 'end_2018', value: 'S', measure: 'end', parameter: { booking_year: '2018' } },
    { code: 'change_2019', value: 'S', measure: 'change', parameter: { booking_year: '2019' } },
    { code: 'end_2019', value: 'S', measure: 'end', parameter: { booking_year: '2019' } },
]*/
const _m_agg_Res_1 = [
  //{ code: 'change_2017', value: 'S', measure: 'change', parameter: { booking_year: '2017' } },
  //{ code: 'change_2018', value: 'S', measure: 'change', parameter: { booking_year: '2018' } },
  {
    code: 'change_2020',
    value: 'S',
    measure: 'change',
    parameter: { booking_year: '2020' },
  },
  {
    code: 'change_2021',
    value: 'S',
    measure: 'change',
    parameter: { booking_year: '2021' },
  },
  {
    code: 'change_2022',
    value: 'S',
    measure: 'change',
    parameter: { booking_year: '2022' },
  },
  {
    code: 'change_2023',
    value: 'S',
    measure: 'change',
    parameter: { booking_year: '2023' },
  },
];
const _m_agg_DCF_1 = [
  { code: 'change_D', value: 'D', measure: 'sum', parameter: {} },
  { code: 'change_C', value: 'C', measure: 'sum', parameter: {} },
  { code: 'change_F', value: 'ST', measure: 'sum', parameter: {} },
];

export const queries_meta = [
  {
    title: 'Fökönyvi számok',
    dataobject: 'li_item_ledger',
    dims: [],
    measures: [],
    filters: '',
    orders: 'ledgeritem',
  },
  {
    title: 'Eredmény 2020-2023 (h3) = BESZÁMOLÓ',
    dataobject: 'ag_vb_period_cur',
    dims: ['h3'],
    measures: _m_agg_Res_1,
    filters: 'cat="P"',
  },
  {
    title: 'Eredmény 2020-2023 (h3, fksz)',
    dataobject: 'ag_vb_period_cur',
    dims: ['h3', 'ledgeritem2'],
    measures: _m_agg_Res_1,
    filters: 'cat="P"',
  },
  /*{
        title: 'Havi összesítő - 4. ÁFA',
        dataobject: 'ag_vb_period_cur',
        dims: ['booking_period desc', 'ledgeritem2'],
        measures: _m_agg_DCF_1,
        filters: 'h2="F.3.8. ÁFA"'  B.2.8 Áfa követelés
    },*/
  {
    title: 'Havi összesítő - B.4. Pénzeszközök',
    dataobject: 'ag_vb_period_cur',
    dims: ['booking_period', 'ledgeritem2'],
    measures: _m_agg_DCF_1,
    filters: 'h1 = "B.4. Pénzeszközök"',
    orders: 'booking_period desc',
  },
  {
    title: 'Havi összesítő - 3841000 OTP Elszámolási betétszámla',
    dataobject: 'ag_vb_period_cur',
    dims: ['booking_period'],
    measures: _m_agg_DCF_1,
    filters: 'ledgeritem = 3841000',
    orders: 'booking_period desc',
  },
  {
    title: 'Havi összesítő - 3851000 OTP Kamatozó betétszámlák',
    dataobject: 'ag_vb_period_cur',
    dims: ['booking_period'],
    measures: _m_agg_DCF_1,
    filters: 'ledgeritem = 3851000',
    orders: 'booking_period desc',
  },
  {
    title: 'Havi összesítő - 3843000 Barion',
    dataobject: 'ag_vb_period_cur',
    dims: ['booking_period'],
    measures: _m_agg_DCF_1,
    filters: 'ledgeritem = 3843000',
    orders: 'booking_period desc',
  },
  {
    title: 'Havi összesítő - F.3.5. NAV',
    dataobject: 'ag_vb_period_cur',
    dims: ['booking_period', 'ledgeritem2'],
    measures: _m_agg_DCF_1,
    filters: 'h2 = "F.3.5. NAV"',
    orders: 'booking_period desc',
  },

  {
    ..._q_vb_all_head2,
    title: 'Tételek - A.2. Tárgyi eszközök',
    filters: 'h1 = "A.2. Tárgyi eszközök" ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - B.2. Követelések',
    filters: 'h1 = "B.2. Követelések" ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - B.4. Pénzeszközök - 2023',
    filters: 'h1 = "B.4. Pénzeszközök" and booking_year=2023 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - B.4. Pénzeszközök - 2022',
    filters: 'h1 = "B.4. Pénzeszközök" and booking_year=2022 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - B.4. Pénzeszközök - 2021',
    filters: 'h1 = "B.4. Pénzeszközök" and booking_year=2021 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - B.4. Pénzeszközök - 2020',
    filters: 'h1 = "B.4. Pénzeszközök" and booking_year=2020 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - F.3.1. Szállító - 2023',
    filters: 'h2 = "F.3.1. Szállító" and booking_year=2023 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - F.3.1. Szállító - 2022',
    filters: 'h2 = "F.3.1. Szállító" and booking_year=2022 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - F.3.1. Szállító - 2021',
    filters: 'h2 = "F.3.1. Szállító" and booking_year=2021 ',
  },
  {
    ..._q_vb_all_head2,
    title: 'Tételek - F.3.1. Szállító - 2020',
    filters: 'h2 = "F.3.1. Szállító" and booking_year=2020 ',
  },

  //{ title: '!!! HIBÁS bizonylat összegek', dataobject: "li_v_check_head_sum" },
];
