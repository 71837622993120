import { datefx } from '../../content/meta_item/item.fx';
import { BHead, BItem, Id } from '../booking.types';

// ----------- client ---------
export const newbooking_meta = {
  head: {
    name: {
      text: 'Név',
      maxlength: '100',
      value: 'megnevezés...',
      validations: ['text'],
    },
    comment: { text: 'Komment 1', maxlength: '200', value: undefined },
    comment2: { text: 'Komment 2', maxlength: '200', value: undefined },
    booking_date: {
      text: 'Könyvelési dátum',
      type: 'date',
      value: datefx.today(),
      validations: ['date1'],
    },
    receipt_date: {
      text: 'Bizonylat dátum',
      type: 'date',
      value: datefx.today(),
      validations: ['date1'],
    },
    completition_date: {
      text: 'Teljesítés dátum',
      type: 'date',
      value: datefx.today(),
      validations: ['date1'],
    },
    receipt_type: {
      text: 'Bizonylat típusa',
      maxlength: '100',
      mt: 1,
      value: undefined,
    },
    receipt_ref: {
      text: 'Bizonylat referencia',
      maxlength: '200',
      value: undefined,
    },
  },

  item: {
    side: {
      text: 'T/K',
      type: 'select3',
      options: [
        { v: 'D', t: 'T' },
        { v: 'C', t: 'K' },
      ],
    },
    ledgeritem: { text: 'Főkönyvi szám', type: 'select2', options: [] },
    value_orig: {
      text: 'Érték',
      validations: ['number', 'nozero'],
      value: 1000,
    },
    currency_orig: {
      text: 'Deviza',
      type: 'select',
      options: ['HUF', 'EUR', 'USD'],
      value: 'HUF',
    },
    exchange_rate: { text: 'Árfolyam', validations: ['number'], value: 1 },
    value: {
      text: 'Érték HUF',
      type: 'rep_num',
      validations: ['number'],
      value: 1000,
    },
    currency: {
      text: 'Deviza HUF',
      type: 'select',
      options: ['HUF'],
      value: 'HUF',
      exclude: true,
    },
    partner_id: { text: 'Partner', type: 'select2', options: [] },
    bsitem_id: { text: 'Mérleg elem', type: 'select2', options: [] },
    text: { text: 'Szöveg 1', maxlength: '200' },
    text2: { text: 'Szöveg 2', maxlength: '200', exclude: true },
  },
};

// ----------- server --------
export const adjust_head_fields = (head: BHead, user: string) => {
  return {
    ...head,
    booking_period: head.booking_date.split('-').join('').slice(0, 6),
    recording_date: datefx.today(),
    user,
  };
};

export const adjust_item_fields = (item: BItem, head: BHead, head_id: Id) => {
  return {
    ...item,
    head_id,
    booking_period: head.booking_date.split('-').join('').slice(0, 6),
    partner_id: item.partner_id || null,
    bsitem_id: item.bsitem_id || null,
  };
};
