import { queries_meta } from '../meta/def_query';
import { reports_meta } from '../meta/def_report';

interface ListReportProps {
  type: string;
  onAction: (input: { type: string; data?: any }) => void;
}

export default function ListReport({ onAction, type }: ListReportProps) {
  const meta = type === 'report' ? reports_meta : queries_meta;

  return (
    <>
      <div className="row">
        <div className="mw1000">
          <div className="row mtop20 text-center">
            <h2>{type === 'report' ? 'Riportok' : 'Fix lekérdesek'}</h2>
          </div>
          <div className="row mtop10">
            <table className="table_dark w100 ">
              <tbody>
                {meta.map((i) => (
                  <ListItem
                    key={i.title}
                    type={type}
                    onAction={onAction}
                    listitem={i}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

interface ListItemProps {
  type: string;
  listitem: any;
  onAction: (input: { type: string; data?: any }) => void;
}

function ListItem({ onAction, listitem, type }: ListItemProps) {
  const clicked = () => {
    onAction({ type, data: listitem });
  };

  return (
    <>
      <tr onClick={clicked}>
        <td className="hcl_accent pointer">{listitem.title}</td>
      </tr>
    </>
  );
}
