import { Button, Divider } from '@material-ui/core';
import React from 'react';

import BookinEnv from '../../content/client_booking/BookinEnv';
import { HEADERS } from '../meta/fields';
import { SetFilter } from './u-filter-inputs';

type FilterFormProps = {
  filter: any;
  onFilter: (inp: Record<string, any>) => void;
  onFinal: (inp: Record<string, any>) => void;
  fastfilters: any[];
};

export function FilterForm({
  filter,
  onFilter,
  onFinal,
  fastfilters,
}: FilterFormProps) {
  const fields = Object.keys(filter);
  const [state, setS] = React.useState({ field: fields[0], value: '' });
  const onField = (field: any) => {
    setS({ field, value: '' });
  };

  const onValue = (newvals: any, isFinal = false) => {
    const field = Object.keys(newvals)[0];
    let value = newvals[field];
    if (value === undefined || value === null) value = '';
    const newstate = { field, value };
    if (isFinal) onFinal({ [field]: value });
    else {
      onFilter({ [field]: value });
      setS(newstate);
    }
  };

  return (
    <BookinEnv>
      <BookingFilters
        {...state}
        {...{ fields, onField, onValue, fastfilters }}
      />
    </BookinEnv>
  );
}

export function BookingFilters({
  field,
  value,
  fields,
  onField,
  onValue,
  fastfilters,
}: any) {
  return (
    <>
      <div className="mw500">
        <FastFilters {...{ fastfilters, onValue }} />
        <Div {...{ fastfilters }} />
        <NormalFilter
          {...{ field, value, fields, onField, onValue, fastfilters }}
        />
      </div>
    </>
  );
}

function FastFilters({ fastfilters, onValue }: any) {
  const onSelect = (filter: any) => {
    onValue(filter, true);
  };
  return !fastfilters ? null : (
    <>
      <div className="row op5">Gyors szűrők:</div>
      <div className="row">
        {fastfilters.map((ff: any, i: any) => (
          <Button
            //color="secondary"
            onClick={() => onSelect(ff.filter)}
            variant="outlined"
            style={{ margin: '5px' }}
            size="small"
            key={i + ff}
          >
            {ff.text}
          </Button>
        ))}
      </div>
    </>
  );
}

function Div({ fastfilters }: any) {
  return !fastfilters ? null : (
    <div className="row" style={{ padding: '20px 0px' }}>
      <Divider light />
    </div>
  );
}

function NormalFilter({ field, value, fields, onField, onValue }: any) {
  return (
    <>
      <div className="row op5">Normál szűrők:</div>
      <div className="row">
        {fields.map((f: keyof typeof HEADERS) => (
          <Button
            key={f}
            color="primary"
            size="small"
            variant={f === field ? 'contained' : 'outlined'}
            style={{ margin: '5px' }}
            onClick={() => onField(f)}
          >
            {HEADERS[f]?.headerName || f}
          </Button>
        ))}
      </div>
      <div className="row text-center mtop30">
        <SetFilter {...{ field, value, onValue }} />
      </div>
    </>
  );
}
