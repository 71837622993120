import Place from '../../util/client/cookie';

const tokenMEM: any = {};

const Tokens = {
  get: (name = 'jwt') => {
    const getCookie = Place.get(name) || null;
    return !getCookie ? tokenMEM[name] : getCookie;
  },

  set: (name = 'jwt', value: string | number) => {
    const setCookie = Place.set(name, value, 365);
    tokenMEM[name] = value;
    return setCookie;
  },

  del: (name = 'jwt') => {
    return Place.del(name);
  },
};

export default Tokens;
