export function fill(node1, node2, merge_array = true) {
  return _merge(node2, node1, merge_array);
}
export function update(node1, node2, merge_array = true) {
  return _merge(node1, node2, merge_array);
}

function _merge(n1, n2, merge_array, path = []) {
  let merged = null;
  let a1 = object_path(n1, path);
  let a2 = object_path(n2, path);
  let t1 = isArr(a1) ? 'arr' : typeof a1;
  let t2 = isArr(a2) ? 'arr' : typeof a2;

  if (a1 === undefined || a1 === null) merged = clone(a2);
  else if (a2 === undefined || a2 === null) merged = clone(a1);
  else if (t1 === 'arr' && t2 !== 'arr')
    merged = merge_array ? [...a1, a2] : [...a1];
  else if (t1 === 'arr' && t2 === 'arr')
    merged = merge_array ? array_uniq([...a1, ...a2]) : [...a2];
  else if (t1 === 'object' && t2 !== 'object') merged = clone(a1);
  else if (t1 === 'object' && t2 === 'object') {
    let keys = Object.keys({ ...a1, ...a2 });
    merged = {};
    for (const k of keys) {
      merged[k] = _merge(n1, n2, merge_array, [...path, k]);
    }
  } else merged = clone(a2);
  return merged;
}

function clone(v) {
  return v;
}

/*function clone2(obj) {
    if (typeof obj === 'object') return (JSON.parse(JSON.stringify(obj)));
    if (typeof obj === 'string') return "" + obj;
    if (typeof obj === 'number') return 3 * 0 + obj;
    else if (obj === undefined) return null;
    else if (obj === null) return null;
    else return obj;
}*/

function array_uniq(arr) {
  return arr.filter((v, i, a) => {
    return i === a.indexOf(v);
  });
}

function object_path(object, path) {
  if (!object) return null;
  if (path.length === 0) return object;
  else if (path.length === 1) return object[path[0]];
  else {
    let nextpath = [...path];
    nextpath.shift();
    return object_path(object[path[0]], nextpath);
  }
}

function isArr(obj) {
  return Array.isArray(obj);
}
