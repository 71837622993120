import { useState } from 'react';

import Modal from '../../util/client_jsx/modal-new';
import * as merge from '../../util/common/json.merge';
import { Item } from '../content.types';
import { ItemEditButtons } from './edit_3_buttons';
import { DataRows } from './edit_3_split';

interface CompProps {
  item: Item;
  edit: boolean;
  onAction: (input: any) => void;
  onSave: (input: any) => void;
}

export function EditItem2({ item, edit, onAction, onSave }: CompProps) {
  const [newitem, setI] = useState({ ...item });
  const [delModalOn, delM] = useState(false);
  const disabled_all = !edit;

  const onChange = (inItem: Partial<Item>) => {
    const new2 = merge.update(newitem, inItem);
    setI(new2);
  };

  const doSave = () => {
    if (onSave) onSave(newitem);
  };
  const doCancel = () => {
    onAction({ type: 'listitems', data: { role: item.role } });
  };
  const doEdit = () => {
    onAction({ type: 'edititem', data: { id: item.id, role: item.role } });
  };
  const openModal = () => {
    delM(true);
  };
  const doDel = () => {
    onAction({ type: 'delitem', data: { id: item.id, role: item.role } });
    delM(false);
  };
  const noDel = () => {
    delM(false);
  };
  const title = newitem?.title || item?.title;

  return (
    <>
      <div className="row">
        <DataRows {...{ item, disabled_all, onChange }} />
        <ItemEditButtons
          {...{ edit, item, doSave, doCancel, doEdit, openModal }}
        />
        <Modal
          open={delModalOn}
          onAction={doDel}
          onClose={noDel}
          title={item.title}
          button={'Törlés'}
        >
          <div className="row p20_0 text-center">
            <div>Valóban törölni szeretnéd?</div>
            <div className="row m20_0 text-center bold">{title}</div>
          </div>
        </Modal>
      </div>
    </>
  );
}
