/* eslint-disable unicorn/consistent-function-scoping */
import './grid2.scss';

import React from 'react';

import {
  ContextMenu,
  // ContextMenuListCreator,
  // useContextMenu,
} from '../../../util-s';
import { GridHead } from './p-head';
import { GridRows } from './p-rows';
import { Grid2Props } from './types';

export const Grid2 = ({
  rows,
  columns,
  canSelect,
  onSelect,
  onCellRightClick,
  pagination,
}: Grid2Props) => {
  const [selected, _setS] = React.useState<Array<string>>([]);
  const setS = (a: string[]) => {
    if (onSelect) onSelect(a);
    _setS(a);
  };
  const toggle = (id: string) => {
    const _selected = new Set(selected);
    if (_selected.has(id)) {
      _selected.delete(id);
    } else {
      _selected.add(id);
    }
    setS([..._selected]);
  };
  const htoggle = () => {
    if (selected.length > 0) {
      setS([]);
    } else {
      setS(rows?.map((r) => r.id) || []);
    }
  };

  return (
    <>
      <ContextMenu>
        <div id="grid2frame">
          <div id="grid2pagination">{pagination}</div>
          <div id="grid2tableframe">
            <table id="grid2table">
              <thead>
                <GridHead
                  {...{ columns, canSelect, selected, toggle, htoggle }}
                />
              </thead>
              <tbody>
                <GridRows
                  {...{
                    columns,
                    rows,
                    canSelect,
                    onCellRightClick,
                    selected,
                    toggle,
                    htoggle,
                  }}
                />
              </tbody>
            </table>
          </div>
        </div>
      </ContextMenu>
    </>
  );
};
