import { repNumber } from '../../util/common/number';

export function DCdiff({ item }: { item: any }) {
  return (
    <>
      <div className="row mtop20">
        <table className={'m0aX table_dark' + (item.ok.eq ? '' : ' cl_red')}>
          <tbody>
            <tr>
              <th>Tartozik</th>
              <th>Követel</th>
              <th>Különbség</th>
            </tr>
            <tr>
              <td>{repNumber(item.sum.D)}</td>
              <td>{repNumber(item.sum.C)}</td>
              <td>{repNumber(item.sum.D - item.sum.C)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
