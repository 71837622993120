import React from 'react';

import Modal from '../../util/client_jsx/modal-new';
import { CreateForm } from '../../util/io_form-mat';

type CompProps = {
  on: any;
  id: any;
  onCancel: any;
  onSend: any;
  form_data: any;
  form_meta: any;
  question: any;
};

export const EqModal = ({
  on,
  id,
  onCancel,
  onSend,
  form_data,
  form_meta,
  question,
}: CompProps) => {
  const [fdata, setF] = React.useState({ data: form_data, ok: true });

  const SetData = (_fdata: any) => {
    let ok = true;
    const ndata: any = {};
    for (const f of Object.keys(_fdata)) {
      ndata[f] = _fdata[f]?.data;
      ok = ok && _fdata[f].ok;
    }
    setF({ data: ndata, ok });
  };
  const onAction = () => {
    if (fdata.ok) onSend(fdata.data);
  };

  return on ? (
    <DataModal
      {...{ onAction, onCancel, question, id, SetData, form_data, form_meta }}
    />
  ) : null;
};

function DataModal({
  onAction,
  onCancel,
  question,
  id,
  SetData,
  form_data,
  form_meta,
}: any) {
  return (
    <Modal
      open={true}
      title={'Feloldás'}
      button="Igen"
      onAction={onAction}
      onClose={onCancel}
    >
      <div className="row p20_0 text-center">
        <div className="row">{question}</div>
        <div className="row mtop20 bold cl_accent2 text-center">
          <h3>{id}</h3>
        </div>
        <DataForm
          onChange={SetData}
          form_data={form_data}
          form_meta={form_meta}
        />
      </div>
    </Modal>
  );
}

function DataForm({ onChange, form_data, form_meta }: any) {
  return (
    <>
      <div className="row p20_0">
        <div className="mw400">
          <CreateForm
            data={form_data}
            meta={form_meta}
            css={'small2 text-left'}
            exclude={[]}
            disabled_all={false}
            autofocus={false}
            button={false}
            cancel_button={false}
            onSubmit={onChange}
          />
        </div>
      </div>
    </>
  );
}
