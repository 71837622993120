import { useSelector } from 'react-redux';

import { StoreProps } from '../../store.types';
import { ItemData } from '../content.types';
import { selector_item } from '../redux/content.selectors';
import { EditItem } from './edit_1_save';

interface CompProps {
  data: ItemData;
  edit: boolean;
  onAction: (input: any) => void;
}

export function ItemOpen({ data, onAction, edit }: CompProps) {
  const { id } = data;
  const item = useSelector((store: StoreProps) => selector_item(store, id));

  return !item ? null : (
    <>
      <div className="row" style={{ margin: '120px 0px 50px', padding: '5px' }}>
        <div className="row text-center">
          <h1>
            {item.id}. {item.title || '...'}
          </h1>
        </div>
        <div className="row text-center mtop20">
          <EditItem {...{ item, onAction, edit }} />
        </div>
      </div>
    </>
  );
}
