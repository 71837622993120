// import { ButtonXls } from './u-xls';
import { Column, Row } from './xtypes';

type ButtonProps = {
  rows?: Row[];
  columns: Column[];
  buttons: React.ReactNode;
  noexport: boolean;
};

export function Buttons({ buttons, noexport }: ButtonProps) {
  return !buttons && noexport ? null : (
    <>
      <div className="row p5_0 text-left space-buttons">
        {buttons}
        {/* {noexport ? null : <ButtonXls {...{ rows, columns }} />} */}
      </div>
    </>
  );
}
