import { arr_unique } from '../../util/common/fx2';

type sOn = string | number;

export const mergeItemA = (primary_string: string, secondary_array: sOn[]) => {
  let itemAll = secondary_array;
  itemAll = itemAll.map((x: sOn) => Number.parseInt('' + x));

  let itemA: sOn[] = (primary_string || '').split(',') || [];
  if (itemA[0] === '') itemA.shift();
  itemA = itemA
    .map((x: sOn) => Number.parseInt('' + x))
    .filter((y) => itemAll.includes(y));

  const itemA2 = arr_unique([...itemA, ...itemAll]);
  return itemA2;
};
