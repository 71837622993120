import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../util/client_jsx/modal-new';
import { BookingData } from './booking_3_bookdata';
import { saveBooking } from './ua-saveBooking';
import { saveTemplate } from './ua-saveTemplate';

type BTprops = {
  template: any;
  onAction: any;
  backAction: any;
  bookinData: any;
};

export function BookingTemplate({
  template,
  onAction,
  backAction,
  bookinData,
}: BTprops) {
  const [template_id, setNewTId] = useState(template?.id || 0);
  const [modalData, setM] = useState(null);
  const dispatch = useDispatch();

  const yesModal = () => {
    saveBooking({ act: modalData, dispatch, onAction });
    setM(null);
  };
  const noModal = () => {
    setM(null);
  };

  const Action = (act: any) => {
    switch (act.type) {
      case 'save_booking':
        setM(act);
        break;
      case 'save_template':
        saveTemplate({ act, dispatch, template_id, setNewTId, bookinData });
        break;
      case 'back_to_templates':
        backAction();
        break;
      default:
        break;
    }
  };
  return (
    <>
      <SaveModal {...{ modalData, yesModal, noModal }} />
      <BookingData
        template={template}
        onAction={Action}
        bookinData={bookinData}
      />
    </>
  );
}

const SaveModal = ({ modalData, yesModal, noModal }: any) => {
  return (
    <Modal
      open={!!modalData}
      title={'Új tétel rögzítése'}
      button="Igen"
      onAction={yesModal}
      onClose={noModal}
    >
      <div className="row p20_0 text-center">
        <div className="row">Valóban rögzíteni akarod a tételt!</div>
      </div>
    </Modal>
  );
};
