export function Errors({ item }: { item: any }) {
  return (
    <>
      <div className="row mtop10 cl_red">
        {item.ok.eq & item.ok.head & item.ok.items ? (
          <div>
            <span className="bold m5 op0">Hibák:</span>
          </div>
        ) : (
          <div>
            <span className="bold m5">Hibák:</span>
            {item.ok.head ? null : <span className="m5">fejadatok</span>}
            {item.ok.items ? null : <span className="m5">elemek</span>}
            {item.ok.nozero ? null : <span className="m5">0-ás tétel</span>}
            {item.ok.eq ? null : <span className="m5">T-K egyenlőség</span>}
          </div>
        )}
      </div>
    </>
  );
}
