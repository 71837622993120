import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleroot: {
      margin: 0,
      padding: theme.spacing(2),
    },
    titlecloseButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    contentroot: {
      padding: theme.spacing(2),
    },
    actionroot: {
      margin: 0,
      padding: theme.spacing(1),
    },
  })
);

const DialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.titleroot} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.titlecloseButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = (props: any) => {
  const classes = useStyles();
  return (
    <MuiDialogContent className={classes.contentroot}>
      {props.children}
    </MuiDialogContent>
  );
};

const DialogActions = (props: any) => {
  const classes = useStyles();
  return (
    <MuiDialogActions className={classes.actionroot}>
      {props.children}
    </MuiDialogActions>
  );
};

export default function CustomizedDialogs({
  open,
  onClose,
  onAction,
  title,
  children,
  button,
  disabled,
}: any) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" size="small">
          Mégse
        </Button>
        <Button
          onClick={onAction}
          color="primary"
          disabled={disabled}
          variant="contained"
          size="small"
        >
          {button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
