import { Dispatch } from 'redux';

import { book_head__get, book_head__xls } from '../redux/booking.actions';

type XFX = {
  filter: any;
  limit?: any;
  dispatch: Dispatch;
};

export const exportFX = async ({ filter, limit, dispatch }: XFX) => {
  const input = { filters: filter, limit };
  return book_head__xls(input, dispatch);
};

type RFX = {
  filter: any;
  page: any;
  setFX: (inp: any) => void;
  dispatch: Dispatch;
};

export const requestFX = ({ filter, page, setFX, dispatch }: RFX) => {
  const input = { filters: filter, page };
  book_head__get(input, dispatch)
    .then((res) => setFX(requestHandle(res)))
    .catch(null);
};

const requestHandle = (res: any) => {
  const new_ids = res.booking_head.map((row: any) => row.id);
  const set_to = {
    // finished: res.finished,
    count: res.count,
    page: res.page,
    pageNr: res.pageNr,
    pageSize: res.pageSize,
    rowCount: res.recordNr,
    ids: new_ids,
  };
  return set_to;
};
