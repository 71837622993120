import { valGen } from '../client_http/h_validate';

export const valF = (_data: any, _meta: any) => {
  let _error = '';
  const errFx = (_err: any) => {
    _error = _err;
  };
  const ok = valGen(_data, _meta.validations, _meta.errorhint, errFx);
  return { data: _data, error: _error, ok };
};

export function CreateState({
  data,
  meta,
  def,
  autofocus,
  exclude,
  exclude_disabled,
  disabled,
  disabled_all,
}: any) {
  let fields = Object.keys(meta).filter((f) => {
    return !(exclude || []).includes(f) && !meta[f].exclude;
  });

  const stateInit: any = {};
  let _autofocued = false;
  for (const f of fields) {
    const _data =
      data[f] !== undefined
        ? data[f]
        : (meta[f] ? meta[f].value : undefined) ||
          (def ? def[f] : undefined) ||
          '';

    const _meta = {
      type: 'text',
      validations: [],
      errorhint: '',
      ...meta[f],
      disabled:
        meta[f].disabled || (disabled || []).includes(f) || disabled_all,
    };
    const _doautofocus: any =
      !_autofocued &&
      (autofocus || false) &&
      _meta.type === 'text' &&
      !_meta.disabled;
    _meta.autofucus = _doautofocus;
    _autofocued = _autofocued || _doautofocus;
    stateInit[f] = {
      meta: _meta,
      ...valF(_data, _meta),
    };
  }
  if (exclude_disabled && !disabled_all)
    fields = fields.filter((f) => !stateInit[f].meta.disabled);

  return { fields, stateInit };
}
