import { bookconfig as CNF } from '../../../config/book.config';
import { Balance, BItem, Id, NewBItem } from '../booking.types';

const { LOCAL_CURR, EXRATE_DIFF_C, EXRATE_DIFF_D } = CNF;

export const calcBalanceFromAll = (selected: Id[], items: BItem[]) => {
  const sitems = selected.map((i) => items[i]);
  return calcBalance(sitems);
};
const trunc = (x: number, d = 4) => {
  return Math.trunc(x * 10 ** d) / 10 ** d;
};

export const calcBalance = (items: BItem[]) => {
  if (items.length === 0)
    return { total: { local: 0 }, ok: false, pair_curr: 'local' };
  let balance: any = {
    total: { local: 0 },
    ok: true,
    pair_curr: 'local',
  };

  balance = items.reduce(
    (prev: Balance, item) => {
      const { value, value_orig, side, currency_orig, ledgeritem, status } =
        item;
      const ch = (side === 'D' ? 1 : -1) * value;
      const chc = (side === 'D' ? 1 : -1) * value_orig;
      return {
        ...prev,
        total: {
          ...prev.total,
          local: prev.total.local + ch,
          [currency_orig]: (prev.total[currency_orig] || 0) + chc,
        },
        [ledgeritem]: {
          ...prev[ledgeritem],
          local: (prev[ledgeritem] || { local: 0 }).local + ch,
          [currency_orig]: ((prev[ledgeritem] || {})[currency_orig] || 0) + chc,
        },
        ok: prev.ok && status === 0,
      };
    },
    { ...balance }
  );

  // const { total, ok, pair_curr, ...ledgeritems } = balance;
  const ledgers = Object.keys(balance).filter(
    (k: string) => !['ok', 'pair_curr'].includes(k)
  );
  for (const l of ledgers) {
    for (const c of Object.keys(balance[l])) {
      balance[l][c] = trunc(balance[l][c], c === 'local' ? 0 : 2);
    }
  }

  const { total } = balance;

  const { local, ...currJson } = total;
  const currJsonWO = { ...currJson };
  delete currJsonWO[LOCAL_CURR];
  const currs = Object.keys(currJson);
  const currsWO = Object.keys(currJsonWO);
  const other_curr = currsWO[0] || null;
  const other_val = other_curr ? currJsonWO[other_curr] : total;

  if (currs.length >= 2) {
    if (local !== 0) balance.ok = false;
  } else if (currs.length === 1) {
    if (local !== 0 && other_val !== 0) balance.ok = false;
    else if (local !== 0 && other_val === 0) balance.pair_curr = other_curr;
  }

  return balance;
};

type CompsProps = {
  [currecy: string]: {
    valueS: number;
    value_origS: number;
  };
};

export const willBook = (balance: Balance) => {
  const { total, pair_curr, ok, ...ledgers } = balance;

  const COMPS: CompsProps = {};

  const addComps = (item: NewBItem) => {
    if (!item) return;
    const { value, value_orig, currency_orig, side } = item;
    COMPS[currency_orig] = COMPS[currency_orig] || {
      valueS: 0,
      value_origS: 0,
    };
    COMPS[currency_orig].value_origS += value_orig * (side === 'C' ? 1 : -1);
    COMPS[currency_orig].valueS += value * (side === 'C' ? 1 : -1);
  };

  const bookElems = (toBook: NewBItem[]) => {
    for (const l in ledgers) {
      const newItem: NewBItem | null = newItemFx(l, ledgers[l], pair_curr);
      if (newItem) {
        toBook.push(newItem);
        addComps(newItem);
      }
    }
  };

  const bookComps = (toBook: NewBItem[]) => {
    for (const currency_orig in COMPS) {
      let { valueS, value_origS } = COMPS[currency_orig];
      const side = valueS > 0 ? 'D' : 'C';
      valueS = trunc(valueS);
      value_origS = trunc(value_origS);
      if (!valueS) continue;

      const value = Math.abs(valueS);
      const value_orig = Math.abs(value_origS);

      const ledgeritem = side === 'D' ? EXRATE_DIFF_D : EXRATE_DIFF_C;
      const currency = LOCAL_CURR;
      const status = 2;
      const exchange_rate = 1;

      toBook.push({
        ledgeritem,
        side,
        status,
        value,
        value_orig,
        currency,
        currency_orig,
        exchange_rate,
      });
    }
  };

  const toBook: NewBItem[] = [];
  bookElems(toBook);
  bookComps(toBook);
  return toBook;
};

const newItemFx = (
  ledgeritem: string,
  ledgerbals: Record<string, number>,
  pair_curr: string
): NewBItem | null => {
  const side_value = ledgerbals.local + (ledgerbals[pair_curr] || 0);
  const side = side_value >= 0 ? 'C' : 'D';

  const value = Math.abs(ledgerbals.local);
  const value_orig = Math.abs(
    pair_curr === 'local' ? ledgerbals.local : ledgerbals[pair_curr]
  );
  const currency = LOCAL_CURR;
  const currency_orig = pair_curr === 'local' ? LOCAL_CURR : pair_curr;
  const status = 1;
  const exchange_rate = 1;
  return value + value_orig
    ? {
        ledgeritem,
        value,
        value_orig,
        currency,
        currency_orig,
        side,
        status,
        exchange_rate,
      }
    : null;
};
