import BookinEnv from '../content/client_booking/BookinEnv';
import { BItem2 } from './booking.types';
import { FIELDS } from './meta/fields';
import { selector_booking_items } from './redux/booking.selectors';
import { ContextMenuProps, Frame } from './u_common/frame';
import { EqButtonText } from './u_common/u-extra-item';
import { exportFX, requestFX } from './u_common/u-req-itemh';
import { selectFX } from './u_common/u-select-item';

type BIprops = { onAction: any; filter: any };

export function BookedItems({ onAction, filter }: BIprops) {
  return (
    <BookinEnv>
      <Frame
        {...{
          title: 'Könyvelési Tételek',
          onAction,
          filter,
          fields: FIELDS.itemh,
          selector: selector_booking_items,
          canSelect: true,
          requestFX,
          exportFX,
          selectFX,
          basefilter,
          fastfilters,
          Extra: EqButtonText,
          onCellRightClick: (inp: RightClickProps) =>
            onCellRightClick(inp, onAction),
        }}
      />
    </BookinEnv>
  );
}

type RightClickProps = Partial<BItem2> & { hid2: number; name: string };

const onCellRightClick = (
  inp: RightClickProps,
  onAction: (inp2: any) => void
): ContextMenuProps => {
  console.log(inp);
  return {
    list: [
      {
        text: 'Bizonylat',
        onClick: () => {
          onAction({
            type: 'add_page',
            data: {
              type: 'booking_heads',
              text: 'Tételek',
              data: { filter: { id: inp.hid2 } },
            },
          });
        },
      },
    ],
    title:
      `#${inp.id} - ${inp.name.slice(0, 20)}` +
      (inp.name.length > 20 ? '...' : ''),
  };
};

const basefilter = {
  id: '',
  booking_year: '',
  booking_period: '',
  ledgeritem: '', //[],
  status: '',
  currency_orig: '',
  eq_id: '',
  head_id: '',
};

const fastfilters = [
  { text: 'nyitott', filter: { status: 0 } },
  { text: '2021', filter: { booking_year: 2021 } },
  { text: 'EUR', filter: { currency_orig: 'EUR' } },
  { text: 'USD', filter: { currency_orig: 'USD' } },
];
