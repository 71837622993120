import { Color } from '@material-ui/lab/Alert';

type StatProps = {
  messages: any[];
  xcounter: number;
};

type ActionProps = {
  type: string;
  id?: number | string;
  message?: MessageProps;
  messages?: MessageProps[];
};

export type MessageProps = {
  id?: string;
  lan?: string;
  text: string;
  type: Color;
};

const default_message_state: StatProps = {
  messages: [],
  xcounter: 1,
};

export function messages_reducer(
  state = default_message_state,
  action: ActionProps
) {
  const newstate = { ...state };
  const delOne = (id: number | string) => {
    newstate.messages = newstate.messages.filter((x: any) => x.id !== id);
  };
  const addOne = (message: any) => {
    newstate.xcounter++;
    newstate.messages.push({ ...message, id: newstate.xcounter });
  };

  switch (action.type) {
    case 'MESSAGE_ADD':
      addOne(action.message);
      break;

    case 'MESSAGE_ADD_MORE':
      for (const message of action.messages!) {
        addOne(message);
      }
      break;

    case 'MESSAGE_DEL':
      delOne(action.id!);
      break;

    default:
      break;
  }
  return newstate;
}
