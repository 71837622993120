type RowType = {
  hu: string;
  en: string;
  type?: string;
};

const messages: Record<string, RowType> = {
  info_logout: { hu: 'Kijelentkeztél az alkalmazásból.', en: '' },
  info_email_sent: { hu: 'Az email kiküldésre került.', en: '' },

  error_general: { hu: 'A művelet során hiba történt!', en: '' },

  error_privileges: { hu: 'Nincs jogosultságod ehhez a művelethez!', en: '' },
  error_priv_loggedin: {
    hu: 'Ehhez a művelethez be kell jelentkezned!',
    en: '',
  },
  error_priv_admin: { hu: 'Nincs admin jogosultságod!', en: '' },

  warning_ui_form_complete: {
    hu: 'Az űrlapot hibátlanul kell kitölteni!',
    en: '',
  },
  warning_ui_register_accept_terms: {
    hu: 'El kell fogadnod a szabályzatot!',
    en: '',
  },
  warning_ui_course_no_subscription: {
    hu: 'Nincs érvényes előfizetésed!',
    en: '',
  },

  success_data_save: { hu: 'Az adatokat sikeresen mentettük!', en: '' },
  error_data_save: { hu: 'Az adatokat nem sikerült menteni!', en: '' },
  warning_data_unchanged: { hu: 'Az adatok nem változtak.', en: '' },

  error_jwt: { hu: 'Az azonosítód lejárt. Jelentkezz be újra!', en: '' },

  info_booking_history_deleted: { hu: 'Historikus adatok törölve', en: '' },
  warning_booking_history_notdeleted: {
    hu: 'Nem került sor adattörlésre!',
    en: '',
  },
  error_booking_history_notdeleted: {
    hu: 'Hiba történt az adatok törlése során!',
    en: '',
  },

  success_booking_history_loaded: {
    hu: 'Historikus adatok betöltésre kerültek',
    en: '',
  },
  warning_booking_history_notloaded: {
    hu: 'Nem került sor adattöltésre!',
    en: '',
  },
  error_booking_history_notloaded: {
    hu: 'Hiba történt az adatok betöltése során!',
    en: '',
  },

  x1: { hu: '', en: '' },
  x2: { hu: '', en: '' },
};

export default messages;
