import * as React from 'react';

type ContextValues = {
  page: number;
  count: number;
};

type ContextProps = ContextValues & {
  onPageChange: (pg: number) => void;
};

const PageContext = React.createContext<ContextProps>({
  page: 1,
  count: 0,
  onPageChange: (pg) => null,
});

type ProviderProps = {
  children: React.ReactNode;
  page: number;
  count: number;
  onPageChange: (pg: number) => void;
};

export const PageProvider = ({
  children,
  page,
  count,
  onPageChange,
}: ProviderProps) => {
  return (
    <PageContext.Provider value={{ page, count, onPageChange }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = () => React.useContext(PageContext);
