import { useDispatch, useSelector } from 'react-redux';

import { StoreProps } from '../../store.types';
import { ItemRole } from '../content.types';
import { item_get_role } from '../redux/content.actions';
import { selector_role_listed } from '../redux/content.selectors';
import { List2Frame } from './list_2_frame';

interface List1MainProps {
  role: ItemRole;
  onAction: () => void;
}

export function List1Main({ role, onAction }: List1MainProps) {
  const role_listed = useSelector((store: StoreProps) =>
    selector_role_listed(store, role)
  );
  const dispatch = useDispatch();

  if (!role_listed) {
    dispatch(item_get_role(role));
  }

  return <List2Frame {...{ role, onAction }} />;
}
