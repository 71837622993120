import { ItemTitle, LedgerName } from '../../content/client_booking/lookups';
import { BookingContextProps } from '../../content/content.types';
import { repNumber } from '../../util/common/number';
import { BItem2, BStatus } from '../booking.types';
import { booking_cnf } from './booking_meta';

const StatusTitle = (status: BStatus) => booking_cnf.status?.[status] || status;

export const FIELDS = {
  head: [
    'hid',
    'name',
    'sum',
    'statusT',
    'status_code',
    'booking_period',
    'booking_date',
    'recording_date',
    'receipt_date',
    'receipt_ref',
    'comment',
    'comment2',
    'user',
  ],
  itemh: [
    'iid',
    'hid2',
    'booking_period',
    'ledgeritem0',
    'Dn',
    'Cn',
    'value_orig',
    'currency_orig',
    'statusT',
    'status_code',
    'eid2',
    'name',
    'partner_bsitem',
    'comments_texts',
    'booking_date',
    'recording_date',
  ],
  eq: ['eid', 'statusT', 'created'],
};

type Row = BItem2;

const partner_bsitem = (row: Row, bookinContext: BookingContextProps) => {
  let re1 = ItemTitle(row?.partner_id, bookinContext) || '';
  const re2 = ItemTitle(row?.bsitem_id, bookinContext) || '';
  if (re1 && re2) re1 += ' / ';
  return re1 + re2;
};
const comments_texts = (row: Row) => {
  let re1 = `${row?.text || ''} ${row?.text2 || ''}`.trim();
  const re2 = `${row?.comment || ''} ${row?.comment2 || ''}`.trim();
  if (re1 && re2) re1 += ' / ';
  return re1 + re2;
};

export const META = (bookinContext: BookingContextProps) => ({
  FIELDS: {
    id: (row: Row) => row?.id,
    hid: (row: Row) => row?.id,
    hid2: (row: Row) => row?.head_id,
    iid: (row: Row) => row?.id,
    eid: (row: Row) => row?.id,
    eid2: (row: Row) => row?.eq_id,

    name: (row: Row) => row?.name,
    sum: (row: Row) => row?.sum, // `${repNumber(row.sum, 0)} HUF`,
    status: (row: Row) => row?.status,
    status_code: (row: Row) => row?.status,
    statusT: (row: Row) => StatusTitle(row?.status),
    booking_period: (row: Row) => row?.booking_period,
    booking_date: (row: Row) => row?.booking_date,
    recording_date: (row: Row) => row?.recording_date,
    receipt_date: (row: Row) => row?.receipt_date,
    receipt_ref: (row: Row) => row?.receipt_ref,
    comment: (row: Row) => row?.comment,
    comment2: (row: Row) => row?.comment2,
    comments: (row: Row) => `${row?.comment || ''} ${row?.comment2 || ''}`, // extra
    user: (row: Row) => row?.user,

    ledgeritem0: (row: Row) => row?.ledgeritem,
    ledgeritem: (row: Row) =>
      `${row?.ledgeritem} ${LedgerName(row?.ledgeritem, bookinContext)}`,
    D: (row: Row) => (row?.side === 'D' ? repNumber(row?.value, 0) : ''),
    Dn: (row: Row) => (row?.side === 'D' ? row?.value || 0 : ''),
    C: (row: Row) => (row?.side === 'C' ? repNumber(row?.value, 0) : ''),
    Cn: (row: Row) => (row?.side === 'C' ? row?.value || 0 : ''),
    cur: (row: Row) => `${repNumber(row?.value_orig, 0)} ${row?.currency_orig}`,
    text: (row: Row) => row?.text,
    text2: (row: Row) => row?.text2,
    texts: (row: Row) => `${row?.text || ''} ${row?.text2 || ''}`, // extra

    comments_texts: (row: Row) => comments_texts(row),

    partner: (row: Row) => ItemTitle(row?.partner_id, bookinContext),
    bsitem: (row: Row) => ItemTitle(row?.bsitem_id, bookinContext),
    partner_bsitem: (row: Row) => partner_bsitem(row, bookinContext),

    created: (row: Row) => row?.created,
    value: (row: Row) => row?.value,
    value_orig: (row: Row) => row?.value_orig,
    currency: (row: Row) => row?.currency,
    currency_orig: (row: Row) => row?.currency_orig,
    booking_year: (row: Row) => row?.booking_year,
  },
  HEADERS,
});

const numCol = { width: 150, type: 'number', cellClassName: 'cl_accent2' };
const numColS = { width: 100, type: 'number', cellClassName: 'cl_accent2' };
const textCol = {
  /*cellClassName: 'gridCol', headerClassName: 'gridCol'*/ width: 250,
};
const datecol = { width: 100 };

export const HEADERS = {
  id: { headerName: 'ID', width: 70 },
  hid: { headerName: 'B.ID', width: 70 },
  hid2: { headerName: 'B.ID', width: 70 },
  iid: { headerName: 'T.ID', width: 70 },
  eid: { headerName: 'K.ID', width: 70 },
  eid2: { headerName: 'K.ID', width: 70 },

  eq_id: { headerName: 'Kiegy.ID', width: 70 },
  head_id: { headerName: 'Biz.ID', width: 70 },

  /*
    hid: { headerName: "B.ID", width: 80, valueFormatter: (params) => 'B' + params.value },
    hid2: { headerName: "B.ID", width: 80, valueFormatter: (params) => 'B' + params.value },
    iid: { headerName: "T.ID", width: 80, valueFormatter: (params) => 'T' + params.value },
    eid: { headerName: "K.ID", width: 80, valueFormatter: (params) => params.value ? 'K' + params.value : '' },
    eid2: { headerName: "K.ID", width: 100, valueFormatter: (params) => params.value ? 'K' + params.value : '' },
    */

  name: { headerName: 'Név', ...textCol },
  sum: { headerName: 'HUF összeg', ...numCol },
  status: { headerName: 'Státusz', width: 80 },
  status_code: { headerName: 'St.kód', width: 80, hide: true },
  statusT: { headerName: 'Státusz', width: 120 },
  booking_period: { headerName: 'Időszak', width: 80 },
  booking_date: { headerName: 'Könyvelési', ...datecol },
  recording_date: { headerName: 'Rögzítési', ...datecol },
  receipt_date: { headerName: 'Bizonylat', ...datecol },
  receipt_ref: { headerName: 'Referencia', width: 130 },
  comment: { headerName: 'Komment', width: 200 },
  comment2: { headerName: 'Komment2', width: 200 },
  comments: { headerName: 'Komment', ...textCol }, // extra
  user: { headerName: 'User', width: 160 },

  ledgeritem0: { headerName: 'Főkönyv', width: 80 },
  ledgeritem: { headerName: 'Főkönyv', width: 140 },
  D: { headerName: 'T', ...numColS },
  Dn: { headerName: 'T', ...numColS },
  C: { headerName: 'K', ...numColS },
  Cn: { headerName: 'K', ...numColS },
  cur: {
    headerName: 'Deviza',
    width: 140,
    type: 'number',
    cellClassName: 'cl_accent2 op6 gridCellVal',
  },
  //status: { headerName: "Státusz", width: 140 },
  partner: { headerName: 'Partner', width: 160 },
  bsitem: { headerName: 'Mérlegelem', width: 160 },
  partner_bsitem: { headerName: 'Partner / Eszköz', width: 200 },

  text: { headerName: 'Szöveg', ...textCol },
  text2: { headerName: 'Szöveg', ...textCol },
  texts: { headerName: 'Szöveg', ...textCol }, // extra

  comments_texts: { headerName: 'Kommentek', ...textCol },

  created: { headerName: 'Rögzítés', ...textCol },

  value: { headerName: 'Value', width: 100 },
  value_orig: {
    headerName: 'Érték',
    width: 100,
    type: 'number',
    cellClassName: 'cl_accent2 op6 gridCellVal',
  },
  currency: {
    headerName: 'Deviza',
    width: 60,
    cellClassName: 'cl_accent2 op6 gridCellVal',
  },
  currency_orig: {
    headerName: 'Deviza',
    width: 60,
    cellClassName: 'cl_accent2 op6 gridCellVal',
  },
  booking_year: { headerName: 'Év', width: 100 },
};
