import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { ButtonBar } from '../../util-s/buttonbar';
import { Item } from '../content.types';

interface CompProps {
  item: Item;
  edit: boolean;
  doSave: (input: any) => void;
  openModal: (input: any) => void;
  doEdit: (input: any) => void;
  doCancel: (input: any) => void;
}

export function ItemEditButtons({
  edit,
  item,
  doSave,
  openModal,
  doEdit,
  doCancel,
}: CompProps) {
  return (
    <ButtonBar>
      {edit ? (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={doSave}
            size="small"
            startIcon={<SaveIcon />}
          >
            Mentés
          </Button>
          {!item.id ? null : (
            <Button
              color="primary"
              variant="outlined"
              onClick={openModal}
              size="small"
              startIcon={<DeleteIcon />}
            >
              Törlés
            </Button>
          )}
        </>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={doEdit}
          size="small"
          startIcon={<EditIcon />}
        >
          Szerkesztés
        </Button>
      )}
      <Button
        variant="outlined"
        onClick={doCancel}
        size="small"
        startIcon={<ChevronLeft />}
      >
        Vissza
      </Button>
    </ButtonBar>
  );
}
