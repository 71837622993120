import { Dispatch } from 'redux';

import { booking_eq__get } from '../redux/booking.actions';

type RFX = {
  filter: any;
  page: any;
  setFX: (inp: any) => void;
  dispatch: Dispatch;
  ids: string[];
};

export const requestFX = ({ filter, page, setFX, dispatch, ids }: RFX) => {
  const input = requestInput(filter, page);
  requestAction(input, dispatch)
    .then((res) => setFX(requestHandle(res, ids)))
    .catch(() => {
      null;
    });
};

const requestAction = booking_eq__get;

const requestInput = (filters: any, page: any) => {
  return { filters, page };
};

const requestHandle = (res: any, ids: any) => {
  const new_ids = res.booking_eq.map((row: any) => row.id);
  const all_ids = [...ids, ...new_ids];
  const set_to = {
    finished: res.finished,
    page: res.page,
    ids: all_ids,
  };
  return set_to;
};
