/* eslint-disable unicorn/no-document-cookie */
/* eslint-disable unicorn/prefer-string-slice */
import { cookies_must_allow } from '../../../config/client.config';

const Cookie = {
  set: (item: string, val: string | number, days = 1) => {
    try {
      if (_allowed() || !cookies_must_allow) setCookie(item, val, days);
      else return false;
      return true;
    } catch {
      return false;
    }
  },
  get: (item: string) => {
    try {
      return getCookie(item);
    } catch {
      return undefined;
    }
  },
  del: (item: string) => {
    try {
      setCookie(item, '-', -1);
    } catch {
      return false;
    }
  },
  allow: () => {
    setCookie('_co', '1', 7200);
  },
  allowed: _allowed,
};

function _allowed() {
  return !!getCookie('_co');
}

function getCookie(cname: string) {
  const name = cname + '=';
  const ca: string[] = document.cookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.slice(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname: string, cvalue: string | number, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export default Cookie;
