/* eslint-disable unicorn/consistent-function-scoping */
import './grid2.scss';

import React from 'react';

import { GridRow } from './p-row';
import { RowProps } from './types';

export const GridRows = ({
  columns,
  rows,
  canSelect,
  onCellRightClick,
  selected,
  toggle,
  htoggle,
}: RowProps) => {
  return (
    <>
      {rows?.map((row) => (
        <GridRow
          key={row.id}
          {...{
            columns,
            row,
            canSelect,
            onCellRightClick,
            selected,
            toggle,
            htoggle,
          }}
        />
      ))}
    </>
  );
};
