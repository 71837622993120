import { Dispatch } from 'redux';

import { REQ } from '../../util';
import {
  Id,
  IGroupAdd,
  IGroupChange,
  IGroupDel,
  IGroupMove,
  ItemRole,
} from '../content.types';

export const item_get = (id: Id, dispatch: Dispatch) => {
  return commonFx2('GET', `items/${id}`)(dispatch);
};
export const item_save = async (item: any, dispatch: Dispatch) => {
  return item.id
    ? commonFx2(
        'PATCH',
        `items/${item.id}`,
        {},
        item,
        'items',
        'Saved!'
      )(dispatch)
    : commonFx2('POST', 'items', {}, item, 'items', 'Saved!')(dispatch);
};
export const item_back = (payload: any) => ({ type: 'CONTENT_RES', payload });

export const item_get_all = () => {
  return commonFx2('GET', 'items');
};
export const item_get_more = (item_ids: Id[]) => {
  return commonFx2('GET', 'items', { ids: item_ids });
};
export const item_get_role = (role: ItemRole) => {
  return commonFx2('GET', 'items', { role });
};
export const item_del = (id: Id) => {
  return commonFx2('DELETE', `items/${id}`, undefined, undefined, '$del_item');
};
export const item_move = ({
  item_id,
  group_id,
}: {
  item_id: Id;
  group_id: Id;
}) => {
  return commonFx2('POST', `items/${item_id}/move/${group_id}`);
};
export const item_copy = (id: Id) => {
  return commonFx2('POST', `items/${id}/copy`);
};
export const group_add = (group: IGroupAdd) => {
  return commonFx2('POST', `groups`, group);
};
export const group_del = ({ id }: IGroupDel) => {
  return commonFx2('DELETE', `groups/${id}`);
};
export const group_move = ({ id, parent_id }: IGroupMove) => {
  return commonFx2('PATCH', 'groups', { id, parent_id });
};
export const group_change = (group: IGroupChange) => {
  return commonFx2('PATCH', 'groups', group);
};

export const item_exrates = () => {
  return commonFx2('GET', 'item-exrate', undefined, undefined, 'item_exrate');
};

export const item_ledgers = () => {
  return commonFx2('GET', 'item-ledger', undefined, undefined, 'item_ledger');
};

export const service_mnb = async (dispatch: Dispatch) => {
  try {
    const res = await commonFx2('GET', 'service-mnb')(dispatch);
    try {
      if (res.vals?.updated) item_exrates()(dispatch);
    } catch {
      null;
    }
    return res;
  } catch {
    return {};
  }
};

const commonFx2 = (
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  url: string,
  params: any = {},
  data: any = undefined,
  payloadName = '',
  successMessage?: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: 'CONTENT_PENDING' });
    const nurl = '/content/' + url;

    const res = await REQ(
      { method, url: nurl, data, params },
      dispatch,
      successMessage
    );
    if (res) {
      const payload = payloadName ? { [payloadName]: res } : res;
      dispatch({ type: 'CONTENT_RES', payload });
    } else {
      console.log('no res');
      dispatch({ type: 'CONTENT_ERR', e: 'error' });
    }
    return res;
  };
};

export const load_items_for_booking = () => {
  return (dispatch: any) => {
    dispatch(item_get_role('partner'));
    dispatch(item_get_role('exrate'));
    dispatch(item_get_role('ledger'));
    dispatch(item_get_role('bsasset'));
    dispatch(item_get_role('bsaccount'));
    dispatch(item_get_role('bktemplate'));
    dispatch(item_exrates());
    dispatch(item_ledgers());
  };
};
