import React from 'react';

import { ContextMenuListCreator } from '../../../util-s';
import { Grid2 } from '../grid2';
import { PageProvider } from './u-context';
import { CustomPagination } from './u-pagination';
import { Column, Row } from './xtypes';

type GridProps = {
  page: number;
  rowCount: number;
  rows?: Row[];
  columns: Column[];
  canSelect?: boolean;
  onSelect?: (ids: Array<string | number>) => void;
  onCellRightClick?: ContextMenuListCreator;
  onPageChange: (pg: number) => void;
};

export function Grid({
  page,
  rowCount,
  rows,
  columns,
  canSelect,
  onSelect,
  onCellRightClick,
  onPageChange,
}: GridProps) {
  const count = React.useMemo(() => {
    return Math.ceil(rowCount / 100);
  }, [rowCount]);

  return !rows ? null : (
    <>
      <PageProvider {...{ page, count, onPageChange }}>
        <Grid2
          rows={rows}
          columns={columns}
          canSelect={canSelect}
          onSelect={onSelect}
          onCellRightClick={onCellRightClick}
          pagination={
            <CustomPagination
              style={{
                margin: '0px auto',
                float: 'none',
                display: 'inline-block',
              }}
            />
          }
        />
      </PageProvider>
    </>
  );
}
