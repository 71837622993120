import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../util/client_jsx/loading-fa';
import { BookingContextProps } from '../content.types';
import { load_items_for_booking } from '../redux/content.actions';
import {
  selector_data,
  selector_items_for_booking_loaded,
} from '../redux/content.selectors';
import { BookingContextProvider } from './BookingContext';

type CompProps = {
  children: React.ReactNode;
};

export default function BookinEnv({ children }: CompProps) {
  const dispatch = useDispatch();
  const all_loaded = useSelector(selector_items_for_booking_loaded);
  if (!all_loaded) dispatch(load_items_for_booking());

  return all_loaded ? (
    <BookingEnvContext>{children}</BookingEnvContext>
  ) : (
    <div className="p50">
      <Loading />
    </div>
  );
}

function BookingEnvContext({ children }: CompProps) {
  const data = useSelector(selector_data);
  const { item, item_exrate, item_ledger, role_list, templates } = data;
  const data2: BookingContextProps = {
    item,
    item_exrate,
    item_ledger,
    role_list,
    templates,
  };

  return (
    <>
      <BookingContextProvider value={data2}>{children}</BookingContextProvider>
    </>
  );
}
