/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Item } from '../content.types';
import { ActionButtons } from './list_9_buttons';

export function HeaderBsAccount() {
  return (
    <>
      <tr>
        <th>#</th>
        <th>Kód</th>
        <th>Cím</th>
        <th>Bank</th>
        <th>Deviza</th>
        <th>Számlaszám</th>

        <th>Actions</th>
      </tr>
    </>
  );
}

interface BodyBsAccountProps {
  item: Item;
  onOpen: () => void;
  onEdit: () => void;
  onDel: () => void;
}

export function BodyBsAccount({
  item,
  onOpen,
  onEdit,
  onDel,
}: BodyBsAccountProps) {
  return (
    <>
      <tr className="pointer">
        <td onClick={onOpen}>{item.id}</td>
        <td onClick={onOpen}>{item.code}</td>
        <td onClick={onOpen}>{item.title}</td>
        <td onClick={onOpen}>{item.bsaccount!.bank}</td>
        <td onClick={onOpen}>{item.bsaccount!.currency}</td>
        <td onClick={onOpen}>{item.bsaccount!.accountno}</td>

        <td className="text-center">
          <ActionButtons {...{ onEdit, onDel }} />
        </td>
      </tr>
    </>
  );
}
