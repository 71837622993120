import { Color } from '@material-ui/lab/Alert';
import { Dispatch } from 'redux';

// import MESSAGES from '../../../config/messages';
import { message_add_more_action } from './store.messages.actions';
import { MessageProps } from './store.messages.reducer';

export function showMessages(type: Color, text: string, dispatch: Dispatch) {
  const messages: MessageProps[] = [];
  messages.push({ type, text });
  dispatch<any>(message_add_more_action(messages));
}
