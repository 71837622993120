import { CreateForm, metaToVals } from '../../util/io_form-mat';
import { Item } from '../content.types';
import { base_meta } from '../meta_item/template.meta';

interface CompProps {
  item: Item;
  disabled_all: boolean;
  onChange: (changeInput: { bktemplate: any }) => void;
}

const onCancel = () => null;

export function RowBasics({ item, disabled_all, onChange }: CompProps) {
  const onSubmit = (x: any) => {
    onChange(metaToVals(x));
  };

  return (
    <>
      <div className="row m20_0">
        <div className="mw700 ">
          <div className="row text-center m10_0">
            <h2>Item adatok</h2>
          </div>
          <div className="row">
            <CreateForm
              data={item}
              meta={base_meta}
              exclude={[
                'item_oid',
                'group_id',
                'group_oid',
                'role',
                'prefix',
                'tags',
              ]}
              exclude_disabled={true}
              disabled={['id', 'group_id']}
              disabled_all={disabled_all || false}
              def={{}}
              autofocus={true}
              button={false}
              button_text={'Mentés'}
              cancel_button={false}
              cancel_button_text={'Mégse'}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
}
