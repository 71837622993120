import { CreateForm, metaToVals } from '../../util/io_form-mat';
import { Item } from '../content.types';
import { partner_meta } from '../meta_item/template.meta';

interface PartnerRowsProps {
  item: Item;
  disabled_all: boolean;
  onChange: (input: any) => void;
}

export function PartnerRows({
  item,
  disabled_all,
  onChange,
}: PartnerRowsProps) {
  return (
    <>
      <RowPartnerBase
        item={item}
        disabled_all={disabled_all}
        onChange={onChange}
      />
      <RowPartnerBilling
        item={item}
        disabled_all={disabled_all}
        onChange={onChange}
      />
    </>
  );
}

interface RowPartnerBaseProps {
  item: Item;
  disabled_all: boolean;
  onChange: (changeInput: { partner: { base: any } }) => void;
}

function RowPartnerBase({ item, disabled_all, onChange }: RowPartnerBaseProps) {
  const onSubmit = (event: any) => {
    onChange({ partner: { base: metaToVals(event) } });
  };

  return (
    <>
      <div className="row m20_0">
        <div className="mw700 ">
          <div className="row text-center m10_0">
            <h2>Partneri Alapadatok</h2>
          </div>
          <div className="row">
            <CreateForm
              data={item.partner!.base}
              meta={partner_meta.base}
              exclude={[]}
              disabled_all={disabled_all || false}
              button={false}
              cancel_button={false}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

interface RowPartnerBillingProps {
  item: Item;
  disabled_all: boolean;
  onChange: (changeInput: { partner: { billing_address: any } }) => void;
}

function RowPartnerBilling({
  item,
  disabled_all,
  onChange,
}: RowPartnerBillingProps) {
  const onSubmit = (event: any) => {
    onChange({ partner: { billing_address: metaToVals(event) } });
  };

  return (
    <>
      <div className="row m20_0">
        <div className="mw700 ">
          <div className="row text-center m10_0">
            <h2>Számlázási adatok</h2>
          </div>
          <div className="row">
            <CreateForm
              data={item.partner!.billing_address}
              meta={partner_meta.billing_address}
              exclude={[]}
              disabled_all={disabled_all || false}
              button={false}
              cancel_button={false}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}
