import { book_new } from '../redux/booking.actions';

type CompProps = {
  act: any;
  dispatch: any;
  onAction: any;
};

export const saveBooking = ({ act, dispatch, onAction }: CompProps) => {
  const head: any = {};

  for (const h in act.item.data.head) head[h] = act.item.data.head[h].data;
  const items = act.item.data.items.map((ii: any) => ii.data);
  const D = items
    .filter((it: any) => it.side === 'D')
    .map((it2: any) => it2.value)
    .reduce((a: any, b: any) => a + b, 0);
  const C = items
    .filter((it: any) => it.side === 'C')
    .map((it2: any) => it2.value)
    .reduce((a: any, b: any) => a + b, 0);
  head.sum = D;
  const tosave = { head, items };
  if (D === C)
    book_new(tosave, dispatch)
      .then((x: any) => {
        console.log('book new x', x);
        onAction({
          type: 'justbooked',
          data: { head_id: x?.new_head_id },
        });
      })
      .catch(() => {
        null;
      });
};
