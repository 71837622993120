import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import { service_mnb } from './redux/content.actions';

const iframeStyle: React.CSSProperties = {
  border: 'none',
  backgroundColor: 'rgba(0,0,0,0.03)',
  minWidth: '100%',
  minHeight: '200px',
};

type stateP = {
  disabled: boolean;
  ok: boolean;
  updated: number;
  message: string;
  html: string;
};

const initState: stateP = {
  disabled: false,
  ok: true,
  updated: 0,
  message: '',
  html: '<style>body {font-family: monospace;}</style><p>...</p>',
};

export const ServiceMnb = () => {
  const [state, setState] = React.useState<stateP>(initState);
  const dispatch = useDispatch();

  const onClick = () => {
    service_mnb(dispatch)
      .then((res) => {
        console.log(res);
        const { vals, ...main } = res;
        // const { dateFrom, dateTo, updated, total, currs } = vals || {};
        setState({ ...state, disabled: false, ...main });
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...initState,
          ok: false,
          message: 'error',
          html: '<p>...valami hiba történt</p>',
        });
      });
    setState({ ...state, disabled: true });
  };

  return (
    <>
      <div className="row text-center mtop50">
        <h1>Feltöltés</h1>
      </div>
      <div className="row text-center mtop50">
        <Button
          color="primary"
          variant="contained"
          onClick={onClick}
          disabled={state.disabled}
        >
          Árfolyamok frissítése
        </Button>
      </div>
      <div className="row text-center mtop50">
        <iframe
          title="Upload result"
          srcDoc={state.html}
          style={iframeStyle}
        ></iframe>
      </div>
    </>
  );
};
