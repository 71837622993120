import { datefx } from '../../content/meta_item/item.fx';

//----------- client ----------
export const form_data_storno = {
  booking_date: datefx.today(),
};
export const form_meta_storno = {
  booking_date: {
    text: 'Könyvelési dátum',
    type: 'date',
    value: datefx.today(),
    validations: ['date1'],
  },
};

export const form_data_release = {
  booking_date: datefx.today(),
  receipt_date: datefx.today(),
  completition_date: datefx.today(),
};
export const form_meta_release = {
  booking_date: {
    text: 'Könyvelési dátum',
    type: 'date',
    value: datefx.today(),
    validations: ['date1'],
  },
  receipt_date: {
    text: 'Bizonylat dátum',
    type: 'date',
    value: datefx.today(),
    validations: ['date1'],
  },
  completition_date: {
    text: 'Teljesítés dátum',
    type: 'date',
    value: datefx.today(),
    validations: ['date1'],
  },
};
