export default function LoadingPage() {
  return (
    <>
      <div
        className="loading"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 50px)',
          opacity: 0.5,
        }}
      >
        <i className="fas fa-spinner fa-spin fa-3x"></i>
      </div>
    </>
  );
}
