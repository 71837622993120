import { template_new } from '../redux/booking.actions';

type CompProps = {
  act: any;
  dispatch: any;
  template_id: any;
  setNewTId: any;
  bookinData: any;
};

export const saveTemplate = ({
  act,
  dispatch,
  template_id,
  setNewTId,
  bookinData,
}: CompProps) => {
  let item = null;
  try {
    item = {
      ...bookinData.templates.find((t: any) => t.role === 'bktemplate'),
    };
  } catch {
    null;
  }
  if (item) {
    item.bktemplate = { ...act.bktemplate };
    item.title = act.bktemplate?.data?.head_data?.name || '';
    item.id = template_id || item.id;
  }
  template_new(item, dispatch)
    .then((x: any) => {
      if (x.data?.$new_item) setNewTId(x.data?.$new_item);
    })
    .catch(() => {
      null;
    });
};
