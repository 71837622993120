/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Item } from '../content.types';
import { ActionButtons } from './list_9_buttons';

export function HeaderExrate() {
  return (
    <>
      <tr>
        <th>#</th>
        <th>Kód</th>
        <th>Cím</th>
        <th>Pár kód</th>
        <th>Deviza 1</th>
        <th>Deviza 2</th>
        <th>Forrás</th>

        <th>Actions</th>
      </tr>
    </>
  );
}

interface BodyExrateProps {
  item: Item;
  onOpen: () => void;
  onEdit: () => void;
  onDel: () => void;
}

export function BodyExrate({ item, onOpen, onEdit, onDel }: BodyExrateProps) {
  return (
    <>
      <tr className="pointer">
        <td onClick={onOpen}>{item.id}</td>
        <td onClick={onOpen}>{item.code}</td>
        <td onClick={onOpen}>{item.title}</td>
        <td onClick={onOpen}>{item.exrate!.pair_code}</td>
        <td onClick={onOpen}>{item.exrate!.cur1}</td>
        <td onClick={onOpen}>{item.exrate!.cur2}</td>
        <td onClick={onOpen}>{item.exrate!.source}</td>

        <td className="text-center">
          <ActionButtons {...{ onEdit, onDel }} />
        </td>
      </tr>
    </>
  );
}
