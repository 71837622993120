import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

interface CompProps {
  onEdit: () => void;
  onDel: () => void;
}

export function ActionButtons({ onEdit, onDel }: CompProps) {
  return (
    <>
      <IconButton size="small" onClick={onEdit} color="primary">
        <CreateIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={onDel} color="primary">
        <DeleteIcon fontSize="small" />
      </IconButton>
    </>
  );
}
