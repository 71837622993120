import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

type TLprops = {
  selectPage: (act: any) => void;
  closePage: (code: string) => void;
  act: any;
  pages: any;
};

export function TabsList({ selectPage, closePage, act, pages }: TLprops) {
  const value = pages.findIndex((p: any) => p.code === act);
  return (
    <Tabs
      value={value}
      onChange={_handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs"
    >
      {pages.map((page: any, id: any) => (
        <OneTab key={page.code} {...{ page, act, id, selectPage, closePage }} />
      ))}
    </Tabs>
  );
}

const _handleChange = (x: any) => {
  console.log(x);
};

function OneTab({ selectPage, closePage, act, id, page }: any) {
  const title = (p: any) => {
    return p.title || page.code + ' - ' + page.content.text;
  };

  return (
    <Tab
      id={id}
      value={act}
      label={title(page)}
      wrapped
      onClick={() => {
        selectPage(page.code);
      }}
      onContextMenu={(event) => {
        event.preventDefault();
        closePage(page.code);
      }}
    />
  );
}
