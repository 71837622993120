import './ifrErrors.scss';

import React from 'react';

export type ErrPlace = 'file' | 'group' | 'field' | 'tech';
export type Severity = 'error' | 'warning' | 'info';
export type ERR = {
  place: ErrPlace;
  text: string;
  severity: Severity;
  row?: number;
  field?: string;
  group?: string;
  value?: string | number;
};

type IFP = {
  ready: boolean;
  errors: ERR[];
};

export const IframeErrors = ({ ready, errors }: IFP) => {
  return !ready ? null : (
    <div className="ifrtableFrame">
      <ShowErrors {...{ errors }} />
    </div>
  );
};

const ShowErrors = ({ errors }: { errors: ERR[] }) => {
  const serrors = React.useMemo(() => {
    const err = [...errors];
    err.sort((a: ERR, b: ERR) => (+a.row! < +b.row! ? -1 : 1));
    return err;
  }, [errors]);

  return serrors.length === 0 ? (
    <h3>A művelet sikeres!</h3>
  ) : (
    <table className="ifrtable">
      <thead>
        <tr>
          <td>Severity</td>
          <td>Row</td>
          <td>Type</td>
          <td>Group</td>
          <td>Field</td>
          <td>Value</td>
          <td>Text</td>
        </tr>
      </thead>
      <tbody>
        {serrors.map((error: ERR, i: number) => (
          <tr key={i} className={'tablerow ' + error.severity}>
            <td className={'tablecell ' + error.severity}>{error.severity}</td>
            <td>{error.row}</td>
            <td>{error.place}</td>
            <td>{error.group}</td>
            <td>{error.field}</td>
            <td>{error.value}</td>
            <td>{error.text}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
