import { bookconfig as CNF } from '../../../config/book.config';
import { repNumber } from '../../util/common/number';
import { Balance } from '../booking.types';
import { ActionButtonPairing } from '../u_action/h_eq_pairing';

const { LOCAL_CURR } = CNF;

type ExtraInfo = { state: any; setFX: any };

export function ExtraInfo({ state, setFX }: ExtraInfo) {
  const { selected } = state;
  const { ids, balance } = selected;
  const { T, otherCurr, ok } = texts(balance);

  const fontSize = '22px';
  const lineHeight = '32px';

  return ids.length === 0 ? null : (
    <>
      <div style={{ float: 'left' }}>
        <div className="left" style={{ margin: '0px 20px', lineHeight }}>
          <span
            style={{ fontSize, color: !ok ? 'red' : '#3f51b5', float: 'left' }}
          >
            {T[0]}{' '}
          </span>
          <span
            style={{
              fontSize,
              opacity: '0.3',
              float: 'left',
              marginLeft: '5px',
            }}
          >
            {T[1]}
          </span>
        </div>

        {!otherCurr ? null : (
          <div className="left" style={{ margin: '0px 20px', lineHeight }}>
            <span
              style={{
                fontSize,
                color: !ok ? 'red' : '#3f51b5',
                float: 'left',
              }}
            >
              {T[2]}{' '}
            </span>
            <span
              style={{
                fontSize,
                opacity: '0.3',
                float: 'left',
                marginLeft: '5px',
              }}
            >
              {T[3]}
            </span>
          </div>
        )}
      </div>
      <div style={{ float: 'left' }}>
        <EqButton {...{ state, setFX }} />
      </div>
    </>
  );
}

export function EqButtonText({ state, setFX }: any) {
  return (
    <div style={{ float: 'left', padding: '1px 0px' }}>
      <EqButton {...{ state, setFX, withText: true }} />
    </div>
  );
}

export function EqButton({ state, setFX, withText }: any) {
  const { ids, balance } = state.selected;
  let text = '';

  if (withText) {
    const { T, otherCurr } = texts(balance);
    text = `${T[0]} ${T[1]}`;
    if (otherCurr) text += ` / ${T[2]} ${T[3]}`;
  }

  const onAction = () => {
    setFX({
      ...state,
      selected: BASE_SELECTED,
      refresh: state.refresh + 1,
    });
  };

  return ids.length === 0 ? null : (
    <ActionButtonPairing {...{ ids, balance, onAction, text }} />
  );
}

const BASE_SELECTED = {
  ids: [],
  balance: { total: { local: 0 }, ok: false },
};

const texts = (balance: Balance) => {
  const {
    total: { local, ...restCurrJson },
    ok,
  } = balance;
  const restCurr = Object.keys(restCurrJson).filter(
    (cur) => cur !== LOCAL_CURR
  );
  const otherCurr = restCurr[0] || null;

  return {
    restCurr,
    otherCurr,
    ok,
    T: [
      repNumber(local),
      LOCAL_CURR,
      repNumber(restCurrJson?.[otherCurr as string] || ''),
      otherCurr,
    ],
  };
};
