import { bookconfig as CNF } from '../../../config/book.config';

const { BOOKING_YEAR_DEFAULT } = CNF;

const _f_vb_all_head2 =
  'id,head_id,name,booking_date,D,C,ledgeritem2,status,partner,bsitem,receipt_date,receipt_ref,text,text2,comment,comment2,value_orig,currency_orig,exchange_rate,completition_date,receipt_date,recording_date';
const _q_vb_all_head2 = {
  dataobject: 'li_vb_all_head2',
  fieldlist: _f_vb_all_head2,
  filters: '',
  orders: 'id desc',
};

const fkszreport = {
  ..._q_vb_all_head2,
  title: 'TÉTELEK - fksz / év',
  filters: { ledgeritem: 9_110_000, booking_year: BOOKING_YEAR_DEFAULT },
};

export const reports_meta = [
  {
    title: 'Mérleg (h0, h1) = BESZÁMOLÓ',
    dataobject: 'ag_vb_period_cur',
    dims: ['bside', 'h0', 'h1'],
    filters: { booking_year: BOOKING_YEAR_DEFAULT },
    updatefx: (meta: any) => {
      const { booking_year, ...filters } = meta.filters;
      const measures = [];
      measures.push(
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year: booking_year - 1 },
        },
        {
          value: 'S',
          measure: 'change',
          parameter: { booking_year },
        },
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year },
        }
      );
      return { ...meta, measures, filters };
    },
  },
  {
    title: 'Mérleg (h0, h1, h2)',
    dataobject: 'ag_vb_period_cur',
    dims: ['bside', 'h0', 'h1', 'h2'],
    filters: { booking_year: BOOKING_YEAR_DEFAULT },
    updatefx: (meta: any) => {
      const { booking_year, ...filters } = meta.filters;
      const measures = [];
      measures.push(
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year: booking_year - 1 },
        },
        {
          value: 'S',
          measure: 'change',
          parameter: { booking_year },
        },
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year },
        }
      );
      return { ...meta, measures, filters };
    },
  },

  {
    title: 'Mérleg (h1, h2)',
    dataobject: 'ag_vb_period_cur',
    dims: ['bside', 'h1', 'h2'],
    filters: { booking_year: BOOKING_YEAR_DEFAULT },
    updatefx: (meta: any) => {
      const { booking_year, ...filters } = meta.filters;
      const measures = [];
      measures.push(
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year: booking_year - 1 },
        },
        {
          value: 'S',
          measure: 'change',
          parameter: { booking_year },
        },
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year },
        }
      );
      return { ...meta, measures, filters };
    },
  },

  {
    title: 'Mérleg (h1, h2, h3, fksz)',
    dataobject: 'ag_vb_period_cur',
    dims: ['bside', 'h1', 'h2', 'h3', 'ledgeritem2'],
    filters: { booking_year: BOOKING_YEAR_DEFAULT },
    updatefx: (meta: any) => {
      const { booking_year, ...filters } = meta.filters;
      const measures = [];
      measures.push(
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year: booking_year - 1 },
        },
        {
          value: 'S',
          measure: 'change',
          parameter: { booking_year },
        },
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year },
        }
      );
      return { ...meta, measures, filters };
    },
  },
  {
    title: 'Mérleg (h2, fksz)',
    dataobject: 'ag_vb_period_cur',
    dims: ['bside', 'h2', 'ledgeritem2'],
    filters: { booking_year: BOOKING_YEAR_DEFAULT },
    updatefx: (meta: any) => {
      const { booking_year, ...filters } = meta.filters;
      const measures = [];
      measures.push(
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year: booking_year - 1 },
        },
        {
          value: 'S',
          measure: 'change',
          parameter: { booking_year },
        },
        {
          value: 'S',
          measure: 'end',
          parameter: { booking_year },
        }
      );
      return { ...meta, measures, filters };
    },
  },
  fkszreport,
];
