import { CreateForm } from '../../util/io_form-mat';

type CompProps = {
  head: any;
  meta: any;
  onAction: (inp: { type: string; head: any }) => void;
};

export function HeadSection({ head, meta, onAction }: CompProps) {
  const onChange = (head: any) => {
    onAction({ type: 'headchange', head });
  };

  return (
    <>
      <div className="row mtop10">
        <div className="row text-center m10_0">
          <h2>Fejadatok</h2>
        </div>
        <div className="row">
          <div className="mw1000">
            <CreateForm
              data={head}
              meta={meta}
              exclude={[]}
              disabled_all={false}
              button={false}
              cancel_button={false}
              onSubmit={onChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
