import Data1 from './p_data_1';

interface CompProps {
  meta: any;
  onAction: (input: { type: string; data?: any }) => void;
}

export default function QueryResult({ meta, onAction }: CompProps) {
  return (
    <>
      <div className="row m50_0">
        <div className="row text-center">
          <h1>{meta.title}</h1>
        </div>
        <div className="row mtop20">
          <Data1 query={meta} onAction={onAction} />
        </div>
      </div>
    </>
  );
}
