import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useState } from 'react';

import { generate_code } from '../../util/common/gen';

export function SetDate({ value, error, name, onChange }: any) {
  const [selectedDate, setSelectedDate] = useState(new Date(value));
  const [id] = useState('c' + generate_code(6));
  const handleDateChange = (dt: any) => {
    try {
      const [y, m, d] = (dt.toLocaleDateString('hu-HU') + ' ').split('. ');
      const dt1 = `${y}-${m}-${d}`.slice(0, 10);
      onChange(dt1);
    } catch {
      onChange('-');
    }
    setSelectedDate(dt);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        margin="normal"
        id={id}
        label={name}
        value={selectedDate}
        onChange={handleDateChange}
        error={!!error}
        helperText={error}
        size="small"
      />
    </MuiPickersUtilsProvider>
  );
}
