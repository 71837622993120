import { ViewAgenda } from '@material-ui/icons';

export const nav_list1a = [{ type: 'bi_reports', text: 'Kimutatások' }];
export const nav_list1b = [
  { type: 'booking_new', text: 'Új bizonylat' },
  { type: 'booking_upload', text: 'Új feltöltés' },
  { type: 'booking_heads', text: 'Bizonylatok', startIcon: <ViewAgenda /> },
  { type: 'booking_items', text: 'Tételek' },
  //{ type: 'booking_eqs', text: 'Párok' },
];
export const nav_list2 = [
  { type: 'partner', text: 'Partnerek' },
  { type: 'bsasset', text: 'Eszközök' },
  { type: 'service-mnb', text: 'MNB frissítés' },
  /*
  {type: 'bsaccount', text: 'számlák' },
  {type: 'exrate', text: 'árfolyamok' },
  {type: 'ledger', text: 'főkönyvek' },
  {type: 'bktemplate', text: 'sablonok' }
  */
];
