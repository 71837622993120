import { Dispatch } from 'redux';

import { item_back, item_save } from '../../content/redux/content.actions';
import { REQ } from '../../util';

interface FP {
  filters?: any;
  page: any;
}
interface FL {
  filters?: any;
  limit?: number;
}

export const book_new = (pack: any, dispatch: Dispatch) => {
  return commonFx2({ url: 'new', method: 'POST', data: pack })(dispatch);
};
export const book_head__get = ({ filters, page }: FP, dispatch: Dispatch) => {
  return commonFx2({ url: 'head', method: 'POST', data: { filters, page } })(
    dispatch
  );
};
export const book_head__xls = ({ filters, limit }: FL, dispatch: Dispatch) => {
  return commonFx2({
    url: 'head-xls',
    method: 'POST',
    data: { filters, limit },
  })(dispatch);
};
export const booking_eq__get = ({ filters, page }: FP, dispatch: Dispatch) => {
  return commonFx2({ url: 'eq', method: 'POST', data: { filters, page } })(
    dispatch
  );
};
export const book_item__get = ({ filters, page }: FP, dispatch: Dispatch) => {
  return commonFx2({ url: 'item', method: 'POST', data: { filters, page } })(
    dispatch
  );
};
export const book_itemh__get = ({ filters, page }: FP, dispatch: Dispatch) => {
  return commonFx2({
    url: 'item-head',
    method: 'POST',
    data: { filters, page },
  })(dispatch);
};
export const book_itemh__xls = ({ filters, limit }: FL, dispatch: Dispatch) => {
  return commonFx2({
    url: 'item-head-xls',
    method: 'POST',
    data: { filters, limit },
  })(dispatch);
};

export const book_eq_release = (input: any, dispatch: Dispatch) => {
  return commonFx2({
    url: 'release',
    method: 'POST',
    data: input,
  })(dispatch);
};
export const book_eq_storno = (
  input: { head_id: number; fields: { booking_date: Date; status: string } },
  dispatch: Dispatch
) => {
  return commonFx2({
    url: 'storno',
    method: 'POST',
    data: input,
  })(dispatch);
};
export const book_eq_pairing = (
  { items, booking_date }: any,
  dispatch: Dispatch
) => {
  return commonFx2({
    url: 'pairing',
    method: 'POST',
    data: { items, booking_date },
  })(dispatch);
};

export const book_meta_active__xls = (dispatch: Dispatch) => {
  return commonFx2({
    url: 'meta-active-xls',
    method: 'GET',
  })(dispatch);
};

export const template_new = (item: any, dispatch: Dispatch) => {
  return new Promise((resolve, reject) => {
    item_save(item, dispatch)
      .then((res: any) => {
        dispatch(item_back(res.data));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

type reqProps = {
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH';
  url: string;
  params?: any;
  data?: any;
  stateProp?: string;
  successMessage?: string;
};

const commonFx2 = ({
  method = 'GET',
  url,
  params = {},
  data = undefined,
  stateProp = '',
  successMessage,
}: reqProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: 'BOOKING_PENDING' });
    const nurl = '/booking/' + url;

    const res = await REQ(
      { method, url: nurl, data, params },
      dispatch,
      successMessage
    );
    if (res) {
      const payload = stateProp ? { [stateProp]: res } : res;
      dispatch({ type: 'BOOKING_RES', payload });
    } else {
      dispatch({ type: 'BOOKING_ERR', e: 'error' });
    }
    return res;
  };
};
