import { Color } from '@material-ui/lab/Alert';
import { Dispatch } from 'redux';

import messages from '../../../config/messages';
import { message_add_more_action } from './store.messages.actions';
import { MessageProps } from './store.messages.reducer';

export function show_message(text: string, dispatch: any) {
  resToMessage({ ok: 0, data: {}, msg: [text] }, dispatch);
}

export function resToMessage(res: any, dispatch: Dispatch) {
  const { ok } = res;
  let { msg } = res;

  const t0 = ok ? 'info' : 'error';
  const arr = [];

  if (typeof msg === 'string') msg = [msg];
  if (!Array.isArray(msg)) return;

  for (const _m of msg) {
    let message: MessageProps = { type: 'info', text: '' };
    if (typeof _m === 'string') {
      let t1: string | undefined = _m.split('_')[0];
      if (!['success', 'error', 'info', 'warning'].includes(t1)) t1 = undefined;
      message = messages[_m]
        ? {
            lan: _m,
            type: (messages[_m].type || t1 || t0) as Color,
            text: messages[_m]?.hu,
          }
        : {
            lan: '',
            type: 'error',
            text: _m,
          };
      arr.push(message);
    }
  }

  dispatch<any>(message_add_more_action(arr));
}
