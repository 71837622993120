import { Button, FormControlLabel, Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ItemRow } from './booking_4_itemsrow';

type ISprops = {
  items: any;
  meta: any;
  onAction: any;
  bookingData: any;
  currency_date: any;
  auto_exrate: any;
};

export function ItemsSection({
  items,
  meta,
  onAction,
  bookingData,
  currency_date,
  auto_exrate,
}: ISprops) {
  const items_meta = { ...meta };
  items_meta.ledgeritem.options = Object.keys(bookingData.item_ledger).map(
    (li) => {
      return { v: li, t: li + ' - ' + bookingData.item_ledger[li].name };
    }
  );
  items_meta.partner_id.options = [
    { v: null, t: '-' },
    ...bookingData.role_list.partner.map((pi: any) => {
      return { v: pi, t: bookingData.item['' + pi].title + ' (' + pi + ')' };
    }),
  ];
  items_meta.bsitem_id.options = [
    { v: null, t: '-' },
    ...bookingData.role_list.bsasset.map((pi: any) => {
      return { v: pi, t: bookingData.item['' + pi].title + ' (' + pi + ')' };
    }),
  ];

  const itemadd = () => {
    onAction({ type: 'itemadd' });
  };
  const setAutoExrate2 = (event: any) => {
    onAction({ type: 'set_auto_exrate', setto: event.target.checked * 1 });
  };

  return (
    <>
      <div className="row mtop20">
        <div className="row text-center m10_0">
          <h2>Elemek</h2>
        </div>

        <div className="row mtop10x text-center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={itemadd}
            startIcon={<AddIcon />}
            className={'left'}
          >
            Új tétel
          </Button>

          <div className="right">
            <FormControlLabel
              label={'Automatikus MNB árfolyam'}
              control={
                <Switch
                  checked={!!auto_exrate}
                  onClick={setAutoExrate2}
                  color="primary"
                />
              }
            />
          </div>
        </div>

        <div className="row mtop10">
          <div className="mw1000">
            <table className="table_dark type2 w100">
              <tbody>
                <tr>
                  <th style={{ width: '30px', minWidth: '30px' }}></th>
                  {Object.keys(items_meta)
                    .filter((ihf) => !items_meta[ihf].exclude)
                    .map((ih) => (
                      <th key={ih}>{items_meta[ih].text}</th>
                    ))}
                </tr>
                {items.map((ii: any) => (
                  <ItemRow
                    key={ii.$id}
                    id={ii.$id}
                    data={ii.data}
                    meta={items_meta}
                    // exclude={[]}
                    // disabled_all={false}
                    exrate={
                      auto_exrate
                        ? bookingData.item_exrate[
                            'EX_MNB_' + ii.data?.currency_orig
                          ]?.[currency_date]?.rate
                        : undefined
                    }
                    onAction={onAction}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
