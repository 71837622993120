import type { Id, ReduxAction, ReduxState } from '../content.types';
import { mergeItemA } from './x_mergeItemA';

export function handle_delete_group(action: ReduxAction, newstate: ReduxState) {
  if (action.payload.$del_group) {
    const gid = action.payload.$del_group;
    delete newstate.data.group[gid];
    delete newstate.data.group_item[gid];
    set_group_ids(newstate);
  }
}

export function handle_groups(action: ReduxAction, newstate: ReduxState): void {
  const groups = action.payload.groups_all || action.payload.groups;
  if (!groups) return;

  if (action.payload.groupsall || newstate.data.groupids.length === 0) {
    newstate.data.group = {
      0: {
        id: 0,
        parent_id: -1,
        name: '__root__',
        description: '',
        active: 1,
        groups: null,
        items: null,
        created: 0,
        item_nr: 0,
        item_nr0: 0,
      },
    };
    newstate.data.groupids = [0];
  }

  if (groups) {
    for (const g of groups) {
      newstate.data.group[g.id] = { ...g };
    }
    set_group_ids(newstate);
  }
}

export function count_groups(newstate: ReduxState, id = 0): number {
  const G = newstate.data.group;
  const I = newstate.data.group_item;
  const i_nr: number = Object.keys(I[id] || {}).length;
  const g_nr: number = Object.keys(G)
    .map((gid) => G[+gid])
    .filter((group) => {
      return group.parent_id === id;
    })
    .reduce((s, v) => {
      return s + count_groups(newstate, v.id);
    }, 0);
  const nr = i_nr + (g_nr || 0);
  G[id].item_nr0 = i_nr;
  G[id].item_nr = nr;
  return nr;
}

export function all_children(newstate: ReduxState, id = 0): number[] {
  const G = newstate.data.group;
  let children: Id[] = Object.keys(G)
    .map((gid) => G[+gid])
    .filter((group) => {
      return group.parent_id === id;
    })
    .map((ch) => ch.id);

  children = mergeItemA(G[id].groups || '', children);

  // const g_nr: Id[] = children.reduce((s, v) => {
  //   return [...s, v, ...all_children(newstate, v)];
  // }, []);
  let allChildren: Id[] = [];
  for (const ch of children) {
    allChildren = [...allChildren, ch, ...all_children(newstate, ch)];
  }

  G[id].all_children = allChildren;
  G[id].children = children;
  return allChildren;
}

function set_group_ids(newstate: ReduxState) {
  newstate.data.groupids = Object.keys(newstate.data.group).map((id) => +id);
  //newstate.data.groupids.sort((a, b) => { return newstate.data.group[a].name < newstate.data.group[b].name ? -1 : 1 })
}

export function handle_item_group(action: ReduxAction, newstate: ReduxState) {
  if (action.payload.item_group_all) {
    newstate.data.item_group = {};
    newstate.data.group_item = {};
  }
  const item_group = action.payload.item_group_all || action.payload.item_group;
  if (item_group) {
    for (const x of item_group) {
      const exgrid = newstate.data.item_group[x.item_id] || 0;
      if (newstate.data.group_item[exgrid])
        delete newstate.data.group_item[exgrid][x.item_id];

      newstate.data.item_group[x.item_id] = x.group_id;
      newstate.data.group_item[x.group_id] =
        newstate.data.group_item[x.group_id] || {};
      newstate.data.group_item[x.group_id][x.item_id] = 1;
    }
  }
}
