import { MessageProps } from './store.messages.reducer';

export const message_add_action = (message: MessageProps) => {
  return async (dispatch: any) => {
    dispatch({ type: 'MESSAGE_ADD', message });
  };
};

export const message_add_more_action = (messages: MessageProps[]) => {
  return async (dispatch: any) => {
    dispatch({ type: 'MESSAGE_ADD_MORE', messages });
  };
};

export const message_del_action = (id: number | string) => {
  return async (dispatch: any) => {
    dispatch({ type: 'MESSAGE_DEL', id });
  };
};
