import React from 'react';
import { useSelector } from 'react-redux';

import BookingContext from '../../../content/client_booking/BookingContext';
import { BookingContextProps } from '../../../content/content.types';
import { ContextMenuListCreator } from '../../../util-s';
import { META } from '../../meta/fields';
import { Buttons } from './p-buttons';
import { Chips } from './p-chips';
import { Grid } from './p-grid2';
import { gridHeaders, gridRow } from './u-cells';
import { XField } from './xtypes';

type CompProps = {
  fields: XField[];
  ids: EId[];
  canSelect: boolean;
  onSelect: (inp: any) => void;
  onCellRightClick?: ContextMenuListCreator;
  buttons: React.ReactNode;
  chips: React.ReactNode;
  noexport: boolean;
  noaction: boolean;
  page: number;
  rowCount: number;
  onPageChange: (pg: number) => void;
};

type Element = {
  id: EId;
};
type EId = string;
type Elements = Record<string, Element>;

export function Table({
  fields,
  ids,
  canSelect,
  onSelect,
  onCellRightClick,
  selector,
  buttons,
  chips,
  noexport,
  noaction,
  page,
  rowCount,
  onPageChange,
}: CompProps & { selector: any }) {
  const elements: Elements = useSelector(selector);
  return !elements ? null : (
    <SectionIn
      {...{
        elements,
        fields,
        ids,
        canSelect,
        onSelect,
        onCellRightClick,
        buttons,
        chips,
        noexport,
        noaction,
        page,
        rowCount,
        onPageChange,
      }}
    />
  );
}

type CState = {
  selected_ids: Array<EId>;
  selected_elements: Array<Element>;
};

function SectionIn({
  elements,
  fields,
  ids,
  canSelect,
  onSelect,
  onCellRightClick,
  buttons,
  chips,
  noexport,
  noaction,
  page,
  rowCount,
  onPageChange,
}: CompProps & { elements: Elements }) {
  const bookinContext = React.useContext(BookingContext) as BookingContextProps;
  const [state, setS] = React.useState({
    selected_ids: [],
    selected_elements: [],
  } as CState);

  const _onSelect = (selectionModel: any[]) => {
    console.log(selectionModel);
    const selected_ids = selectionModel;
    const selected_elements = [];
    for (const i of selected_ids) selected_elements.push(elements[i]);
    onSelect({
      ids: selected_ids,
      elements: selected_elements,
    });
    setS({ selected_ids, selected_elements });
  };
  const checkSelection = () => {
    const todel: EId[] = [];
    for (const id of state.selected_ids) {
      if (!ids.includes(id)) todel.push(id);
    }
    if (todel.length > 0) {
      let { selected_ids, selected_elements } = state;
      selected_ids = selected_ids.filter((id) => !todel.includes(id));
      selected_elements = selected_elements.filter(
        (el) => !todel.includes(el.id)
      );
      onSelect({
        ids: selected_ids,
        elements: selected_elements,
      });
      setS({ selected_ids, selected_elements });
    }
  };
  const meta = META(bookinContext);

  const columns = gridHeaders({ meta, fields });
  const rows = elements
    ? ids.map((id: string) => gridRow({ item: elements[id], meta, fields }))
    : undefined;

  React.useEffect(checkSelection, [ids, state, onSelect]);

  return noaction ? (
    <Grid {...{ rows, columns, page, rowCount, onPageChange }} />
  ) : (
    <>
      <Buttons {...{ rows, columns, buttons, noexport }} />
      <Chips {...{ chips }} />
      <Grid
        {...{
          rows,
          columns,
          canSelect,
          onSelect: _onSelect,
          onCellRightClick,
          page,
          rowCount,
          onPageChange,
        }}
      />
    </>
  );
}
