import type { StoreProps } from '../../store.types';

export const selector_data = (store: StoreProps) => store.booking.data;

export const selector_last_loaded = (store: StoreProps) =>
  store.booking.data.last_loaded;

export const selector_booking_eqs = (store: StoreProps) =>
  store.booking.data.booking_eq;
export const selector_booking_heads = (store: StoreProps) =>
  store.booking.data.booking_head;
export const selector_booking_head = (store: StoreProps, head_id: number) =>
  store.booking.data.booking_head[head_id];
export const selector_booking_head_items = (store: StoreProps) =>
  store.booking.data.booking_head_item;
export const selector_booking_head_item = (
  store: StoreProps,
  head_id: number
) => store.booking.data.booking_head_item[head_id];
export const selector_booking_items = (store: StoreProps) =>
  store.booking.data.booking_item;
export const selector_booking_item = (store: StoreProps, item_id: number) =>
  store.booking.data.booking_item[item_id];
