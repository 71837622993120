import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { Dispatch } from 'redux';

import { api_url } from '../../../config/api.config';
import { showMessages } from '../notification/pure.messages.actions.new';
import { userJwt } from '../user/cognito-user';

type AnyProps = {
  url: string;
  params?: object;
  data?: unknown;
  method: Method;
};
type PostParams = {
  url: string;
  params?: object;
  data: unknown;
};
type GetParams = {
  url: string;
  params?: object;
};

export const POST = async (
  postParams: PostParams,
  dispatch?: Dispatch,
  successMessage?: string
) => {
  return REQ({ ...postParams, method: 'POST' }, dispatch, successMessage);
};
export const GET = async (
  getParams: GetParams,
  dispatch?: Dispatch,
  successMessage?: string
) => {
  return REQ({ ...getParams, method: 'GET' }, dispatch, successMessage);
};

export const REQ = async (
  { url, params, method, data }: AnyProps,
  dispatch?: Dispatch,
  successMessage?: string
) => {
  const headers = await setHeaders();
  const config: AxiosRequestConfig = {
    url,
    params,
    headers,
    method,
    data,
    baseURL: api_url(),
    validateStatus: null,
  };
  try {
    const res: AxiosResponse = await axios.request(config);

    if (res.status === 200) {
      if (dispatch && successMessage) {
        showMessages('success', successMessage, dispatch);
      }
      return res.data;
    } else {
      if (dispatch) {
        showMessages('warning', 'A művelet sikertelen', dispatch);
      }
      return null;
    }
  } catch (error) {
    console.log('ERROR', error);
    if (dispatch) {
      showMessages('warning', 'A művelet sikertelen', dispatch);
    }
    return null;
  }
};

const setHeaders = async () => {
  const jwt = await userJwt();
  return { Authorization: jwt };
};
