//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function SetSelect({
  value,
  name,
  options,
  onChange,
  disabled,
  objects,
}: any) {
  const classes = useStyles();
  const [value2, setValue] = React.useState(value);
  const onChange2 = (e: any) => {
    const v = e.target.value;
    onChange(v);
    setValue(v);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={'demo-simple-select-label'}>{name}</InputLabel>
      <Select
        className=""
        id={name}
        value={value2}
        onChange={onChange2}
        disabled={disabled}
      >
        {options.map((o: any) => {
          return objects ? (
            <MenuItem key={o.v} value={o.v}>
              {o.t}
            </MenuItem>
          ) : (
            <MenuItem key={o} value={o}>
              {o.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
