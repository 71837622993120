import { Button } from '@material-ui/core';
import { Sync } from '@material-ui/icons';

type ButtonsProps = { reload: any };

export function ButtonReload({ reload }: ButtonsProps) {
  return (
    <>
      <Button
        key={'button-refres-table'}
        variant="outlined"
        color="primary"
        onClick={reload}
        size="small"
        startIcon={<Sync />}
      >
        Frissít
      </Button>
    </>
  );
}
