import BookinEnv from '../content/client_booking/BookinEnv';
import { FIELDS } from './meta/fields';
import { selector_booking_eqs } from './redux/booking.selectors';
import { Frame } from './u_common/frame';
import { requestFX } from './u_common/u-req-eq';

const exportFX = () => {
  console.log('export EQ is not implemented');
};

type BEprops = { onAction: any; filter: any };

export function BookedEqs({ onAction, filter }: BEprops) {
  return (
    <BookinEnv>
      <Frame
        {...{
          title: 'Kiegyenlítések',
          onAction,
          filter,
          fields: FIELDS.eq,
          selector: selector_booking_eqs,
          requestFX,
          exportFX,
          basefilter,
          canSelect: false,
        }}
      />
    </BookinEnv>
  );
}

const basefilter = {
  id: '',
};
