import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import { CSSProperties } from 'react';

import { usePage } from './u-context';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

export function CustomPagination(props: { style?: CSSProperties }) {
  const { page, count, onPageChange } = usePage();
  const classes = useStyles();

  const onChange = (_event: any, value: any) => {
    onPageChange(value - 1);
  };

  return (
    <Pagination
      className={classes.root}
      color="primary"
      count={count}
      page={page + 1}
      onChange={onChange}
      {...props}
    />
  );
}
