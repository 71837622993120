import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreProps } from '../../store.types';
import Loading from '../../util/client_jsx/loading_page-fa';
import { ItemData } from '../content.types';
import { item_del } from '../redux/content.actions';
import { selector_item } from '../redux/content.selectors';

interface CompProps {
  data: ItemData;
  onAction: (input: any) => void;
}

export function ItemDel({ data, onAction }: CompProps) {
  const { id, role } = data;
  const dispatch = useDispatch();
  const item = useSelector((store: StoreProps) => selector_item(store, id));

  const doit = React.useCallback(async () => {
    if (item) {
      await dispatch(item_del(id));
      onAction({ type: 'listitems', data: { role } });
    }
  }, [id, dispatch, item, onAction, role]);

  React.useEffect(() => {
    doit();
  });

  return <Loading />;
}
