import type { ReduxAction, ReduxState } from '../content.types';
import { get_templates } from '../meta_item/h_templates';
import {
  handle_is_all_loaded,
  handle_item_exrate,
  handle_item_ledger,
} from './content.reducer.booking';
import {
  all_children,
  count_groups,
  handle_delete_group,
  handle_groups,
  handle_item_group,
} from './content.reducer.group';
import { handle_delete_item, handle_items } from './content.reducer.item';

const default_content_state = (): ReduxState => {
  const nullState: ReduxState = {
    data: {
      item: {},
      itemids: [],
      group: {
        0: {
          id: 0,
          parent_id: -1,
          name: '__root__',
          description: '',
          active: 1,
          groups: null,
          items: null,
          created: 0,
          item_nr: 0,
          item_nr0: 0,
        },
      },
      groupids: [],
      item_group: {},
      group_item: {},

      templates: get_templates(),

      role_listed: {},
      role_list: {},

      item_exrate: null,
      item_ledger: null,
      items_for_booking_loaded: false,
    },
    count: 1,
    pending: false,
    error: false,
  };
  return nullState;
};

export function content_reducer(
  state = default_content_state(),
  action: ReduxAction
) {
  switch (action.type) {
    case 'CONTENT_PENDING':
      return { ...state, pending: true, error: false };
    case 'CONTENT_ERR':
      return { ...state, peding: false, error: true };
    case 'CONTENT_RES':
      return do_all(action, state);
    case 'LOGOUT':
      return default_content_state();

    default:
      return state;
  }
}

function do_all(action: ReduxAction, state: ReduxState) {
  const newstate = {
    ...state,
    pending: false,
    error: false,
    count: state.count + 1,
  };
  handle_delete_item(action, newstate);
  handle_delete_group(action, newstate);
  handle_items(action, newstate);
  handle_groups(action, newstate);
  handle_item_group(action, newstate);
  count_groups(newstate);
  all_children(newstate);
  handle_item_exrate(action, newstate);
  handle_item_ledger(action, newstate);
  handle_is_all_loaded(action, newstate);
  return newstate;
}
