import { Item, ItemRole } from '../content.types';
import { BodyBkTemplate, HeaderBkTemplate } from './list_4_bktemplate';
import { BodyBsAccount, HeaderBsAccount } from './list_4_bsaccount';
import { BodyBsAsset, HeaderBsAsset } from './list_4_bsasset';
import { BodyExrate, HeaderExrate } from './list_4_exrate';
import { BodyLedger, HeaderLedger } from './list_4_ledger';
import { BodyPartner, HeaderPartner } from './list_4_partner';

export function SplitTableHeader({ role }: { role: ItemRole }) {
  switch (role) {
    case 'ledger':
      return <HeaderLedger />;
    case 'exrate':
      return <HeaderExrate />;
    case 'partner':
      return <HeaderPartner />;
    case 'bsasset':
      return <HeaderBsAsset />;
    case 'bsaccount':
      return <HeaderBsAccount />;
    case 'bktemplate':
      return <HeaderBkTemplate />;
    default:
      return null;
  }
}

interface SplitTableBody {
  role: ItemRole;
  item: Item;
  onOpen: () => void;
  onEdit: () => void;
  onDel: () => void;
}

export function SplitTableBody({
  role,
  item,
  onOpen,
  onEdit,
  onDel,
}: SplitTableBody) {
  switch (role) {
    case 'ledger':
      return <BodyLedger {...{ item, onOpen, onEdit, onDel }} />;
    case 'exrate':
      return <BodyExrate {...{ item, onOpen, onEdit, onDel }} />;
    case 'partner':
      return <BodyPartner {...{ item, onOpen, onEdit, onDel }} />;
    case 'bsasset':
      return <BodyBsAsset {...{ item, onOpen, onEdit, onDel }} />;
    case 'bsaccount':
      return <BodyBsAccount {...{ item, onOpen, onEdit, onDel }} />;
    case 'bktemplate':
      return <BodyBkTemplate {...{ item, onOpen, onEdit, onDel }} />;
    default:
      return null;
  }
}
