export const frame_action = (
  act: any,
  set: any,
  rename: any,
  addPage: (inp: any) => void
) => {
  const doItem = () => {
    const tonull = ['listitems', 'newitem'].includes(act.type);
    if (['viewitem'].includes(act.type)) rename(act.data?.title || '');
    if (tonull) rename('');
    set({
      type: act.type === 'listitems' ? act.data.role : act.type,
      data: act.data,
    });
  };

  switch (act.type) {
    case 'add_page':
      addPage({ ...act.data });
      break;

    case 'bi_action':
      rename(act.data?.data?.title || '');
      break;

    case 'booking_items':
      set({ type: 'booking_items', data: act.data });
      break;
    case 'justbooked':
    case 'notbooked':
      set({ type: 'booking_heads', data: { type: 'head_select', filter: {} } });
      break;

    case 'newitem':
    case 'viewitem':
    case 'edititem':
    case 'delitem':
    case 'listitems':
      doItem();
      break;

    default:
      console.log('type not handled (a_framework.jsx)', act);
      break;
  }
};
