import { ItemRole } from '../content.types';
import { List1Main } from './list_1_main';

interface ListProps {
  role: ItemRole;
  onAction: () => void;
}

export function ItemsList({ role, onAction }: ListProps) {
  return (
    <>
      <div className="row m50_0">
        <List1Main {...{ role, onAction }} />
      </div>
    </>
  );
}
