/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

export function SetText(props: any) {
  const { value, name, disabled, maxlength, error, onChange, autofocus } =
    props;
  const [svalue, setValue] = useState(value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <div className="row">
        <label>{name}</label>
        <input
          type="text"
          value={svalue}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          disabled={disabled}
          maxLength={maxlength || '500'}
          autoFocus={autofocus || false}
        ></input>
      </div>
      <div className="row error">{error || null}</div>
    </div>
  );
}

export function SetDate(props: any) {
  const [value, setValue] = useState(props.value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <div className="row">
        <label>{props.name}</label>
        <input
          type="date"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(e.target.value);
          }}
          disabled={props.disabled}
          maxLength={props.maxlength || '500'}
        ></input>
      </div>
      <div className="row error">{props.error || null}</div>
    </div>
  );
}

export function SetSwitch(props: any) {
  const { value, name, onChange, disabled } = props;
  const [value2, setValue] = useState(value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <label>{name}</label>
      <span
        className="switch"
        onClick={() => {
          if (!disabled) {
            setValue(1 - value2);
            onChange(1 - value2);
          }
        }}
      >
        {value2 === 1 ? (
          <span className="fas fa-toggle-on cl_green"></span>
        ) : (
          <span className="fas fa-toggle-off cl_red op3x"></span>
        )}
      </span>
    </div>
  );
}

export function SetSelect(props: any) {
  const [value, setValue] = useState(props.value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <label>{props.name}</label>
      <select
        className="simpleselect"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e.target.value);
        }}
        disabled={props.disabled}
      >
        {props.options.map((o: any) => {
          return (
            <option key={o} value={o}>
              {o.toUpperCase()}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function SetSelectVT(props: any) {
  const [value, setValue] = useState(props.value);
  return (
    <div className={'row admin_setItem ' + props.class}>
      <label>{props.name}</label>
      <select
        className="simpleselect"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e.target.value);
        }}
        disabled={props.disabled}
      >
        {props.options.map((o: any) => {
          return (
            <option key={o.v} value={o.v}>
              {o.t}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function SetSelectSwitch(props: any) {
  const [curValue, setValue] = useState(props.value);
  const arr: any = props.options.slice(0, 2);
  const pos: any = arr.findIndex((a: any) => a.v === curValue);
  const click = () => {
    const toVal = arr[1 - pos].v;
    setValue(toVal);
    props.onChange(toVal);
  };
  const eStyle: React.CSSProperties = {
    width: '50%',
    float: 'left',
    textAlign: 'center',
  };
  return (
    <div
      className={'row admin_setItem nooverflow ' + props.css}
      onClick={click}
    >
      <div
        style={{
          width: '200%',
          transition: '0.5s',
          transform: pos ? 'translate(-50%,0)' : 'translate(0%,0)',
          cursor: 'pointer',
        }}
      >
        <div style={eStyle}>{arr[0].t}</div>
        <div style={eStyle}>{arr[1].t}</div>
      </div>
    </div>
  );
}
