import VAL from '../common/validations';

export function valEmail(email: string, fx: (msg: string) => void) {
  let ok = false;
  let msg = '';
  if (email === '' || email === undefined) msg = '* a mező nem lehet üres';
  else if (!new VAL().is('email', email)) msg = '* az email cím nem megfelelő';
  else ok = true;
  fx(msg);
  return ok;
}

export function valPW(pw: string, fx: (msg: string) => void) {
  let ok = false;
  let msg = '';
  if (pw === '' || pw === undefined) msg = '* a mező nem lehet üres';
  else if (pw.length < 4) msg = '* túl rövid';
  else if (pw.length > 30) msg = '* túl hosszú';
  else if (!new VAL().is('password', pw)) msg = '* a jelszó nem megfelelő';
  else ok = true;
  fx(msg);
  return ok;
}

export function valPW2(pw: string, pw2: string, fx: (msg: string) => void) {
  let ok = false;
  let msg = '';
  if (pw2 === '' || pw2 === undefined) msg = '* a mező nem lehet üres';
  else if (pw !== pw2) msg = '* a két jelszó nem egyezik';
  else ok = true;
  fx(msg);
  return ok;
}

export function valGen(
  data: any,
  _typ: any,
  typhint = '',
  fx: (msg: string) => void
) {
  let ok = true;
  let msg = '';
  let typ: any = _typ;
  if (!Array.isArray(typ)) typ = [typ];

  for (const t of typ) {
    if (!new VAL().is(t, data)) ok = false;
  }
  if (!ok)
    msg =
      '* nem megfelelő furmátum' +
      (typhint ? ' ( ' + typhint + ' ) ' || '' : '');
  fx(msg);
  return ok;
}
