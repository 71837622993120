export const arr_unique_sorted = (arr) => {
  let arr2 = [...arr];
  arr2.sort();
  return arr2.filter((v, i, a) => {
    return v !== a[i - 1];
  });
};

export const arr_unique = (arr) => {
  return arr.filter((v, i, a) => {
    return i === a.indexOf(v);
  });
};

export function arr_shuffle(arr) {
  let arr2 = [...arr];
  for (let i = arr2.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    [arr2[i], arr2[j]] = [arr2[j], arr2[i]];
  }
  return arr2;
}
export function arr_pick(arr) {
  let arr2 = arr_shuffle(arr);
  return arr2[0];
}

export const string = {
  start: function (str, st0) {
    return str.slice(0, st0.length) === st0;
  },
};
