export const BOOKING_YEAR_FILTER = [
  { v: '', t: '-- Nincs kiválasztva --' },
  { v: 2023, t: 2023 },
  { v: 2022, t: 2022 },
  { v: 2021, t: 2021 },
  { v: 2020, t: 2020 },
  { v: 2019, t: 2019 },
  /*{ v: 201912, t: '- 201912' },
    { v: 201911, t: '- 201911' },
    { v: 201910, t: '- 201910' },
    { v: 201909, t: '- 201909' },
    { v: 201908, t: '- 201908' },
    { v: 201907, t: '- 201907' },
    { v: 201906, t: '- 201906' },
    { v: 201905, t: '- 201905' },
    { v: 201904, t: '- 201904' },
    { v: 201903, t: '- 201903' },
    { v: 201902, t: '- 201902' },
    { v: 201901, t: '- 201901' },*/
  { v: 2018, t: 2018 },
  { v: 2017, t: 2017 },
  { v: 2016, t: 2016 },
  { v: 2015, t: 2015 },
  { v: 2014, t: 2014 },
];
