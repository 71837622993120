export default function Validations() {
  //password:	/([A-Za-z0123456789<>#&@{}$§3€]{4,30})/,
  //number:			/^\-{0,1}([1-9][0-9]*|[0])([.][0-9]*[1-9])?$/,
  //change_script:	/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi

  const regex = {
    username: /^[\dA-Za-z]{4,30}$/,
    password: /^[\w!#$%&()*+<>@[\]|§ÁÉÍÓÖ×ÚÜßáéíóö÷úüŐőŰű€]{4,30}$/,
    city: /^[\sA-Za-zÁÉÍÓÖÚÜáéíóöúüŐőŰű-]{2,70}$/,
    name: /^[\d\sA-Za-zÁÉÍÓÖÚÜáéíóöúüŐőŰű-]{3,50}$/,
    address: /^[\d\s,.;A-Za-zÁÉÍÓÖÚÜáéíóöúüŐőŰű-]{3,100}$/,
    ziphu: /^[1-9]\d{3}$/,
    zip: /^[1-9]\d{3,5}$/,
    tax: /^[\dA-Za-z-]{8,20}$/,

    email: /^\w+(\.\w+)*@[\dA-Za-z-]+(\.[\dA-Za-z-]+)*(\.[A-Za-z]{2,7})$/,
    number: /^([1-9]\d*|0)(\.\d*[1-9])?$/,
    integer: /^([1-9]\d*|0)$/,
    integer30000: /^([1-9]\d*|0)$/,
    required: /^[\w!#$%&()*+,./:<>?@[\]`|§ÁÉÍÓÖ×ÚÜßáéíóö÷úüŐőŰű˝€-]+$/,
    text: /^[\d!#$%&()*+,./:<>?@A-Z[\]_-\s`a-z|§ÁÉÍÓÖ×ÚÜßáéíóö÷úüŐőŰű˝€]+$/,
    words: /^[\dA-Za-zÁÉÍÓ-\sÖÚÜáéíóöúüŐőŰű]+$/,
    wordsEng: /^[\d\sA-Za-z-]+$/,
    word: /^[\dA-Za-zÁÉÍÓÖÚÜáéíóöúüŐőŰű]+$/,
    wordEng: /^[\dA-Za-z]+$/,
    change_text: /[^\d!#$%&()*+,./:<>?@A-Z_-\s`a-z|§ÁÉÍÓÖ×ÚÜßáéíóö÷úüŐőŰű˝€]/g,
    change_space: /\s+/g,
    change_quote: /"/g,
    change_squote: /'/g,
    bookingid: /^[A-Z]{0,1}\d{1,10}$/,
  };
  const test_functions = {
    date1: (val) => {
      try {
        if (Number.isNaN(new Date(val).getTime())) return false;
        const [y, m, d] = val.toString().split('-');
        if (y * 1 < 1990) return false;
        if (y * 1 > 2050) return false;
        if (m * 1 < 1) return false;
        if (m * 1 > 12) return false;
        if (d * 1 < 1) return false;
        if (d * 1 > 31) return false;
      } catch {
        return false;
      }
      return true;
    },
    nozero: (val) => val * 1 !== 0,
  };

  const range = { integer30000: [1, 30_000] };

  this.is = (what, value) => {
    if (what) {
      if (regex[what]) return regex[what].test(value);
      if (test_functions[what]) return test_functions[what](value);
    }
    return true;
  };

  this.isbetween = (what, value) => {
    if (range[what] === undefined) return true;
    return Number(value) >= range[what][0] && Number(value) <= range[what][1];
  };

  this.checkJson = (json, value) => {
    let ok = true;
    let validations = json.validations;
    if (json.vs) validations = json.vs;
    for (const i in validations) {
      const v = validations[i];
      ok = ok && this.is(v, value);
      ok = ok && this.isbetween(v, value);
    }
    return ok;
  };
  this.checkFile = (file, options) => {
    let ok = true;
    const msg = [];
    try {
      if (file.size >= options.maxfilesize) {
        ok = false;
        msg.push('msg_file_toobig');
      }
      if (
        options.type === 'img' &&
        !['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
          file.type
        )
      ) {
        ok = false;
        msg.push('picture_wrongformat');
      }
      return { ok: ok, msg: msg };
    } catch {
      return { ok: false, msg: 'something_wrong' };
    }
  };
  this.checkSame = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  this.totext = (str) => {
    str = str.replace(regex.change_quote, '˝');
    str = str.replace(regex.change_squote, '`');
    str = str.replace(regex.change_space, ' ');
    str = str.replace(/;+/g, ',');
    str = str.replace(regex.change_text, '');
    str = str.replace(/<script>/, '');
    str = str.replace(/<\/script>/, '');
    return str;
  };
}
