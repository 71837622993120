import Loading from '../../util/client_jsx/loading_page-fa';
import { repNumber } from '../../util/common/number';

interface CompProps {
  data: any;
}

export function DataTable({ data }: CompProps) {
  const levels = data?.jrows?.map((row: any) => row.$level) || [];
  const corr = 5 - Math.max(...levels);
  return !data ? (
    <Loading />
  ) : (
    <>
      <div className="row mtop10">
        <table className="table_dark type1 max-content canselect m0a">
          <tbody>
            <THead field={data.field} />
            <TData field={data.field} data={data.jrows} corr={corr} />
          </tbody>
        </table>
      </div>
    </>
  );
}

function THead({ field }: { field: any }) {
  return (
    <>
      <tr>
        {Object.keys(field).map((f) => (
          <th key={f}>{field[f].text}</th>
        ))}
      </tr>
    </>
  );
}

function TData({ field, data, corr }: { field: any; data: any; corr: any }) {
  return (
    <>
      {data.map((row: any, i: number) => (
        <TRow key={i} field={field} row={row} corr={corr} />
      ))}
    </>
  );
}

function TRow({ field, row, corr }: { field: any; row: any; corr: any }) {
  return (
    <>
      <tr className={'level_' + (row.$level ? row.$level + corr : 0)}>
        {Object.keys(field).map((f) => (
          <RepValue key={f} field={field[f]} value={row[f]} />
        ))}
      </tr>
    </>
  );
}

function RepValue({ value, field }: { value: any; field: any }) {
  return field.type === 'measure' ? (
    <td className="text-right">{repNumber(value)}</td>
  ) : (
    <td className="text-left">{value}</td>
  );
}
