import axios from 'axios';

import { api_url } from '../../../config/api.config';
import { userJwt } from '../user/cognito-user';

const FX = !!process.env.REACT_APP_API_FX;

export const reqUpload = async (uploadUrl: string, file: File) => {
  if (FX) {
    const res1 = await fxUploadStep1(uploadUrl, file);
    const { signedUrl, key } = res1.data;
    await fxUploadStep2(signedUrl, file);
    return fxUploadStep3(uploadUrl, key);
  } else {
    return simpleUpload(uploadUrl, file);
  }
};

const simpleUpload = async (uploadUrl: string, file: File) => {
  const formData = new FormData();
  formData.append('onefile', file, file.name);

  return axios.post(uploadUrl, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL: api_url(),
  });
};

const fxUploadStep1 = async (uploadUrl: string, file: File) => {
  const body = { filename: file.name, contentype: file.type };
  const jwt = await userJwt();
  return axios.post(uploadUrl, body, {
    headers: { Authorization: jwt },
    baseURL: api_url(),
  });
};

const fxUploadStep2 = async (signedUrl: string, file: File) => {
  return axios.put(signedUrl, file, {
    headers: { 'Content-Type': file.type },
  });
};

const fxUploadStep3 = async (uploadUrl: string, key: string) => {
  const body = { key };
  const jwt = await userJwt();
  return axios.post(uploadUrl, body, {
    headers: { Authorization: jwt },
    baseURL: api_url(),
  });
};
