import type { ReduxAction, ReduxState } from '../booking.types';

const default_booking_state = (): ReduxState => {
  return {
    data: {
      booking_head: {},
      booking_head_item: {},
      booking_item: {},
      booking_eq: {},
    },
    count: 1,
    pending: false,
    error: false,
  };
};

export function booking_reducer(
  state: ReduxState = default_booking_state(),
  action: ReduxAction
) {
  switch (action.type) {
    case 'BOOKING_PENDING':
      return { ...state, pending: true, error: false };
    case 'BOOKING_ERR':
      return { ...state, peding: false, error: true };
    case 'BOOKING_RES':
      return do_all(action, state);
    case 'LOGOUT':
      return default_booking_state();

    default:
      return state;
  }
}

function do_all(action: ReduxAction, state: ReduxState) {
  const newstate = {
    ...state,
    pending: false,
    error: false,
    count: state.count + 1,
  };
  const { booking_item, head_id, booking_head, booking_eq } = action.payload;

  if (booking_item) {
    for (const row of booking_item) {
      newstate.data.booking_item[row.id] = row;
      if (head_id) {
        newstate.data.booking_head_item[head_id] =
          newstate.data.booking_head_item[head_id] || [];
        if (!newstate.data.booking_head_item[head_id].includes(row.id))
          newstate.data.booking_head_item[head_id].push(row.id);
      }
    }
  }

  if (booking_head) {
    for (const row of booking_head) {
      newstate.data.booking_head[row.id] = row;
    }
  }

  if (booking_eq) {
    for (const row of booking_eq) {
      newstate.data.booking_eq[row.id] = row;
    }
  }

  return newstate;
}
