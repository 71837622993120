/* eslint-disable promise/no-callback-in-promise */
import { useDispatch } from 'react-redux';

import { BHead } from '../booking.types';
import { form_data_storno, form_meta_storno } from '../meta/meta_eq';
import { book_eq_storno } from '../redux/booking.actions';
import { EqModal } from './h_eq_modal';

type CompProps = {
  head: BHead | undefined;
  close: () => void;
  cb: (res: unknown) => void;
};

export function StornoModal({ head, close, cb }: CompProps) {
  const dispatch = useDispatch();

  const onSend = (_fields: any) => {
    const fields = { ..._fields };
    const input = { head_id: head!.id, fields };
    console.log('release', input);
    book_eq_storno({ head_id: head!.id, fields }, dispatch)
      .then(cb)
      .catch(null);
    close();
  };
  const onCancel = () => {
    close();
  };

  return !head ? null : (
    <EqModal
      on={!!head}
      id={'B' + head.id}
      onCancel={onCancel}
      onSend={onSend}
      form_data={form_data_storno}
      form_meta={form_meta_storno}
      question={'Valóban sztornózod a következő bizonylatot?'}
    />
  );
}
