import { Button } from '@material-ui/core';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import React from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../util/client_jsx/modal-new';
import { CreateForm } from '../../util/io_form-mat';
import { willBook } from '../meta/balance';
import { form_data_storno, form_meta_storno } from '../meta/meta_eq';
import { book_eq_pairing } from '../redux/booking.actions';

const def_text = 'Kiegyenlít';

type CompProps = {
  ids: any;
  balance: any;
  onAction: (inp?: any) => void;
  text: any;
};

export function ActionButtonPairing({
  ids,
  balance,
  onAction,
  text,
}: CompProps) {
  const [fdata, setF] = React.useState({ data: form_data_storno, ok: true });
  const { ok } = balance;
  const dispatch = useDispatch();

  const [modalOpen, setM] = React.useState(false);
  const openModal = () => {
    if (ok) setM(true);
  };
  const noModal = () => {
    setM(false);
  };
  const yesModal = () => {
    send(ids, fdata.data);
    setM(false);
  };

  const SetData = (_fdata: any) => {
    let ok = true;
    const ndata: any = {};
    for (const f of Object.keys(_fdata)) {
      ndata[f] = _fdata[f]?.data;
      ok = ok && _fdata[f].ok;
    }
    setF({ data: ndata, ok });
  };

  const send = (items: any, data: any) => {
    book_eq_pairing({ items, ...data }, dispatch)
      .then(() => {
        onAction(true);
      })
      .catch(() => {
        console.log('error with pairing');
      });
    setM(false);
  };

  return (
    <>
      <Button
        color={ok ? 'primary' : 'secondary'}
        variant="contained" //{ok ? "contained" : "outlined"}
        size="small"
        //disabled={!ok}
        startIcon={<CompareArrowsIcon />}
        onClick={() => {
          if (ok) openModal();
        }}
      >
        {ok ? def_text : text || def_text}
      </Button>
      <SaveModal {...{ modalOpen, yesModal, noModal, SetData }}>
        <ShowWhatWillBook {...{ balance }} />
      </SaveModal>
    </>
  );
}

const cast = (f: any, item: any) => {
  const v: string = item[f];
  if (f === 'side') return { D: 'T', C: 'K' }[v];
  if (f === 'currency_orig' && item.currency_orig === item.currency) return '';
  if (f === 'value_orig' && item.currency_orig === item.currency) return '';
  return v;
};

const ShowWhatWillBook = ({ balance }: any): any => {
  const willbook = willBook(balance);
  const fields = [
    'side',
    'ledgeritem',
    'currency',
    'value',
    'currency_orig',
    'value_orig',
  ];

  return (
    <table style={{ margin: '10px auto', fontWeight: 'bold' }}>
      <tbody>
        {willbook.map((item) =>
          !item ? null : (
            <tr key={item.ledgeritem}>
              {fields.map((f) => (
                <td className="p5" key={f}>
                  {cast(f, item)}
                </td>
              ))}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const SaveModal = ({
  modalOpen,
  yesModal,
  noModal,
  SetData,
  children,
}: any) => {
  return (
    <Modal
      open={modalOpen}
      title={'Tételek pontozása'}
      button="Igen"
      onAction={yesModal}
      onClose={noModal}
    >
      <div className="row p20_0 text-center">
        <div className="row">Valóban szeretnéd kiegyenlíteni a tételeket?</div>
        <div className="row">{children}</div>
        <DataForm onChange={SetData} />
      </div>
    </Modal>
  );
};

function DataForm({ onChange }: any) {
  return (
    <>
      <div className="row p20_0">
        <div className="mw400">
          <CreateForm
            data={form_data_storno}
            meta={form_meta_storno}
            css={'small2 text-left'}
            exclude={[]}
            disabled_all={false}
            autofocus={false}
            button={false}
            cancel_button={false}
            onSubmit={onChange}
          />
        </div>
      </div>
    </>
  );
}
