import { Redirect, Route, Switch } from 'react-router-dom';

import { Landing } from '../pages';
import { Login } from './util';

export function App() {
  return (
    <>
      <Login>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>

          <Route path="/">
            <Redirect to={'/'} />
          </Route>
        </Switch>
      </Login>
    </>
  );
}
