import { Item } from '../content.types';
import { RowBasics } from './edit_4_basics';
import { BkTemplateRows } from './edit_4_bktemplate';
import { BsAccountRows } from './edit_4_bsaccount';
import { BsAssetRows } from './edit_4_bsasset';
import { ExrateRows } from './edit_4_exrate';
import { LedgerRows } from './edit_4_ledger';
import { PartnerRows } from './edit_4_partner';

interface CompProps {
  item: Item;
  disabled_all: boolean;
  onChange: (input: any) => void;
}

export function DataRows({ item, disabled_all, onChange }: CompProps) {
  return (
    <>
      <RowBasics item={item} disabled_all={disabled_all} onChange={onChange} />
      <UniqueRows item={item} disabled_all={disabled_all} onChange={onChange} />
    </>
  );
}

function UniqueRows({ item, disabled_all, onChange }: CompProps) {
  switch (item.role) {
    case 'ledger':
      return (
        <LedgerRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );
    case 'exrate':
      return (
        <ExrateRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );
    case 'partner':
      return (
        <PartnerRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );
    case 'bsasset':
      return (
        <BsAssetRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );
    case 'bsaccount':
      return (
        <BsAccountRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );
    case 'bktemplate':
      return (
        <BkTemplateRows
          item={item}
          disabled_all={disabled_all}
          onChange={onChange}
        />
      );

    default:
      return null;
  }
}
