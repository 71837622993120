import { Snackbar } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { message_del_action } from './store.messages.actions';
import { MessageProps } from './store.messages.reducer';
import {
  selector_messages,
  selector_refresh,
} from './store.messages.selectors';

const Messages = () => {
  const refresh = useSelector(selector_refresh);
  const messages = useSelector(selector_messages);
  return !refresh ? null : <MessagesIn messages={messages} />;
};

const MessagesIn = ({ messages }: { messages: MessageProps[] }) => {
  const dispatch = useDispatch();
  return (
    <>
      {messages.map((message, i) => (
        <MyAlert
          key={message.id}
          nr={i}
          {...message}
          onClose={() => {
            dispatch(message_del_action(message.id!));
          }}
        />
      ))}
    </>
  );
};

type AlertProps = {
  key?: number | string;
  nr: number;
  text?: string;
  type?: Color;
  onClose: any;
};

const MyAlert = ({ nr, text, type, onClose }: AlertProps) => {
  const [open, setO] = React.useState(true);
  const _onClose = () => {
    setO(false);
    if (onClose) onClose();
  };
  const dur = 4000;
  const severity: Color = type || 'info';
  return (
    <Snackbar
      open={open}
      autoHideDuration={dur}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={_onClose}
    >
      <MuiAlert
        onClose={_onClose}
        severity={severity}
        elevation={6}
        variant="filled"
        style={{ transform: 'translate(0px, ' + nr * 60 + 'px)' }}
      >
        {text}
      </MuiAlert>
    </Snackbar>
  );
};

export default Messages;
