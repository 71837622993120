import { useState } from 'react';

import BookinEnv from '../content/client_booking/BookinEnv';
import { BHead } from './booking.types';
import { FIELDS } from './meta/fields';
import { selector_booking_heads } from './redux/booking.selectors';
import { ReleaseModal } from './u_action/h_eq_release';
import { StornoModal } from './u_action/h_eq_storno';
import { ContextMenuProps, Frame } from './u_common/frame';
import { exportFX, requestFX } from './u_common/u-req-head';

type BHprops = { onAction: (_inp: unknown) => void; filter: any };
type RightClickProps = Partial<BHead> & { name: string };

export function BookedHeads({ onAction, filter }: BHprops) {
  const [releaseHead, setRH] = useState<BHead | undefined>(undefined);
  const [stornoHead, setSH] = useState<BHead | undefined>(undefined);
  const [frameRefresh, setFR] = useState<number>(1);
  const closeReleaseModal = () => setRH(undefined);
  const closeStornoModal = () => setSH(undefined);

  const update = (res: unknown) => {
    console.log({ res });
    setFR(Date.now());
  };

  const onCellRightClick = (
    inp: RightClickProps,
    onAction: (inp2: unknown) => void
  ): ContextMenuProps => {
    return {
      list: [
        {
          text: 'Tételek',
          onClick: () => {
            onAction({
              type: 'add_page',
              data: {
                type: 'booking_items',
                text: 'Tételek',
                data: { filter: { head_id: inp.id } },
              },
            });
          },
        },
        {
          text: 'Storno',
          onClick: () => setSH(inp as BHead),
        },
        {
          text: 'Visszakönyvelés',
          onClick: () => setRH(inp as BHead),
        },
      ],
      title:
        `#${inp.id} - ${inp.name.slice(0, 20)}` +
        (inp.name.length > 20 ? '...' : ''),
    };
  };

  return (
    <BookinEnv>
      <Frame
        {...{
          title: 'Könyvelési Bizonylatok',
          onAction,
          filter,
          fields: FIELDS.head,
          selector: selector_booking_heads,
          requestFX,
          exportFX,
          basefilter,
          canSelect: false,
          frameRefresh,
          onCellRightClick: (inp: RightClickProps) =>
            onCellRightClick(inp, onAction),
        }}
      />
      <ReleaseModal
        {...{ head: releaseHead, close: closeReleaseModal, cb: update }}
      />
      <StornoModal
        {...{ head: stornoHead, close: closeStornoModal, cb: update }}
      />
    </BookinEnv>
  );
}

const basefilter = {
  id: '',
  booking_period: '',
};
