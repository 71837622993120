import ListOfReportsAndQueries from './client/m_list_0';

export function BI({ action }: { action: any }) {
  return (
    <>
      <div className="row m50_0 p5">
        <Reports action={action} />
      </div>
    </>
  );
}

function Reports({ action }: any) {
  return <ListOfReportsAndQueries {...{ action }} />;
}
