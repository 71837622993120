import { Chip } from '@material-ui/core';

type CompProps = { filter: any[]; delFilter: (pair: any) => void };

export function FilterChips({ filter, delFilter }: CompProps) {
  return (
    <div style={{ float: 'left' }}>
      <FilterChips2 {...{ filter, delFilter }} />
    </div>
  );
}

function FilterChips2({ filter, delFilter }: any) {
  const allFV = Object.keys(filter)
    .map((f) => ({ field: f, value: filter[f] }))
    .filter((j) => !!j.value || j.value === 0);

  const OneChip = ({ pair }: any) => (
    <Chip
      label={`${pair.field}: ${pair.value}`}
      //onClick={handleClick}
      onDelete={() => {
        delFilter(pair);
      }}
      style={{ marginRight: '5px' }}
    />
  );

  const OnePair = ({ pair }: any) => {
    return Array.isArray(pair.value) ? (
      pair.value.map((v: string) => (
        <OneChip
          {...{ pair: { ...pair, value: v } }}
          key={`${pair.field}-${v}`}
        />
      ))
    ) : (
      <OneChip {...{ pair }} key={pair.field} />
    );
  };

  return (
    <>
      {allFV.map((pair) => (
        <OnePair {...{ pair }} key={pair.field} />
      ))}
    </>
  );
}
