import { Button, Icon } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { reqDownloadPath } from '../../util';
import { ContextMenuListCreator, Page } from '../../util-s';
import { FilterButton } from '../u_util/u-filter-button2';
import { FilterChips } from '../u_util/u-filter-chips';
import { Frame2 } from './frame2';

export type {
  ContextMenuList,
  ContextMenuListCreator,
  ContextMenuProps,
} from '../../util-s';

const BASE_SELECTED = {
  ids: [],
  balance: { total: { local: 0 }, ok: false },
};

type FrameProps = {
  title: any;
  onAction: any;
  filter: any;
  fields: any;
  noaction?: any;
  noexport?: any;
  canSelect: any;
  basefilter: any;
  fastfilters?: any;
  selector: any;
  requestFX: any;
  exportFX: any;
  selectFX?: any;
  Extra?: any;
  frameRefresh?: number;
  onCellRightClick?: ContextMenuListCreator;
};

export function Frame({
  title,
  onAction,
  filter,
  fields,
  noaction,
  noexport,
  canSelect,
  basefilter,
  fastfilters,
  selector,
  requestFX,
  exportFX,
  selectFX,
  Extra,
  frameRefresh = 1,
  onCellRightClick,
}: FrameProps) {
  const dispatch = useDispatch();
  const [state, setS] = useState({
    selected: BASE_SELECTED,
    filter: { ...basefilter, ...filter },
    refresh: 1,
  });

  const [fileUrl, setF] = useState('');
  const [exporting, setE] = useState<boolean>(false);

  const onSelect = (input: any) => {
    if (selectFX) selectFX({ input, state, setFX: setS });
  };
  const setFilter = (filter: any) => {
    setS({
      ...state,
      filter: { ...basefilter, ...filter },
      refresh: state.refresh + 1,
    });
  };
  const onFilter = (newvals: any) => {
    const newfilter = { ...state.filter };
    for (const f in newvals) {
      const val = newvals[f];
      if (Array.isArray(newfilter[f])) {
        if (Array.isArray(val)) newfilter[f] = [...newfilter[f], ...val];
        else newfilter[f] = [...newfilter[f], val];
        newfilter[f] = newfilter[f].filter(
          (x: any, i: number, a: any[]) => a.indexOf(x) === i
        );
      } else newfilter[f] = val;
    }
    setFilter(newfilter);
  };
  const onExport = () => {
    exportFX({ filter: state.filter, dispatch })
      .then((res: any) => {
        setF(reqDownloadPath(res.filepath || ''));
        setE(false);
      })
      .catch(null);
    setE(true);
  };

  const delFilter = ({ field, value }: any) => {
    const newfilter = { ...state.filter };
    if (Array.isArray(newfilter[field])) {
      newfilter[field] = newfilter[field].filter((v: any) => v !== value);
    } else {
      delete newfilter[field];
    }
    setFilter(newfilter);
  };
  const Action = (act: any) => {
    if (!act.type) setFilter(act.filter || state.filter);
    else onAction(act);
  };

  const doRefresh = () => {
    setS({
      ...state,
      refresh: state.refresh + 1,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(doRefresh, [frameRefresh]);

  return (
    <>
      <Page title={title}>
        <div className="row">
          {
            <Frame2
              {...{
                filter: state.filter,
                onAction: Action,
                fields,
                selector,
                requestFX,
                noaction,
                noexport,
                canSelect,
                onSelect,
                onCellRightClick,
                refresh: state.refresh,
              }}
              buttons={
                <>
                  <FilterButton
                    {...{ filter: state.filter, onFilter, fastfilters }}
                  />
                  <Button
                    key={'button-xls-full-export'}
                    variant="outlined"
                    color="primary"
                    onClick={onExport}
                    size="small"
                    startIcon={
                      <Icon
                        className={
                          exporting
                            ? 'fas fa-spinner fa-spin'
                            : 'far fa-file-excel'
                        }
                      />
                    }
                    disabled={exporting}
                  >
                    {'Export'}
                  </Button>
                </>
              }
              chips={
                <div style={{ float: 'left', minHeight: '42px' }}>
                  {!Extra ? null : <Extra {...{ state, setFX: setS }} />}
                  <FilterChips {...{ filter: state.filter, delFilter }} />
                </div>
              }
            />
          }
        </div>
      </Page>
      <iframe
        id="downloadarea-frame"
        title="downloadarea"
        src={fileUrl}
        style={{ display: 'none' }}
      ></iframe>
    </>
  );
}
