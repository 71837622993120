import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';

import { signOut, UserName } from '../util';
import { nav_list1a, nav_list1b, nav_list2 } from './u-meta';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export function SideBar({ addPage }: { addPage: any }) {
  return (
    <>
      <List>
        <ListItem
          style={{ opacity: '0.5', paddingTop: '6px', paddingBottom: '6px' }}
        >
          <UserName />
        </ListItem>
      </List>
      <Divider />
      <PartGroup
        {...{
          addPage,
          list: nav_list1b,
          groupKey: 'bookingGroup',
          groupTitle: 'Könyvelés',
          open: true,
        }}
      />
      <Divider />
      <PartSimple {...{ addPage, list: nav_list1a }} />
      <Divider />
      <PartGroup
        {...{
          addPage,
          list: nav_list2,
          groupKey: 'settings',
          groupTitle: 'Beállítások',
        }}
      />
      <Divider />
      <PartOther />
    </>
  );
}

function PartOther() {
  return (
    <List>
      <ListItem button dense onClick={signOut}>
        Kijelentkezés
      </ListItem>
    </List>
  );
}

function PartSimple({ addPage, list, nested }: any) {
  const classes = useStyles();
  return (
    <>
      <List>
        {list.map((li: any) => (
          <ListItem
            key={li.text}
            button
            dense
            onClick={() => addPage(li)}
            className={nested ? classes.nested : undefined}
          >
            <ListItemText primary={li.text} />
          </ListItem>
        ))}
      </List>
    </>
  );
}

function PartGroup({ addPage, list, groupKey, groupTitle, open }: any) {
  const [openGroup, setS] = React.useState(open);
  const toggleSettings = () => {
    setS(!openGroup);
  };
  return (
    <>
      <List>
        <ListItem button dense key={groupKey} onClick={toggleSettings}>
          <ListItemText primary={groupTitle} />
          {openGroup ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openGroup} timeout="auto" unmountOnExit>
          <PartSimple {...{ addPage, list, nested: true }} />
        </Collapse>
      </List>
    </>
  );
}
