import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { reqDownloadPath } from '../../util';
import time from '../../util/common/time';
// import { CreateXls } from '../../util/common/xlsx';
import { data_to_xls } from '../meta/data_to_xls';
import { bi_query, bi_xlsx } from '../redux/bi.actions';
import { ReportButtons } from './p_data_2_buttons';
import { DataTable } from './p_data_2_datatable';
import { FileList } from './p_data_2_filelist';

interface CompProps {
  query: any;
  onAction: (input: { type: string; data?: any }) => void;
}

const nullstate = (): any => {
  return { data: null, iframe_src: '', files: [], inited: false };
};

export default function ShowData({ query, onAction }: CompProps) {
  const [state, setS] = useState(nullstate());
  const setI = () => {
    setS({ ...state, inited: true });
  };
  const setD = (data: any) => {
    console.log('setD', data);
    setS({ ...state, data, inited: true });
  };
  const setF = (newfile: any) => {
    setS({
      ...state,
      iframe_src: newfile.dest,
      //files: [...state.files, newfile] ,
      files: [newfile],
    });
  };

  const dispatch = useDispatch();

  const reload = () => {
    setS(nullstate());
  };
  const back = () => {
    onAction({ type: 'list', data: {} });
  };

  // const xlsx = () => {
  //   if (!state.data) return;
  //   try {
  //     const filename =
  //       'Kimutatás - ' +
  //       state.data.query.title +
  //       ' - ' +
  //       time.datatime_str() +
  //       '.xlsx';
  //     CreateXls(data_to_xls(state.data), filename);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const xlsx2 = () => {
    bi_xlsx(query, dispatch)
      .then((x: any) => {
        if (x?.filepath) {
          const dest = reqDownloadPath(x.filepath);
          setF({ name: x.filename, dest, time: new Date() });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const load_query = () => {
    if (state.inited) return;
    bi_query(query, dispatch)
      .then((x: any) => {
        setD(x);
      })
      .catch((error) => {
        console.log('BI query error', error);
        setD(null);
      });
    setI();
  };

  useEffect(load_query);

  return (
    <>
      <FileList files={state.files} />
      <DataTable data={state.data} />
      <ReportButtons {...{ back, reload, xlsx2 }} />
      <iframe
        id="downloadarea"
        title="downloadarea"
        src={state.iframe_src}
        style={{ display: 'none' }}
      ></iframe>
    </>
  );
}
