import type { StoreProps } from '../../store.types';
import type { Id, ItemRole, ReduxStateData } from '../content.types';

export const selector_data = (store: StoreProps): ReduxStateData =>
  store.content.data;

export const selector_templates = (store: StoreProps) =>
  store.content.data.templates;
export const selector_role_listed_all = (store: StoreProps) =>
  store.content.data.role_listed;
export const selector_role_listed = (store: StoreProps, role: ItemRole) =>
  store.content.data.role_listed[role];
export const selector_role_lists = (store: StoreProps) =>
  store.content.data.role_list;
export const selector_role_list = (store: StoreProps, role: ItemRole) =>
  store.content.data.role_list[role];

export const selector_items = (store: StoreProps) => store.content.data.item;
export const selector_itemids = (store: StoreProps) =>
  store.content.data.itemids;
export const selector_group_item = (store: StoreProps) =>
  store.content.data.group_item;
export const selector_group_items = (store: StoreProps, groupId: Id) =>
  store.content.data.group[groupId].items;

export const selector_item = (store: StoreProps, id: Id) =>
  store.content.data.item[id];
export const selector_item_ledgers = (store: StoreProps) =>
  store.content.data.item_ledger;
export const selector_item_ledger = (store: StoreProps, ledgeritem: string) =>
  store.content.data.item_ledger?.[ledgeritem];
export const selector_item_exrates = (store: StoreProps) =>
  store.content.data.item_exrate;

export const selector_items_for_booking_loaded = (store: StoreProps) =>
  store.content.data.items_for_booking_loaded;
