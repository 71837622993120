/* eslint-disable unicorn/consistent-function-scoping */
import './grid2.scss';

import React from 'react';

import { RowProps } from './types';

export const GridHead = ({
  columns,
  canSelect,
  selected,
  htoggle,
}: RowProps) => {
  const rowSelected = React.useMemo(() => {
    return selected.length > 0;
  }, [selected]);

  return (
    <>
      <tr>
        {canSelect && (
          <td>
            <input type="checkbox" checked={rowSelected} onChange={htoggle} />
          </td>
        )}
        {columns?.map((col, i) => (
          <th key={i}>{col.headerName}</th>
        ))}
      </tr>
    </>
  );
};
