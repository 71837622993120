import { BookingContextProps, Id, ItemLedger } from '../content.types';

export function LedgerName(
  ledgerItem: ItemLedger,
  bookinContext: BookingContextProps
) {
  const item_ledger = bookinContext?.item_ledger[ledgerItem];
  return !item_ledger ? null : item_ledger.name;
}

export function ItemTitle(id: Id | null, bookinContext: BookingContextProps) {
  if (!id) return null;
  const item = bookinContext?.item[id];
  return !item ? `(${id})` : item.title || item.code;
}
