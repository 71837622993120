/* eslint-disable jsx-a11y/no-autofocus */
import { useContext } from 'react';

import BookingContext from '../../content/client_booking/BookingContext';
import { SetSelect } from '../../util/io_form-mat/set-select';
import { SetText } from '../../util/io_form-mat/set-text';
import { BOOKING_YEAR_FILTER } from '../meta/filter';

type SetFilterProps = { value: any; onValue: any; field: any };
type CompProps = { value: any; onChange: any };
type CompProps2 = CompProps & { field: any };

export function SetFilter({ field, value, onValue }: SetFilterProps) {
  const onChange = (newvalue: any) => {
    const newfilter = { [field]: newvalue };
    onValue(typeof newvalue === 'object' ? newvalue : newfilter);
  };
  switch (field) {
    case 'booking_year':
      return <FilterBookingYear value={value} onChange={onChange} />;
    case 'period':
    case 'booking_period':
      return <FilterBookingPeriod value={value} onChange={onChange} />;
    case 'ledgeritem':
      return <FilterLedgerItem value={value} onChange={onChange} />;
    case 'id':
    case 'hid':
    case 'iid':
    case 'eq_id':
    case 'head_id':
      return <FilterText {...{ field, value, onChange }} />;
    case 'status':
      return <FilterStatus value={value} onChange={onChange} />;
    case 'currency_orig':
      return <FilterCurrency value={value} onChange={onChange} />;
    default:
      return null;
  }
}

function FilterText({ field, value, onChange }: CompProps2) {
  const onChange2 = (val: number) => {
    onChange({ [field]: val * 1 || '' });
  };
  return (
    <SetText
      name="ID"
      value={value}
      type="number"
      onChange={onChange2}
      autoFocus={true}
    />
  );
}

function FilterCurrency({ value, onChange }: CompProps) {
  const options = [
    { v: '', t: 'Összes tétel' },
    { v: 'HUF', t: 'HUF' },
    { v: 'EUR', t: 'EUR' },
    { v: 'USD', t: 'USD' },
  ];
  const title = 'Deviza';
  return (
    <SetSelect
      value={value}
      options={options}
      name={title}
      onChange={onChange}
      objects={true}
    />
  );
}

function FilterStatus({ value, onChange }: CompProps) {
  const options = [
    { v: '', t: 'Összes tétel' },
    { v: 0, t: 'Nyitott tételek' },
  ];
  const title = 'Státusz';
  return (
    <SetSelect
      value={value}
      options={options}
      name={title}
      onChange={onChange}
      objects={true}
    />
  );
}

function FilterLedgerItem({ value, onChange }: CompProps) {
  const bookinData: any = useContext(BookingContext);
  const ledgers: { [ledger: string]: any } = bookinData.item_ledger;
  let options: any[] = Object.keys(ledgers).map((l: string) => ({
    v: l, // was l*1
    t: l + ' ' + ledgers[l].name,
  }));
  options = [{ v: '', t: '-- Nincs kiválasztva --' }, ...options];
  const title = 'Főkönyvi szám';
  return (
    <SetSelect
      value={value}
      options={options}
      name={title}
      onChange={onChange}
      objects={true}
    />
  );
}

function FilterBookingYear({ value, onChange }: CompProps) {
  const options = BOOKING_YEAR_FILTER;
  const onValue = (booking_year: number) => {
    onChange({ booking_year });
  };
  const title = 'Könyvelési év';
  return (
    <SetSelect
      value={value}
      options={options}
      name={title}
      onChange={onValue}
      objects={true}
    />
  );
}

function FilterBookingPeriod({
  value,
  onChange,
}: {
  value: any;
  onChange: any;
}) {
  const options_period = [{ v: '', t: '-- Nincs kiválasztva --' }];
  for (let y = new Date().getFullYear(); y > 2013; y--)
    for (let m = 12; m > 0; m--) {
      const p = '' + (y * 100 + m);
      options_period.push({ v: p, t: p });
    }
  const onChPeriod = (booking_period: number) => {
    onChange({ booking_period });
  };

  const title = 'Időszak';
  return (
    <SetSelect
      value={value}
      options={options_period}
      name={title}
      class={'smallYY'}
      onChange={onChPeriod}
      objects={true}
    />
  );
}
