/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Template } from '../content.types';

interface ChooseTempProps {
  templates: Template[];
  choose: (t: Template) => void;
}

export function ChooseTemp({ templates, choose }: ChooseTempProps) {
  return (
    <>
      <div className="mw400">
        {templates.map((x, i) => {
          return (
            <div key={i}>
              <div className="row text-center mtop5">
                <div
                  className="button bt_accent big mw400"
                  onClick={() => choose(x)}
                >
                  {x.template}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
