import { useState } from 'react';

import { BI } from '../bi';
import { BookedEqs } from '../booking/beqs';
import { BookedHeads } from '../booking/bheads';
import { BookedItems } from '../booking/bitems';
import { BookNew } from '../booking/bnew';
import { BookUpload } from '../booking/bupload';
import { ItemDel } from '../content/client_item_edit/a_del';
import { ItemNew } from '../content/client_item_edit/a_new';
import { ItemOpen } from '../content/client_item_edit/a_open';
import { ItemsList } from '../content/client_item_list/a_list';
import { ServiceMnb } from '../content/client_service-mnb';
import { frame_action } from './u-action';

type OCprops = {
  initialContent: any;
  setTitle: any;
  addPage: (inp: any) => void;
};

export function OneContent({ initialContent, setTitle, addPage }: OCprops) {
  const [act, setAct] = useState(initialContent);
  const action = (toAct: any) => {
    // console.log('ACTION', toAct);
    frame_action(toAct, setAct, setTitle, addPage);
  };

  return (
    <>
      <div className="bookframe_content">
        <div className="bookframe_content_in">
          <RenderContent {...{ act, action, setTitle }} />
        </div>
      </div>
    </>
  );
}

function RenderContent({ action, act }: any) {
  const onAction = action;
  switch (act.type) {
    case 'booking_new':
      return <BookNew {...{ ...act.data, onAction }} />;
    case 'booking_upload':
      return <BookUpload {...{ ...act.data, onAction }} />;
    case 'booking_heads':
      return <BookedHeads {...{ ...act.data, onAction }} />;
    case 'booking_items':
      return <BookedItems {...{ ...act.data, onAction }} />;
    case 'booking_eqs':
      return <BookedEqs {...{ ...act.data, onAction }} />;
    case 'bi_reports':
      return <BI {...{ action }} />;

    case 'ledger':
    case 'exrate':
    case 'partner':
    case 'bsasset':
    case 'bsaccount':
    case 'bktemplate':
      return <ItemsList role={act.type} onAction={action} />;
    case 'service-mnb':
      return <ServiceMnb />;

    case 'newitem':
      // eslint-disable-next-line jsx-a11y/aria-role
      return <ItemNew onAction={action} role={act.data?.role} />;
    case 'delitem':
      return <ItemDel {...{ data: act.data, onAction }} />;
    case 'edititem':
      return <ItemOpen {...{ data: act.data, onAction, edit: true }} />;
    case 'viewitem':
      return (
        <div>
          <ItemOpen {...{ data: act.data, onAction, edit: true }} />
        </div>
      );

    default:
      return null;
  }
}
