import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Item, ItemRole } from '../content.types';
import { selector_templates } from '../redux/content.selectors';
import { EditItem } from './edit_1_save';
import { ChooseTemp } from './h_choosetemp';

interface CompProps {
  role: ItemRole;
  onAction: (input: any) => void;
}

export function ItemNew({ onAction, role }: CompProps) {
  const templates: Item[] = useSelector(selector_templates);
  let newitem: Item | undefined;
  try {
    newitem = templates.find((t: Item) => t.role === role);
  } catch {
    null;
  }
  const [item, setItem] = useState(newitem);

  return !item ? (
    <>
      <div className="row" style={{ margin: '120px 0px 50px', padding: '5px' }}>
        <div className="content">
          <div className="row text-center">
            <h1>Templates</h1>
          </div>
          <div className="row">
            <ChooseTemp
              templates={templates || []}
              choose={(x) => {
                setItem(x);
              }}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="row" style={{ margin: '120px 0px 50px', padding: '5px' }}>
        <div className="content">
          <div className="row text-center">
            <h1>Új {item!.template?.toLowerCase()}</h1>
          </div>
          <div className="row">
            <EditItem
              item={item}
              group_id={0}
              onAction={onAction}
              edit={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
