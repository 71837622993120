export const datefx = {
  today() {
    const dt = new Date();
    return this.code(dt);
  },

  todayPeriod() {
    const dt = new Date();
    return this.code(dt, '').slice(0, 6);
  },

  code(dt: Date, char = '-') {
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();
    return this.form(y, m, d, char);
  },

  decode(dts: string, char = '-') {
    const [y, m, d] = dts.split(char);
    const s = this.form(+y, +m, +d);
    const dt = new Date(s);
    return dt;
  },

  form(y: number, m: number, d: number, char = '-') {
    const coded =
      '' + y + char + ('0' + m).slice(-2) + char + ('0' + d).slice(-2);
    return coded;
  },
};
