const VERSION = '1.0.0';

const default_state = {
  pages: [], //[{ code: 'p1', content: { t: 'bi_reports', text: 'kimutatás' } }],
  act: 'p1',
  nr: 1,
  ver: VERSION,
};

export const initState = () => {
  return default_state;
};
