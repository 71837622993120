import { Meta, XField } from './xtypes';

type GridHeadersProps = {
  meta: Meta;
  fields: XField[];
};

export function gridHeaders({ meta, fields }: GridHeadersProps) {
  return fields.map((field: XField) => {
    const def = {
      field,
      ...meta.HEADERS[field],
    };
    return def;
  });
}

type GridRowProps = {
  item: any;
  meta: Meta;
  fields: XField[];
};

type Row = {
  id: number;
} & {
  [f: string]: any;
};

export function gridRow({ item, meta, fields }: GridRowProps) {
  const row: Row = { id: item.id };
  for (const field of fields)
    row[field] = (
      meta.FIELDS[field] ||
      (() => {
        return '';
      })
    )(item);
  return row;
}
