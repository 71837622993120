import { datefx } from './item.fx';

export const base_meta = {
  id: {},
  item_oid: {},
  group_id: {},
  group_oid: {},
  role: {},
  prefix: {},
  title: { text: 'Megnevezés' },
  code: { text: 'Kód (opcionális)' },
  tags: {},
};

export const ledger_meta = {};

export const exrate_meta = {
  pair_code: { text: 'Devizapár kódja' },
  cur1: { text: 'Deviza 1' },
  cur2: { text: 'Deviza 2' },
  source: { text: 'Forrás' },
};

export const partner_meta = {
  base: {
    iscompany: {
      text: 'Forma',
      type: 'select2',
      options: [
        { v: 0, t: 'Magánszemély' },
        { v: 1, t: 'Cég, Szervezet' },
      ],
      value: 0,
    },
    name: { text: 'Név', maxlength: '200', validations: ['name'] },
    short_name: { text: 'Rövid név', maxlength: '200', validations: ['name'] },
    nationality: {
      text: 'Nemzetiség',
      maxlength: '200',
      validations: ['name'],
    },
    co_name: { text: 'Cég neve', maxlength: '200', validations: ['name'] },
    co_regno: {
      text: 'Cég regisztrációs száma',
      maxlength: '200',
      validations: [],
    },
    taxno: { text: 'Adószám', maxlength: '100', validations: ['tax'] },
    taxno_eu: {
      text: 'EU-s adószám',
      maxlength: '100',
      validations: ['tax'],
      errorhint: '000-000-000',
    },
    member_eu: {
      text: 'EU tagság',
      type: 'select2',
      options: [
        { v: 0, t: 'Közösségen kívüli' },
        { v: 1, t: 'Közösségen belüli' },
      ],
      value: 0,
    },
  },

  billing_address: {
    coname: { text: 'Cég neve', maxlength: '200', validations: ['name'] },
    name: { text: 'Név', maxlength: '200', validations: ['name'] },
    country: { text: 'Ország', maxlength: '200', validations: ['name'] },
    zip: { text: 'Irányítószám', maxlength: '20', validations: ['zip'] },
    city: { text: 'Város', maxlength: '100', validations: ['city'] },
    address: { text: 'Cím 1.sor', maxlength: '200', validations: ['address'] },
    address2: { text: 'Cím 2.sor', maxlength: '200', validations: [] },
  },
};

export const bsasset_meta = {
  type: { text: 'Eszköz típus' },
  market_new_value: { text: 'Újkori piaci érték' },
  market_value: { text: 'Beszerzési piaci érték' },
  acquisition_date: {
    text: 'Beszerzés dátuma',
    type: 'date',
    value: datefx.today(),
  },
  activated: {
    text: 'Aktivált ? ',
    type: 'select2',
    options: [
      { v: 0, t: 'Nem' },
      { v: 1, t: 'Igen' },
    ],
  },
  activation_date: {
    text: 'Aktiválás dátuma',
    type: 'date',
    value: datefx.today(),
  },
  book_value: { text: 'Aktiválási érték' },
  lifespan_year: { text: 'Hasznos élettartam (év)', value: 3 },
  residual_percent: { text: 'Maradványérték (%)', value: 20 },
};

export const bsaccount_meta = {
  bank: { text: 'Bank neve' },
  currency: { text: 'Deviza' },
  accountno: { text: 'Számlaszám' },
  swift: { text: 'SWIFT' },
  iban: { text: 'IBAN' },
  ledger_account: { text: 'főkönyvi szám' },
};

export const bktemplate_meta = {};
