import { LoginCognito } from './cognito-login';
import { LoginSimple } from './simple-login';

const FX = !!process.env.REACT_APP_API_FX;

export const Login = ({ children }: { children: React.ReactNode }) => {
  return FX ? (
    <LoginCognito>{children}</LoginCognito>
  ) : (
    <LoginSimple>{children}</LoginSimple>
  );
};
