import { useContext, useState } from 'react';

import BookinEnv from '../../content/client_booking/BookinEnv';
import BookingContext from '../../content/client_booking/BookingContext';
import { ChooseTemplate } from './booking_2_choose';
import { BookingTemplate } from './booking_3_book';

type CompProps = {
  onAction: any;
};

export function BookNew({ onAction }: CompProps) {
  return (
    <BookinEnv>
      <BookingStart onAction={onAction} />
    </BookinEnv>
  );
}

function BookingStart({ onAction }: CompProps) {
  const bookinData = useContext(BookingContext);
  const [state, setS] = useState({ choosed: false, template: null });

  const chooseAction = (act: any) => {
    if (act.template !== undefined)
      setS({ choosed: true, template: act.template });
  };
  const backAction = () => {
    setS({ choosed: false, template: null });
  };

  return state.choosed ? (
    <BookingTemplate
      template={state.template}
      onAction={onAction}
      backAction={backAction}
      bookinData={bookinData}
    />
  ) : (
    <ChooseTemplate onAction={chooseAction} bookinData={bookinData} />
  );
}
