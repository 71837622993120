import React from 'react';

import { SetDate } from './set-date';
import { SetSelect } from './set-select';
import { SetSwitch } from './set-switch';
import { SetText } from './set-text';

export function FormRowData({ data, error, meta, name, onChange }: any) {
  switch (meta.type) {
    case 'text':
      return (
        <SetText
          value={data}
          error={error}
          name={meta.text || name}
          onChange={onChange}
          maxlength={meta.maxlength}
          disabled={meta.disabled}
          autofocus={meta.autofucus}
        />
      );

    case 'date':
      return (
        <SetDate
          value={data}
          error={error}
          name={meta.text || name}
          onChange={onChange}
          disabled={meta.disabled}
        />
      );

    case 'switch':
      return (
        <SetSwitch
          value={data}
          error={error}
          name={meta.text || name}
          onChange={onChange}
          maxlength={meta.maxlength}
          disabled={meta.disabled}
        />
      );

    case 'select':
      return (
        <SetSelect
          value={data}
          name={meta.text || name}
          onChange={onChange}
          options={meta.options}
          disabled={meta.disabled}
        />
      );

    case 'select2':
      return (
        <SetSelect
          value={data}
          name={meta.text || name}
          onChange={onChange}
          options={meta.options}
          disabled={meta.disabled}
          objects={true}
        />
      );

    default:
      return null;
  }
}
