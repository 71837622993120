const Arr = [
  // name, set, show, code, quiz, exercise, solution, picture, quiz

  {
    template: 'Főkönyv',
    role: 'ledger',
    prefix: 'LE',
    ledger: {},
  },
  {
    template: 'Árfolyam',
    role: 'exrate',
    prefix: 'EX',
    exrate: {},
  },
  {
    template: 'Partner',
    role: 'partner',
    prefix: 'PA',
    partner: {},
  },
  {
    template: 'Eszköz',
    role: 'bsasset',
    prefix: 'AS',
    bsasset: {},
  },
  {
    template: 'Bankszámla / egyenleg',
    role: 'bsaccount',
    prefix: 'AC',
    bsaccount: {},
  },
  {
    template: 'Könyvelési sablon',
    role: 'bktemplate',
    prefix: 'TE',
    bktemplate: {},
  },
];

export default Arr;
