import React from 'react';

import BookinEnv from '../../content/client_booking/BookinEnv';
import { SetFilter } from './u-filter-inputs';

type FilterFormProps = {
  filter: any;
  onFilter: (inp?: any) => void;
  field?: any;
};

export function FilterForm({ filter, onFilter, field }: FilterFormProps) {
  const [filter2, setF] = React.useState(filter);
  const onValue = (newvals: any[]) => {
    const newFilter = { ...filter2, ...newvals };
    onFilter(newFilter);
    setF(newFilter);
  };
  return (
    <BookinEnv>
      <BookingFilters {...{ filter: filter2, onValue, field }} />
    </BookinEnv>
  );
}

type BookingFiltersProps = {
  filter: any;
  onValue: (inp?: any) => void;
  field?: any;
};

export function BookingFilters({
  filter,
  onValue,
  field,
}: BookingFiltersProps) {
  const show_fields = Object.keys(filter).filter((k) => !field || k === field);
  return (
    <>
      <div className="row text-center">
        <div className="mw400">
          {show_fields.map((field) => (
            <SetFilter
              key={field}
              field={field}
              value={filter[field]}
              onValue={onValue}
            />
          ))}
        </div>
      </div>
    </>
  );
}
