import { Auth } from 'aws-amplify';

export const userJwt = async () => {
  try {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();
    return jwt;
  } catch {
    return '';
  }
};

export const userData = async () => {
  const user = await Auth.currentUserInfo();
  return user;
};

export const signOut = () => {
  Auth.signOut();
};
