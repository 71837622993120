/* eslint-disable unicorn/consistent-function-scoping */
import './grid2.scss';

import React from 'react';

import { useContextMenu } from '../../../util-s';
import { Row } from '../gridtable/xtypes';
import { RowProps } from './types';

export const GridRow = ({
  columns,
  row,
  canSelect,
  onCellRightClick,
  selected,
  toggle,
}: RowProps & { row: Row }) => {
  const { onContextMenu } = useContextMenu();

  const rowSelected = React.useMemo(() => {
    return selected.includes(row.id);
  }, [row.id, selected]);

  const onRowClick = () => {
    if (canSelect) toggle(row.id);
  };

  return (
    <tr onClick={onRowClick} className={rowSelected ? 'selected' : ''}>
      {canSelect && (
        <td>
          <input type="checkbox" checked={rowSelected} onChange={() => null} />
        </td>
      )}
      {columns?.map((col, i) => (
        <td
          key={i}
          className={col.cellClassName + ' ' + col.type}
          onContextMenu={(event: any) => {
            if (onCellRightClick) {
              const { list, title } = onCellRightClick(row);
              onContextMenu(event, list, title);
            }
          }}
        >
          {row?.[col.field]}
        </td>
      ))}
    </tr>
  );
};
