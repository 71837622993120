import '@aws-amplify/ui-react/styles.css';
import './cognito-login.scss';

import {
  Authenticator,
  Button,
  Heading,
  // Image,
  // Text,
  translations,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { Amplify, I18n } from 'aws-amplify';

const amplifyAuthObject = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENTID,
};

Amplify.configure({ Auth: amplifyAuthObject });

const components = {
  // Header() {
  //   const { tokens } = useTheme();

  //   return (
  //     <View textAlign="center" padding={tokens.space.large}>
  //       <Image
  //         alt="Amplify logo"
  //         src="https://docs.amplify.aws/assets/logo-dark.svg"
  //       />
  //     </View>
  //   );
  // },

  // `${tokens.colors.neutral['80']}`

  // Footer() {
  //   const { tokens } = useTheme();

  //   return (
  //     <View textAlign="center" padding={tokens.space.large}>
  //       <Text color={'white'}>&copy; All Rights Reserved</Text>
  //     </View>
  //   );
  // },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Bejelentkezés
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center" style={{ marginBottom: '20px' }}>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Elfelejtettem a jelszavam
          </Button>
        </View>
      );
    },
  },
};

I18n.putVocabularies(translations);
I18n.setLanguage('hu');

I18n.putVocabulariesForLanguage('hu', {
  // 'Sign In': 'Bejelentkezés', // Tab header
  'Signing in': 'Bejelentkezés', // Tab header
  'Sign in': 'Bejelentkezés', // Button label
  // 'Sign in to your account': 'Üdv újra itt!',
  Username: 'Email cím', // Username label
  Password: 'Jelszó', // Password label
  // 'Reset password': 'Elfelejtett jelszó',
  // 'Forgot your password?': 'Elfelejtett jelszó',
  'Incorrect username or password.': 'Helytelen bejelentkezési adatok',
  'User does not exist.': 'Ismeretlen felhasználó',
  'Your passwords must match': 'A két jelszónak egyeznie kell',
  Changing: 'Változtatás', // Button label
  'Reset your password': 'Jelszó visszaállítása',
  'Enter your username': 'Add meg az email címed',
  'Send code': 'Küldés',
  'Back to Sign In': 'Vissza a bejelentkezéshez',
  // 'Change password': 'Jelszó változtatása',
  'Change Password': 'Jelszó változtatása',
  'Confirm password': 'Jelszó újra',
  'Confirm Password': 'Jelszó újra',
});

export function LoginCognito({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Authenticator
        hideSignUp={true}
        variation="modal"
        components={components}
      >
        {children}
      </Authenticator>
    </>
  );
}
