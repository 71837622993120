import { Button } from '@material-ui/core';
import { ChevronLeft, Save } from '@material-ui/icons';

import { ButtonBar } from '../../util-s/buttonbar';

type CompProps = {
  Action: (inp: { type: string }) => void;
  backAction: () => void;
};

export function BtottomButtons({ Action, backAction }: CompProps) {
  const saveBooking = () => {
    Action({ type: 'save_booking' });
  };
  const saveTemplate = () => {
    Action({ type: 'save_template' });
  };

  return (
    <ButtonBar>
      <Button
        color="primary"
        variant="contained"
        size={'small'}
        // m={2}
        startIcon={<Save />}
        onClick={saveBooking}
      >
        Rögzítés
      </Button>
      <Button
        color="primary"
        variant="outlined"
        size={'small'}
        // m={2}
        startIcon={<Save />}
        onClick={saveTemplate}
      >
        Sablon
      </Button>
      <Button
        color="default"
        variant="outlined"
        size={'small'}
        // m={2}
        startIcon={<ChevronLeft />}
        onClick={backAction}
      >
        Sablonok
      </Button>
    </ButtonBar>
  );
}
