import * as merge from '../../util/common/json.merge';
import { ItemRole, Template } from '../content.types';
import { TemplateDefs } from './template.def';
import templates from './templates';

export function get_templates() {
  const tmps: Template[] = [];
  for (const tmp of templates) {
    tmps.push(set_template_def(tmp));
  }
  return tmps;
}

export function set_template_def(tmp: Template) {
  const role: ItemRole = tmp.role || 'task';

  const d = {
    ...TemplateDefs.base,
    [role]: { ...TemplateDefs[role] },
  };
  const b = merge.fill(tmp, d);
  return b;
}
