/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable unicorn/consistent-function-scoping */
import './contextMenu.scss';

import * as React from 'react';

const ContextMenuContext = React.createContext<ContextProps>({
  onContextMenu: () => null,
});

export const ContextMenu = ({ children }: ComponentProps) => {
  const [state, setS] = React.useState<contextState>(_contextState);

  const onContextMenu: ContextProps['onContextMenu'] = (
    event: any,
    list: ContextMenuList,
    title?: string
  ) => {
    setS({
      open: true,
      x: event?.pageX + 'px',
      y: event?.pageY + 'px',
      title: title || '',
      list: list || [],
    });
  };

  const close = () => {
    setS(_contextState);
  };

  React.useEffect(() => {
    document.addEventListener('click', close);
    // document.addEventListener('contextmenu', close);
    return () => {
      document.removeEventListener('click', close);
      // document.removeEventListener('contextmenu', close);
    };
  });

  return (
    <>
      <ContextMenuContext.Provider value={{ onContextMenu }}>
        {children}
      </ContextMenuContext.Provider>
      <Menu {...state} />
    </>
  );
};

export const useContextMenu = () => React.useContext(ContextMenuContext);

const Menu = ({ x, y, title, list, open }: contextState) => {
  return open ? (
    <div className="context-menu" style={{ top: y, left: x }}>
      <ul>
        {title && <li className="title">{title}</li>}
        {list.map((elem: CmListElem, i: number) => (
          <li
            key={i}
            className={elem.disabled ? 'inactive' : 'active'}
            onClick={() => {
              if (!elem.disabled) elem.onClick();
            }}
          >
            {elem.text}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

// -------------------------- types -----------------------
type ContextProps = {
  onContextMenu: (event: any, list: ContextMenuList, title?: string) => void;
};

type ComponentProps = {
  children: React.ReactNode;
};

type contextState = {
  open: boolean;
  x: string;
  y: string;
  title: string;
  list: ContextMenuList;
};

const _contextState: contextState = {
  open: false,
  x: '100px',
  y: '100px',
  title: '',
  list: [],
};

type CmListElem = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

export type ContextMenuList = CmListElem[];
export type ContextMenuListCreator = (inp: any) => ContextMenuProps;

export type ContextMenuProps = {
  list: ContextMenuList;
  title?: string;
};
