/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Item } from '../content.types';
import { ActionButtons } from './list_9_buttons';

export function HeaderBsAsset() {
  return (
    <>
      <tr>
        <th>#</th>
        <th>Kód</th>
        <th>Cím</th>
        <th>Típusa</th>
        <th>Élettartam</th>
        <th>Maradvány</th>

        <th>Actions</th>
      </tr>
    </>
  );
}

interface BodyBsAssetProps {
  item: Item;
  onOpen: () => void;
  onEdit: () => void;
  onDel: () => void;
}

export function BodyBsAsset({ item, onOpen, onEdit, onDel }: BodyBsAssetProps) {
  return (
    <>
      <tr className="pointer">
        <td onClick={onOpen}>{item.id}</td>
        <td onClick={onOpen}>{item.code}</td>
        <td onClick={onOpen}>{item.title}</td>
        <td onClick={onOpen}>{item.bsasset!.type}</td>
        <td onClick={onOpen}>{item.bsasset!.lifespan_year}év</td>
        <td onClick={onOpen}>{item.bsasset!.residual_percent}%</td>

        <td className="text-center">
          <ActionButtons {...{ onEdit, onDel }} />
        </td>
      </tr>
    </>
  );
}
