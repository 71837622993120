import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Refresh from '@material-ui/icons/Refresh';

import { ButtonBar } from '../../util-s/buttonbar';

interface CompProps {
  back: () => void;
  reload: () => void;
  xlsx2: () => void;
}

export function ReportButtons({ back, reload, xlsx2 }: CompProps) {
  return (
    <ButtonBar>
      <Button
        color="primary"
        variant="outlined"
        onClick={reload}
        size="small"
        startIcon={<Refresh />}
      >
        Frissít
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={xlsx2}
        size="small"
        startIcon={<Icon className="far fa-file-excel" />}
      >
        Export
      </Button>
      <Button
        color="default"
        variant="outlined"
        onClick={back}
        size="small"
        startIcon={<ChevronLeft />}
      >
        Vissza
      </Button>
    </ButtonBar>
  );
}
