export const bookconfig = {
  // common
  LOCAL_CURR: 'HUF',
  EXRATE_DIFF_D: '8761000',
  EXRATE_DIFF_C: '9761000',
  // client
  BOOKING_YEAR_DEFAULT: 2023,

  UPLOAD_TEMPLATE: 'KönyvelésiMinta001.xlsx',
};
