export const generate_code = (x) => {
  let b = 'yxcvbnmasdfghjklqwertzuiopYXCVBNMASDFGHJKLQWERTZUIOP0123456789';
  let s = '';
  for (let i = 0; i < x; i++) {
    s += b[Math.floor(Math.random() * b.length)];
  }
  return s;
};

export const generate_timecode = (x = 32) => {
  let n1 = Date.now() * 100_000;
  let n2 = Math.floor(Math.random() * 100_000);
  let n = n1 + n2;
  return n.toString(x);
};
