import React from 'react';
import { useDispatch } from 'react-redux';

import { ContextMenuListCreator } from '../../util-s';
import { BState } from '../booking.types';
import { Table } from '../u_util/gridtable';
import { ButtonReload } from './u-button-reload';

const nullState = (): BState => ({
  loaded: 0,
  page: 0,
  rowCount: 0,
  ids: [],
  refresh: -1,
});

type Frame2Props = {
  filter: any;
  onAction: any;
  buttons: any;
  chips: any;
  fields: any;
  selector: any;
  requestFX: any;
  noaction: any;
  noexport: any;
  canSelect: any;
  onSelect: any;
  onCellRightClick?: ContextMenuListCreator;
  refresh: any;
};

export function Frame2({
  filter,
  onAction,
  buttons,
  chips,
  fields,
  selector,
  requestFX,
  noaction,
  noexport,
  canSelect,
  onSelect,
  onCellRightClick,
  refresh,
}: Frame2Props) {
  const [state, _setState] = React.useState<BState>(nullState());

  const dispatch = useDispatch();
  const setState = (new_state_part: any) => {
    _setState({ ...state, ...new_state_part });
  };
  const setR = (json: any) => {
    console.log(json);
    setState({ ...json, loaded: 1, refresh });
  };
  const reload = () => {
    request();
  };
  const onPageChange = (pgMinusOne: number) => {
    request(pgMinusOne + 1);
  };

  const request = (page = state.page) => {
    requestFX({
      filter,
      page,
      setFX: setR,
      dispatch,
    });
  };

  React.useEffect(() => {
    if (refresh !== state.refresh) reload();
  });

  return !state.loaded ? null : (
    <Table
      ids={state.ids}
      buttons={
        <>
          <ButtonReload {...{ reload }} />
          {buttons}
        </>
      }
      {...{
        onAction,
        chips,
        fields,
        selector,
        noaction,
        noexport,
        canSelect,
        onSelect,
        onCellRightClick,
        refresh,
        page: state.page - 1,
        rowCount: state.rowCount,
        onPageChange,
      }}
    />
  );
}
